import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useMhaProvisioningWithPolling } from 'hooks'
import { useCreateMha } from 'modules/Onboarding/OnboardingForm/hooks'
import { useNavigate } from 'react-router-dom'
import { ROOT } from 'constants/route'
import { ProvisioningContainer } from 'modules/FormsContent/ProvisioningContainer'
import { StatusHeader } from 'components/StatusHeader'

interface CreateAgentProps {
  onCancel?: () => void
  showCancel?: boolean
  onRedirectPrevious: () => void
  onRedirectNext: () => void
  modalContent?: string
}

const CreateAgent: FunctionComponent<CreateAgentProps> = ({
  onCancel,
  showCancel = false,
  onRedirectPrevious,
  onRedirectNext,
  modalContent
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [createMhaComplete, setCreateMhaComplete] = useState(false)
  const createMHA = useCreateMha(() => {
    navigate(ROOT, { replace: true })
  })

  useEffect(() => {
    createMHA().then(() => {
      setCreateMhaComplete(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { agentComplete } = useMhaProvisioningWithPolling({
    onError: onRedirectPrevious,
    onCompleted: onRedirectNext,
    enabled: createMhaComplete
  })

  return (
    <ProvisioningContainer>
      <StatusHeader
        primaryText={t('formContent.agent.primaryText')}
        secondaryText={t('formContent.agent.secondaryText')}
        isComplete={agentComplete}
        showCancel={showCancel}
        onCancel={onCancel}
        modalContent={modalContent}
        data-testid="create-agent-status"
      />
    </ProvisioningContainer>
  )
}

export default CreateAgent
