import { FunctionComponent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AutoComplete, Field } from '@matillion/component-library'
import classes from 'components/Form/Form.module.scss'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { renderFormikError } from 'modules/utils'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import { createWarehouseDefaultsMutation } from 'api/mutation'

const FIELD_NAME = 'catalog'

const Catalog: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const {
    data: listOfCatalogs = [],
    mutate: mutateCatalog,
    isLoading,
    isError: fetchingError
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.CATALOG)

  const { compute } = values
  const isEnabled =
    compute &&
    !!compute.clusterId &&
    !!compute.clusterName &&
    !!compute.clusterType

  listOfCatalogs.sort((a, b) => a.localeCompare(b))

  const computeFormikError = renderFormikError(
    errors.catalog,
    Boolean(touched.catalog)
  )

  const catalogError = useMemo(() => {
    if (!isEnabled) return null
    if (fetchingError) {
      return t('fields.catalog.error.loadingError')
    }
    if (computeFormikError && listOfCatalogs.length) {
      return t(computeFormikError)
    }
    if (listOfCatalogs.length === 0 && !isLoading) {
      return t('fields.catalog.error.noItemsFound')
    }
    return null
  }, [
    computeFormikError,
    fetchingError,
    isEnabled,
    isLoading,
    listOfCatalogs.length,
    t
  ])

  const listenerFieldValue = values.compute
  const initialListenerFieldValue = initialValues.compute

  useEffect(() => {
    if (
      listenerFieldValue?.id !== initialListenerFieldValue?.id ||
      !isEnabled
    ) {
      setFieldTouched(FIELD_NAME, false)
      setFieldValue(FIELD_NAME, { id: '', name: '' })
    }
    if (isEnabled) {
      mutateCatalog({
        values: createWarehouseDefaultsMutation(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled, mutateCatalog, setFieldTouched, setFieldValue, values.compute])

  return (
    <div data-testid="databricks-defaults-catalog">
      <Field
        title={t('fields.catalog.title')}
        name={FIELD_NAME}
        inputComponent={AutoComplete}
        availableItems={listOfCatalogs.map((catalog) => ({
          id: catalog,
          name: catalog
        }))}
        loading={isLoading}
        data-testid="databricks-defaults-catalog-input"
        value={values.catalog}
        placeholder={
          fetchingError
            ? t('fields.catalog.error.placeholderText')
            : t('fields.catalog.placeholderText')
        }
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.catalog.supportText')}
        errorText={isLoading ? undefined : catalogError}
        hasError={
          Boolean(errors?.catalog?.id) && Boolean(touched.catalog) && !isLoading
        }
        required
        disabled={(fetchingError && !isLoading) || !isEnabled}
      />
    </div>
  )
}
export default Catalog
