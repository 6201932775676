import { useClient } from './useClient'
import { useMutation, UseMutationOptions } from 'react-query'
import { DELETEProjectRequest } from 'api/types'
import { AxiosError } from 'axios'
import { deleteProject } from 'api/clients'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEProjectRequest
  }
>

export const useDeleteProject = (options?: Options) => {
  const client = useClient()

  return useMutation(async (data: { values: DELETEProjectRequest }) => {
    const { values } = data

    return deleteProject(values, client)
  }, options)
}
