import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StepInfo } from 'components/Form/types'

type NavigationSteps<T extends string> = Record<
  T,
  {
    previousStep?: T
    currentStep: T
    nextStep?: T
    excludeFromStep?: boolean
  }
>

export const useFormNavigation = <T extends string>(
  steps: Partial<NavigationSteps<T>>
): StepInfo<T> => {
  const [progress, setProgress] = useState(0)
  const location = useLocation()

  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  ) as T

  const step = steps[currentPath]

  const firstStepKey = Object.keys(steps).find(
    (k) => !steps[k as T]?.previousStep && steps[k as T]?.nextStep
  ) as T

  const firstStep = steps[firstStepKey]

  const allStepsInOrder = []

  let currentStep = firstStep
  allStepsInOrder.push(currentStep)
  while (currentStep?.nextStep !== undefined) {
    currentStep = steps[currentStep.nextStep]
    allStepsInOrder.push(currentStep)
  }

  const stepInfo: StepInfo<T> = {
    currentStep: undefined,
    previousStep: undefined,
    nextStep: undefined,
    stepIndex: 0,
    progress: 0,
    isLastStep: false
  }

  if (step) {
    stepInfo.currentStep = step.currentStep
    stepInfo.previousStep = step.previousStep
    stepInfo.nextStep = step.nextStep
    stepInfo.progress = progress

    stepInfo.stepIndex =
      allStepsInOrder.findIndex((x) => x?.currentStep === step.currentStep) + 1
    stepInfo.isLastStep = stepInfo.stepIndex === allStepsInOrder.length
  }

  useEffect(() => {
    if (step) {
      if (stepInfo.isLastStep) {
        setProgress(0.95)
      } else {
        // rounding to 2 decimal places
        const newProgress =
          Math.round((stepInfo.stepIndex / allStepsInOrder.length) * 100) / 100

        setProgress(newProgress)
      }
    }

    return () => {
      setProgress(0)
    }
  }, [
    progress,
    step,
    stepInfo.isLastStep,
    stepInfo.stepIndex,
    allStepsInOrder.length
  ])

  return stepInfo
}
