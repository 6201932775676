import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Loader, Toaster } from '@matillion/component-library'

import {
  useGetEnvironmentAgents,
  useGetEnvironments,
  useGetProject,
  useGetSchedules,
  usePutSchedule
} from 'api/hooks'
import { editScheduleMutationData } from 'api/mutation'
import Form from 'components/Form'
import { AppRoutes, PROJECT_DETAILS_SCHEDULES } from 'constants/route'
import { AgentsSecretsHost } from 'types'
import EditScheduleContent, {
  initialValues,
  validationSchema
} from 'modules/Projects/EditSchedule/FormContent'
import { EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

const EditScheduleForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId, scheduleId } = useParams()
  const navigate = useNavigate()
  const {
    data: schedules,
    isLoading: isSchedulesLoading,
    refetch
  } = useGetSchedules(projectId!)
  const { data: environments, isLoading: isEnvironmentsLoading } =
    useGetEnvironments(projectId!)
  const { data: agents, isLoading: isAgentsLoading } = useGetEnvironmentAgents()
  const { data: projectData } = useGetProject(projectId!)

  const { mutateAsync: mutateAsyncSchedule } = usePutSchedule(
    projectId!,
    scheduleId!
  )

  const { makeToast, clearToasts } = Toaster.useToaster()

  const handleOnSubmit = () => {
    refetch()

    return navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES),
      {
        replace: true
      }
    )
  }

  const publishSchedule = async (
    values: EditScheduleFormValues,
    onPublishStatus: (success: boolean, scheduleName?: string) => void,
    onFinish: () => void
  ) =>
    mutateAsyncSchedule({
      values: editScheduleMutationData(values, projectId!)
    })
      .then((_res) => {
        onPublishStatus(true, values.name)
      })
      .catch((_res) => {
        onPublishStatus(false)
      })
      .finally(onFinish)

  const submitForm = async (newValues: EditScheduleFormValues) => {
    clearToasts()

    await publishSchedule(
      newValues,
      (success, scheduleName) => {
        if (success) {
          makeToast({
            title: t('editSchedule.responseMessage.success', {
              scheduleName
            }),
            message: '',
            type: 'success'
          })

          return handleOnSubmit()
        }

        makeToast({
          title: t('editSchedule.responseMessage.error'),
          message: '',
          type: 'error'
        })
      },
      () => null
    )
  }

  const handleOnCancel = () => {
    navigate(AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES))
  }

  if (isSchedulesLoading || isEnvironmentsLoading || isAgentsLoading) {
    return <Loader />
  }

  const scheduleInitialValues = initialValues(
    scheduleId!,
    schedules,
    environments,
    agents,
    projectData?.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
  )

  return (
    <Form
      formikValues={{
        validationSchema: validationSchema,
        onSubmit: submitForm,
        initialValues: scheduleInitialValues,
        validateOnMount: true,
        initialTouched: false
      }}
      translationPrefix="editSchedule"
    >
      <EditScheduleContent
        showCancel
        onCancel={handleOnCancel}
        modalContent={t('editSchedule.modal.content')}
      />
    </Form>
  )
}

export default EditScheduleForm
