import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@matillion/component-library'

import classes from 'modules/Projects/ProjectDetails/ProjectDetails.module.scss'

const PlaceholderFields: FunctionComponent = () => (
  <>
    <Skeleton
      width={300}
      height={34}
      className={classNames(classes.ProjectDetail__LoadingTitle)}
      aria-label="Title Placeholder"
    />
    <Skeleton
      width={'100%'}
      height={20}
      className={classNames(classes.ProjectDetail__LoadingDescription)}
      aria-label="Description Placeholder"
    />
    <Skeleton
      width={'75%'}
      height={20}
      className={classNames(classes.ProjectDetail__LoadingDescription)}
      aria-label="Short Description Placeholder"
    />
  </>
)

export default PlaceholderFields
