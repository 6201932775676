import { useTranslation } from 'react-i18next'
import { Value } from 'react-date-picker/dist/cjs/shared/types'

import { Label } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import DatePicker from 'components/DatePicker'
import { useFormikContext } from 'formik'
import { FunctionComponent } from 'react'
import { FormValues } from 'modules/Projects/CreateSchedule/types'

const StartTime: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, setFieldValue } = useFormikContext<FormValues>()

  const today = new Date()

  return (
    <div
      className={classes.Form__Field}
      data-testid="create-schedule-starts-on"
    >
      <Label title={t('createSchedule.fields.startTime.title')} role="h4">
        <DatePicker
          onChange={(value: Value) => {
            setFieldValue('startTime', value)
          }}
          onCalendarClose={() => {
            if (values.startTime.getDate() < today.getDate()) {
              setFieldValue('startTime', today)
            }
          }}
          minDate={today}
          value={values.startTime}
          data-testid="scheduler-date-picker"
        />
      </Label>
    </div>
  )
}

export default StartTime
