import { FunctionComponent } from 'react'

import { CreateProjectsContextProvider } from 'context'
import CreateProjectForm from 'modules/Projects/CreateProject/CreateProjectForm'

const CreateProject: FunctionComponent = () => {
  return (
    <CreateProjectsContextProvider>
      <CreateProjectForm />
    </CreateProjectsContextProvider>
  )
}

export default CreateProject
