import { POSTEnvironmentRequest, PUTEnvironmentRequestBody } from 'api/types'
import { CreateEnvironmentFormikValueTypes } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'
import { AgentsSecretsHost, Warehouse } from 'types'
import { AutoCompleteProps, DatabricksDefaultsI } from 'types/FormTypes'

export const createEnvironmentMutationData = (
  formValues: CreateEnvironmentFormikValueTypes,
  projectName: string
): POSTEnvironmentRequest => {
  const data = updateEnvironmentMutationData(formValues)
  return {
    name: `${projectName.trim()}-${formValues.environmentName.trim()}`,
    ...data
  }
}

interface FormValues extends DatabricksDefaultsI {
  projectConfiguration: AgentsSecretsHost
  account: string
  username: string
  tempSecretReferenceId?: string
  secretReferenceId: string
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  defaultWarehouse: AutoCompleteProps
  defaultSchema: AutoCompleteProps
  etlAgent: AutoCompleteProps
  matillionHostedAgentId: string
  type: Warehouse | ''
  port?: number | null
  ssl?: boolean | null
}

export const updateEnvironmentMutationData = (
  formValues: FormValues
): PUTEnvironmentRequestBody => ({
  agent:
    formValues.projectConfiguration === AgentsSecretsHost.CustomerHosted
      ? formValues.etlAgent.id
      : formValues.matillionHostedAgentId,
  warehouseConnection: {
    // TO-DO: Remove .toLowerCase() when liquibase change has been applied for all database records
    type: formValues.type.toLowerCase(),
    url: formValues.account.trim(),
    username: formValues.username.trim(),
    secretId: formValues.tempSecretReferenceId || formValues.secretReferenceId,
    ...(formValues.defaultRole?.id && {
      defaultRole: formValues.defaultRole.id
    }),
    ...(formValues.defaultDatabase?.id && {
      defaultDatabase: formValues.defaultDatabase.id
    }),
    ...(formValues.defaultWarehouse?.id && {
      defaultWarehouse: formValues.defaultWarehouse.id
    }),
    defaultSchema: formValues.defaultSchema.id,
    port: formValues?.port,
    ssl: formValues?.ssl,
    ...(formValues.catalog?.id && {
      defaultCatalog: formValues.catalog.id
    }),
    ...(formValues.compute?.clusterId && {
      clusterId: formValues.compute.clusterId,
      clusterName: formValues.compute.clusterName,
      clusterType: formValues.compute.clusterType
    })
  }
})
