import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { AutoComplete, Field } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { renderFormikError } from 'modules/utils'
import { FunctionComponent } from 'react'
import { groupAutocompleteTimezones } from 'modules/Projects/CreateSchedule/FormContent/Fields/AdvancedSchedule/Timezone/Timezones.util'
import { FormValues } from 'modules/Projects/CreateSchedule/types'

const Timezone: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<FormValues>()

  const timezoneError = renderFormikError(
    errors.timezone,
    Boolean(touched.timezone)
  )

  return (
    <div data-testid="create-schedule-timezone">
      <Field
        inputComponent={AutoComplete}
        availableItems={groupAutocompleteTimezones()}
        title={t('createSchedule.fields.timezone.title')}
        name="timezone"
        className={classes.Form__Field}
        data-testid="create-schedule-timezone-input"
        value={values.timezone}
        placeholder={
          timezoneError
            ? t('createSchedule.fields.timezone.error.placeholderText')
            : t('createSchedule.fields.timezone.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        errorText={timezoneError ? t(timezoneError) : ''}
        hasError={Boolean(errors?.timezone?.id) && Boolean(touched.timezone)}
        required
      />
    </div>
  )
}

export default Timezone
