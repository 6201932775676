import { createContext, useContext } from 'react'
import { noop } from 'lodash'

import { Warehouse } from 'types'

interface OnboardingContextProviderProps {
  projectType: Warehouse | ''
  setProjectType: (projectType: Warehouse | '') => void
  secretLocationId: string
  setSecretLocationId: (id: string) => void
  secretReferenceId: string
  setSecretReferenceId: (id: string) => void
  isTrialWarehouse: boolean
  setIsTrialWarehouse: (isTrial: boolean) => void
}

export const OnboardingContext = createContext<OnboardingContextProviderProps>({
  projectType: '',
  setProjectType: noop,
  secretLocationId: '',
  setSecretLocationId: noop,
  secretReferenceId: '',
  setSecretReferenceId: noop,
  isTrialWarehouse: true,
  setIsTrialWarehouse: noop
})

export const useOnboardingContext = (context = OnboardingContext) =>
  useContext(context)
