import classNames from 'classnames'
import { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

interface ModalActionsProps extends PropsWithChildren {
  singleButton?: boolean
}

export const ModalActions: FunctionComponent<ModalActionsProps> = ({
  children,
  singleButton = false
}) => {
  return (
    <div
      className={classNames(classes.Modal__Actions, {
        [classes['Modal__Actions--single-button']]: singleButton
      })}
      data-testid="modal-actions"
    >
      {children}
    </div>
  )
}
