import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { GIT_AUTH_EVENT_ID } from '../../hooks/useAuthMessageListener/types'
import { LoadingSpinner, Typography } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'
import classes from './InterstitialAuthRedirectPage.module.scss'
import { Buffer } from 'buffer'

const InterstitialAuthRedirectPage = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.interstitial'
  })
  const [hasMissingState, setHasMissingState] = useState(false)
  const [hasMissingCode, setHasMissingCode] = useState(false)

  useEffect(() => {
    // The state value is '{RANDOM_STRING}_BASE64({ORGANISATION SUBDOMAIN})'
    const state = searchParams.get('state')

    // The code is a random value generated by the auth provider
    const code = searchParams.get('code')

    if (!state) {
      setHasMissingState(true)
      return
    }

    if (!code) {
      setHasMissingCode(true)
      return
    }

    // The second half of the state, after the underscore, is the BASE64 encoded organisation
    const encodedOrganisation = state.substring(state.indexOf('_') + 1)

    // We then decode the account back to its raw value
    const account = Buffer.from(encodedOrganisation, 'base64')

    // Rebuild the origin domain using the organisation as the subdomain
    const originWithSubdomain = window.origin.replace(
      /^https:\/\//,
      `https://${account}.`
    )

    // Post a message with our state and code back to the origin
    window.opener.postMessage(
      {
        code,
        state,
        type: GIT_AUTH_EVENT_ID
      },
      originWithSubdomain
    )

    // Close itself so the browser switches back to the origin tab
    window.close()
  }, [searchParams])

  const id = 'git-auth-interstitial'

  if (hasMissingCode || hasMissingState) {
    return (
      <div
        className={classes.wrapper}
        data-testid="git-oauth-redirect-interstitial-error"
      >
        <Typography
          format="tm"
          className={classes.title}
          data-testid={`${id}-error-title`}
        >
          {t('error-title')}
        </Typography>

        {hasMissingState && (
          <Typography format="bcm" data-testid={`${id}-missing-state`}>
            {t('missing-state')}
          </Typography>
        )}

        {hasMissingCode && (
          <Typography format="bcm" data-testid={`${id}-missing-code`}>
            {t('missing-code')}
          </Typography>
        )}

        <Typography
          format="bcm"
          className={classes.help}
          data-testid={`${id}-error-help`}
        >
          {t('error-help')}
        </Typography>
      </div>
    )
  }

  return (
    <div
      className={classes.wrapper}
      data-testid="git-oauth-redirect-interstitial"
    >
      <LoadingSpinner className={classes.loading} />

      <div className={classes.body}>
        <Typography
          format="tm"
          data-testid={`${id}-title`}
          className={classes.title}
        >
          {t('title')}
        </Typography>

        <Typography
          format="bcm"
          data-testid={`${id}-desc`}
          className={classes.desc}
        >
          {t('desc')}
        </Typography>

        <Typography
          format="bcm"
          data-testid={`${id}-info`}
          className={classes.info}
        >
          {t('info')}
        </Typography>
      </div>
    </div>
  )
}

export default InterstitialAuthRedirectPage
