import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'
import { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'

import { DatabricksCredentials } from './Credentials'
import { DatabricksDefaults } from './Defaults'

interface DatabricksProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

const Databricks: FunctionComponent<DatabricksProps> = ({ ...props }) => {
  const location = useLocation()

  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  const paths: { [key: string]: JSX.Element } = {
    [PROJECTS_CREDENTIALS]: <DatabricksCredentials {...props} />,
    [PROJECTS_DEFAULTS]: <DatabricksDefaults {...props} />
  }

  return paths[currentPath] ?? null
}

export default Databricks
