import { ChangeEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const Port: FunctionComponent = () => {
  const { errors, handleBlur, touched, values, setFieldValue } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleBlur(e)
    const port = e.target.value
      ? Number(e.target.value.replace(/[^0-9.]/g, ''))
      : e.target.value
    setFieldValue('port', port)
  }

  return (
    <div data-testid="redshift-credentials-port">
      <Field
        title={t('fields.port.title')}
        name="port"
        data-testid="redshift-credentials-port-input"
        value={values.port}
        placeholder={t('fields.port.placeholderText')}
        onBlur={handleChange}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.port.supportText')}
        errorText={errors?.port && touched.port ? t(errors.port) : null}
        hasError={Boolean(errors?.port) && Boolean(touched.port)}
        required
      />
    </div>
  )
}

export default Port
