import { TError, usePostProject } from 'api/hooks'
import { OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'
import { createProjectMutationData } from 'api/mutation'
import { useCallback, useState } from 'react'
import { redirectToDesigner } from 'modules/Onboarding/OnboardingForm/utils/redirectToDesigner'
import { Toaster } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'
import { useDesignerUrl } from 'hooks/useDesignerUrl'
import { GitProvider } from 'types'

export const useFinishOnboarding = (onRedirectPrevious?: () => void) => {
  const { t } = useTranslation()
  const { clearToasts, makeToast } = Toaster.useToaster()
  const designerFrontendUrl = useDesignerUrl()
  const { mutateAsync: mutateAsyncProject } = usePostProject()

  const [isComplete, setIsComplete] = useState(false)
  const [isError, setIsError] = useState(false)

  const mutateProject = useCallback(
    async (values: OnboardingFormikValueTypes) => {
      return mutateAsyncProject({
        values: createProjectMutationData({
          ...values,
          etlAgent: { id: '', name: '' },
          provider: GitProvider.Matillion
        })
      })
        .then((response) => {
          const { projectId, environmentId } = response

          // Delay for animation to finish
          setTimeout(() => {
            redirectToDesigner(
              projectId,
              environmentId,
              values.matillionHostedAgentId,
              designerFrontendUrl
            )
          }, 1000)
        })
        .catch((e: TError) => {
          clearToasts()
          setIsError(true)
          if (onRedirectPrevious) {
            onRedirectPrevious()
          }
          makeToast({
            title:
              e.response?.data?.detail ??
              t('createProject.responseMessage.error'),
            message: '',
            type: 'error'
          })
        })
        .finally(() => setIsComplete(true))
    },
    [
      mutateAsyncProject,
      designerFrontendUrl,
      clearToasts,
      onRedirectPrevious,
      makeToast,
      t
    ]
  )

  return { isComplete, isError, mutateProject }
}
