import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { getSecretKey } from 'api/createProjectForm/client'
import { GETSecretKeyResponse } from 'api/createProjectForm/types'
import { useClient } from 'api/hooks'
import { QueryKey } from 'constants/endpoint'

type TData = GETSecretKeyResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

/**
 * See more at https://tanstack.com/query/v4/docs/reference/useQuery
 *
 * @param {string} secretName - The secret name for referencing the secret keys
 * @param {string} secretLocationId - The ID of the secret location
 * @param {string} agentId - The ID of the agent
 * @param {Object|undefined} options - useQuery options
 * @returns {Array} The secret keys in string format
 */
export const useGetSecretKeys = (
  secretName: string,
  secretLocationId: string,
  agentId: string,
  options?: Options
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.SECRET_KEYS, secretName, secretLocationId, agentId],
    async () => getSecretKey({ agentId, secretLocationId, secretName }, client),
    options
  )
}
