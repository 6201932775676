import { BRANCH_ID, BRANCH_NAME } from '__test-utils__/ids'

import { type WorkingTreeBranchResponse } from 'api/hooks/useGetWorkingTreeBranch/types'

export const MockedGetWorkingTreeBranchResponses: Record<
  string,
  WorkingTreeBranchResponse
> = {
  [BRANCH_NAME]: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3'
  },
  main: {
    commitId: '579435ab17bf62fb92df6a92c2b964b58efdba53'
  },
  [BRANCH_ID]: {
    commitId: '197b33fb8e170dc88d6a7674fb1dd975c68fae5c'
  }
}

export const MockedDifferentGetWorkingTreeBranchResponses: Record<
  string,
  WorkingTreeBranchResponse
> = {
  [BRANCH_NAME]: {
    commitId: '90edb29efcf90ea0dd45fd724cf73f235ea3fb5a'
  },
  BranchNoConflicts: {
    commitId: 'df8abcc7d6244b112c2016a617140356c99434d3'
  },
  BranchWithConflicts: {
    commitId: '90edb29efcf90ea0dd45fd724cf73f235ea3fb5a'
  },
  main: {
    commitId: '579435ab17bf62fb92df6a92c2b964b58efdba53'
  }
}
