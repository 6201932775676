import { FunctionComponent } from 'react'
import { useFormikContext } from 'formik'
import { AutoComplete, Field } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

import {
  FormValues,
  resolveErrorText
} from 'modules/Projects/EditBranch/EditBranchForm.util'
import classes from 'modules/Projects/EditBranch/EditBranch.module.scss'
import { GETEnvironmentsResponse } from 'api/types'

const Environment: FunctionComponent<{
  availableEnvironments: GETEnvironmentsResponse
}> = ({ availableEnvironments }) => {
  const { t } = useTranslation()
  const { getFieldProps, getFieldMeta } = useFormikContext<FormValues>()
  const fieldName = 'defaultEnvironment'
  const field = getFieldProps(fieldName)
  const meta = getFieldMeta(fieldName)
  const env = availableEnvironments
    .map((x) => ({ id: x.id, name: x.name }))
    .sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div data-testid="edit-branch-default-environment">
      <Field
        {...field}
        data-testid="edit-branch-default-environment-input"
        className={classes['EditBranch__Field--spaced']}
        title={t('editBranch.fields.defaultEnvironment.title')}
        name={fieldName}
        placeholder={t('editBranch.fields.defaultEnvironment.placeholderText')}
        inputComponent={AutoComplete}
        availableItems={env}
        errorText={resolveErrorText(t, meta)}
        value={field.value || null}
      />
    </div>
  )
}

export default Environment
