import { FunctionComponent, PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'

import { Grid, GridProps } from '@matillion/component-library'

import classes from './GridLayout.module.scss'

interface GridLayoutProps {
  'data-testid'?: string
  className?: string
}

export const GridLayout: FunctionComponent<
  PropsWithChildren<GridLayoutProps>
> = ({ 'data-testid': dataTestId, className, children }) => {
  return (
    <Grid
      className={classNames(classes.GridLayout, className)}
      data-testid={dataTestId}
    >
      {children as ReactElement<GridProps>}
    </Grid>
  )
}
