export enum ColumnKeys {
  name = 'name',
  jobName = 'job',
  environment = 'environment',
  schedule = 'schedule',
  lastRun = 'lastRun',
  nextRun = 'nextRun',
  action = 'action'
}

export type ScheduleTypes = Exclude<ColumnKeys, 'action'>

export interface ScheduleDataTypes {
  job: string
  name: string
  environment: string
  schedule: string
  lastRun: string
  nextRun: string
}

export type SortTypes = { [key in ColumnKeys]: ColumnKeys }

export interface SortedScheduleDataTypes extends ScheduleDataTypes {
  id: string
}

export interface ActionCellTypes {
  isError?: boolean
  item: SortedScheduleDataTypes
  projectId: string
}
