import { FieldMetaProps } from 'formik'
import { TFunction } from 'react-i18next'
import { object, string, InferType } from 'yup'

export const resolveErrorText = (t: TFunction, meta: FieldMetaProps<unknown>) =>
  meta.touched && meta.error ? t(meta.error) : undefined

export const formSchema = object({
  branchName: string()
    .required('addBranch.fields.branchName.error.required')
    .max(60, 'addBranch.fields.branchName.error.max'),
  branchFrom: string().required('addBranch.fields.branchFrom.error.required'),
  defaultEnvironment: string().required(
    'addBranch.fields.defaultEnvironment.error.required'
  )
})

export type FormValues = InferType<typeof formSchema>

export const initialValues: FormValues = {
  branchName: '',
  branchFrom: '',
  defaultEnvironment: ''
}
