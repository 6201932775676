/* eslint-disable @typescript-eslint/no-explicit-any */
import { AutoCompleteItemId } from '@matillion/component-library'
import { OAuthSchema } from 'api/types'

// remove blank property from object
export const removeFalsyStringProperties = (obj: any) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) =>
        !(
          (typeof v === 'string' && !v.length) ||
          v === null ||
          typeof v === 'undefined' ||
          (typeof v === 'object' && (v as { [key: string]: any }).id === '')
        )
    )
  )
}

export const getProviderList = (
  wholeSchema: OAuthSchema[],
  oauthAllowedProviders: string[],
  t: (arg: string, options?: object) => string
): AutoCompleteItemId[] => {
  const list = wholeSchema
    .filter((x) => oauthAllowedProviders.includes(x.provider.toLowerCase()))
    .map((o) => {
      return {
        id: o.provider,
        name: t(`secrets.createOAuth.providers.${o.resourceId}`)
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  return list
}

export const getOAuthTypeList = (
  oAuthTypeSchema: OAuthSchema,
  allowedAuthTypes: string[],
  t: (arg: string, options?: object) => string
): AutoCompleteItemId[] => {
  return oAuthTypeSchema.authTypes
    .filter((authType) => allowedAuthTypes.includes(authType.type))
    .map((x, i) => ({
      id: x.type,
      name: t(`secrets.createOAuth.authTypes.${x.resourceId}`)
    }))
}
