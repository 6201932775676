import { FunctionComponent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AutoCompleteItemId } from '@matillion/component-library'

import { OAuthParameter, OAuthParameterType } from 'api/types'
import { FormInputString } from 'modules/FormsContent/CreateOAuth/Fields/FormInputString'
import { FormInputDropdown } from 'modules/FormsContent/CreateOAuth/Fields/FormInputDropdown'

export interface ParametersProps {
  oAuthParameter?: OAuthParameter[]
}
export const Parameters: FunctionComponent<ParametersProps> = ({
  oAuthParameter
}) => {
  const { t } = useTranslation()

  const getParameterInputs = useCallback(() => {
    if (!oAuthParameter) {
      return false
    }

    const paramInputs = oAuthParameter.map((x, i) => {
      const title = t(`secrets.createOAuth.parameters.${x.resourceId}`)
      if (x.type === OAuthParameterType.Textbox) {
        return (
          <FormInputString
            key={x.id}
            title={title}
            formikFormName={x.id}
            placeholderText={t('genericField.textbox.placeholderText', {
              fieldName: title
            })}
            inputType={x?.password ? 'password' : 'text'}
          />
        )
      } else if (
        x.type === OAuthParameterType.Dropdown &&
        x.options &&
        x.options?.length > 0
      ) {
        const list: AutoCompleteItemId[] = x.options.map((o) => ({
          id: o.id,
          name: t(`secrets.createOAuth.options.${o.resourceId}`)
        }))

        return (
          <FormInputDropdown
            key={x.id}
            title={title}
            formikFormName={x.id}
            availableItems={list}
            placeholderText={t('genericField.dropdown.placeholderText', {
              fieldName: title
            })}
          />
        )
      } else {
        // if the schema gives us a type that we cannot handle, or dropdown doesn't have options defined, then it will not display anything
        return false
      }
    })

    return paramInputs
  }, [oAuthParameter, t])

  return <>{getParameterInputs()}</>
}
