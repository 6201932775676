import { useFormikContext } from 'formik'
import DefaultSchema from 'modules/Common/Fields/DefaultSchema/DefaultSchema'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export const RedshiftDefaultSchema = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultDatabase && !!values.defaultDatabase.id

  return (
    <DefaultSchema
      listenerField={'defaultDatabase'}
      isFieldEnabled={isFieldEnabled}
    />
  )
}
