import { Environment as CommonEnvironment } from 'modules/Common/Fields'
import { useResetPipelineField } from 'modules/Common/Fields/Environment/hooks/resetPipelineField/useResetPipelineField'

export const Environment = () => {
  const onChange = useResetPipelineField()

  return (
    <CommonEnvironment
      data-testid="edit-schedule-environment"
      onChange={onChange}
    />
  )
}
