import { object, string, mixed, InferType } from 'yup'
import { TFunction } from 'react-i18next'
import { FieldMetaProps } from 'formik'
import { AutoCompleteItemId } from '@matillion/component-library'

import { SecretReferenceTypes } from 'types'
import { alphaNumericMildCharsWithLeadEndSpacesRegEx } from 'modules/Projects/CreateProject/CreateProjectForm'

export enum FormFields {
  name = 'name',
  description = 'description',
  type = 'type',
  agent = 'agent',
  secretName = 'secretName',
  secretKey = 'secretKey',
  secretValue = 'secretValue',
  secretValueToggle = 'secretValueToggle'
}

const genericFormSchema = {
  [FormFields.name]: string()
    .required('secrets.definitionForm.fields.name.error.required')
    .matches(
      alphaNumericMildCharsWithLeadEndSpacesRegEx,
      'secrets.definitionForm.fields.name.error.regEx'
    ),
  [FormFields.description]: string(),
  [FormFields.type]: mixed<SecretReferenceTypes>()
    .oneOf(Object.values(SecretReferenceTypes))
    .required('secrets.definitionForm.fields.type.error.required')
}

export const formSchemaCHA = object({
  ...genericFormSchema,
  [FormFields.agent]: object({
    id: string().required('secrets.definitionForm.fields.agent.error.required'),
    name: string().required(
      'secrets.definitionForm.fields.agent.error.required'
    )
  })
    .required('secrets.definitionForm.fields.agent.error.unmatched')
    .nullable(),
  [FormFields.secretName]: string().required(
    'secrets.definitionForm.fields.secretName.error.required'
  ),
  [FormFields.secretKey]: string().required(
    'secrets.definitionForm.fields.secretKey.error.required'
  )
})

export const formSchemaMHA = object({
  ...genericFormSchema,
  [FormFields.secretValue]: string().required(
    'secrets.definitionForm.fields.secretValue.error.required'
  )
})

export enum SecretValueInputType {
  SINGLE = 'SINGLE',
  MULTI = 'MULTI'
}

export type FormValues = InferType<
  typeof formSchemaCHA & typeof formSchemaMHA
> & { secretValueToggle?: SecretValueInputType }

export const initialValuesCreate: FormValues = {
  name: '',
  description: '',
  type: SecretReferenceTypes.PASSWORD,
  agent: { id: '', name: '' },
  secretName: '',
  secretKey: '',
  secretValue: '',
  secretValueToggle: SecretValueInputType.SINGLE
}

export const resolveFormikError = (
  t: TFunction,
  meta: FieldMetaProps<unknown>
) => (meta.touched && meta.error ? t(meta.error) : undefined)

export const createAutoCompleteItems = (
  items: string[]
): AutoCompleteItemId[] => items.map((item) => ({ id: item, name: item }))
