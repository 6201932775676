import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Toaster } from '@matillion/component-library'

import useLegacyMerge from 'api/hooks/useLegacyMerge/useLegacyMerge'

import { type MergeActionArgs, type MergeActionResult } from './types'
import useProblemDetails from 'hooks/useProblemDetails'
import { type ProblemDetailRegistry } from 'hooks/useProblemDetails/types'
import { Problem } from 'hooks/useProblemDetails/Problem'
import useFeatureFlags from '../useFeatureFlags'
import useGitContext from 'provider/GitProvider/useGitContext'
import useMerge from '../../api/hooks/useMerge'

const commonFailureTitleKey = 'merge.problem.default.title'

const problemDetailsRegistry: ProblemDetailRegistry = [
  {
    type: Problem.WTS_BRANCH_NOT_FOUND,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'merge.problem.branch-not-found'
    }
  },
  {
    type: Problem.GIT_MANUAL_MERGE_REQUIRED,
    translation: {
      titleKey: commonFailureTitleKey,
      messageKey: 'merge.problem.manual-merge-required'
    }
  }
]

const isNotEmpty = (value?: string): value is string =>
  value !== undefined && value.trim() !== ''

const useMergeAction = ({
  mergeMessage,
  targetBranch,
  targetCommitId,
  sourceCommitId,
  conflictSelections,
  onSuccess,
  onFailure
}: MergeActionArgs): MergeActionResult => {
  const { makeToast } = Toaster.useToaster()
  const { isNativeGit } = useGitContext()
  const { enableWorkingTreeMerge } = useFeatureFlags()
  const { mutateAsync: legacyMerge, isLoading: isLegacyMergeLoading } =
    useLegacyMerge()
  const { mutateAsync: wtsMerge, isLoading: isWTSMergeLoading } = useMerge()
  const { t } = useTranslation('translation', { keyPrefix: 'merge.toast' })
  const { resolveProblemDetails } = useProblemDetails(problemDetailsRegistry, [
    {
      name: 'targetBranch',
      value: targetBranch ?? ''
    }
  ])

  const shouldCallWTSMerge = useMemo(() => {
    return isNativeGit && enableWorkingTreeMerge
  }, [enableWorkingTreeMerge, isNativeGit])

  const isValid =
    isNotEmpty(mergeMessage) &&
    isNotEmpty(targetBranch) &&
    isNotEmpty(targetCommitId) &&
    isNotEmpty(sourceCommitId)

  const isLoading = useMemo(() => {
    return isLegacyMergeLoading || isWTSMergeLoading
  }, [isLegacyMergeLoading, isWTSMergeLoading])

  const merge = useCallback(async () => {
    if (isLoading) return
    if (!isValid) return

    try {
      let commitId: string | undefined

      if (shouldCallWTSMerge) {
        await wtsMerge({
          remoteCommitId: targetCommitId,
          conflictSelections
        })
      } else {
        const response = await legacyMerge({
          mergeMessage,
          targetBranch,
          sourceCommitId,
          targetCommitId,
          conflictSelections
        })
        commitId = response.commitId
      }

      makeToast({
        type: 'success',
        title: t('success.title', { branch: targetBranch }),
        message: t('success.message', { branch: targetBranch })
      })

      await onSuccess(commitId)
    } catch (error) {
      const { title, message } = resolveProblemDetails(error, {
        titleKey: commonFailureTitleKey,
        messageKey: 'merge.problem.default.message'
      })

      makeToast({ type: 'error', title, message })

      onFailure()
    }
  }, [
    isLoading,
    isValid,
    shouldCallWTSMerge,
    makeToast,
    t,
    targetBranch,
    onSuccess,
    wtsMerge,
    targetCommitId,
    conflictSelections,
    legacyMerge,
    mergeMessage,
    sourceCommitId,
    resolveProblemDetails,
    onFailure
  ])

  return { isLoading, merge, isValid }
}

export default useMergeAction
