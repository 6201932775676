import { useMemo } from 'react'
import useLegacyDescribeMerge from 'api/hooks/useLegacyDescribeMerge'
import useDescribeMerge from 'api/hooks/useDescribeMerge'
import useFeatureFlags from '../useFeatureFlags'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type DescribeMergeArgs, type DescribeMergeResponse } from './types'

const useDescribeMergeDecision = ({ targetBranch }: DescribeMergeArgs) => {
  const { enableWorkingTreeMerge } = useFeatureFlags()
  const { branch, isLegacyWTS } = useGitContext()

  const shouldUseLegacyImpl = isLegacyWTS || !enableWorkingTreeMerge

  const {
    data: legacyMergeDescription,
    isLoading: isLegacyDescribeLoading,
    fetchStatus: legacyDescribeFetchStatus
  } = useLegacyDescribeMerge(branch, targetBranch, {
    enabled: shouldUseLegacyImpl
  })

  const {
    data: wtsMergeDescription,
    isLoading: isDescribeLoading,
    fetchStatus: describeFetchStatus
  } = useDescribeMerge(targetBranch, !shouldUseLegacyImpl)

  const mergeDescription: DescribeMergeResponse = useMemo(() => {
    if (shouldUseLegacyImpl) {
      return {
        targetCommitId: legacyMergeDescription?.targetCommit.id,
        ...legacyMergeDescription
      }
    } else {
      return {
        sourceCommitId: wtsMergeDescription?.localCommitId,
        targetCommitId: wtsMergeDescription?.remoteCommitId,
        ...wtsMergeDescription
      }
    }
  }, [legacyMergeDescription, shouldUseLegacyImpl, wtsMergeDescription])

  const isLegacyDescribeLoadingAndNotIdle =
    isLegacyDescribeLoading && legacyDescribeFetchStatus !== 'idle'

  const isDescribeLoadingAndNotIdle =
    isDescribeLoading && describeFetchStatus !== 'idle'

  const isLoading =
    isLegacyDescribeLoadingAndNotIdle || isDescribeLoadingAndNotIdle

  return {
    shouldShowPublishForm: shouldUseLegacyImpl,
    isLegacyDescribeLoadingAndNotIdle,
    isLoading,
    mergeDescription
  }
}

export default useDescribeMergeDecision
