import { FunctionComponent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AutoComplete, Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { createGetWarehouseComputeResourceMutationData } from 'api/createProjectForm/mutation'
import { renderFormikError } from 'modules/utils'
import { useMutateDatabricksComputeResources } from 'api/hooks/useMutateDatabricksComputeResources'

const Compute: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    initialValues,
    setFieldTouched,
    setFieldValue
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const {
    data: listOfComputeResources = [],
    isLoading,
    isError: fetchingError,
    mutate: mutateDatabricksComputeResources
  } = useMutateDatabricksComputeResources()

  const isFieldEnabled =
    !!values.secretReferenceId && !!values.account && !!values.username

  listOfComputeResources.sort((a, b) =>
    a.clusterName.localeCompare(b.clusterName)
  )

  const computeFormikError = renderFormikError(
    errors.compute,
    Boolean(touched.compute)
  )

  const computeError = useMemo(() => {
    if (fetchingError) {
      return t('fields.compute.error.loadingError')
    }

    if (computeFormikError) {
      return t(computeFormikError)
    }

    if (listOfComputeResources.length === 0 && !isLoading) {
      return t('fields.compute.error.noItemsFound')
    }
    return null
  }, [
    computeFormikError,
    fetchingError,
    isLoading,
    listOfComputeResources.length,
    t
  ])

  const listenerFieldValue = values.secretReferenceId
  const initialListenerFieldValue = initialValues.secretReferenceId

  useEffect(() => {
    if (listenerFieldValue !== initialListenerFieldValue || !isFieldEnabled) {
      setFieldTouched('compute', false)
      setFieldValue('compute', {
        id: '',
        name: '',
        clusterId: '',
        clusterName: '',
        clusterType: ''
      })
    }
    if (isFieldEnabled) {
      mutateDatabricksComputeResources({
        values: createGetWarehouseComputeResourceMutationData(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFieldEnabled,
    mutateDatabricksComputeResources,
    setFieldTouched,
    listenerFieldValue
  ])

  return (
    <div data-testid="databricks-defaults-compute">
      <Field
        title={t('fields.compute.title')}
        name="compute"
        inputComponent={AutoComplete}
        availableItems={listOfComputeResources.map((computeResource) => ({
          id: computeResource.clusterId,
          name: computeResource.clusterName,
          clusterId: computeResource.clusterId,
          clusterName: computeResource.clusterName,
          clusterType: computeResource.clusterType
        }))}
        loading={isLoading}
        data-testid="databricks-defaults-compute-input"
        value={values.compute}
        placeholder={
          fetchingError
            ? t('fields.compute.error.placeholderText')
            : t('fields.compute.placeholderText')
        }
        onBlur={handleBlur}
        onChange={handleChange}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.compute.supportText')}
        errorText={isLoading ? undefined : computeError}
        hasError={
          Boolean(errors?.compute?.id) && Boolean(touched.compute) && !isLoading
        }
        required
        disabled={fetchingError}
      />
    </div>
  )
}

export default Compute
