import { VideoFrame } from 'components/VideoFrame'
import config from 'config'
import { useTranslation } from 'react-i18next'

export const ProvisioningVideo = () => {
  const { t } = useTranslation()
  return (
    <VideoFrame
      data-testid="provisioning-video"
      src={config.docs.onboardingVideoUrl}
      title={t('formContent.provisioning.videoTitle')}
    />
  )
}
