import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Typography } from '@matillion/component-library'

import { SourceName } from 'types'

import classes from './CircledImageContent.module.scss'
import { CircledImage } from './CircledImage'

interface CircledImageContentProps {
  logoName: SourceName
  displayName: string
  Image?: FunctionComponent
  displayImage?: boolean
  text?: string
  className?: string
  imageContainerSize?: number
  imageSize?: number
  textClassName?: string
}

export const CircledImageContent: FunctionComponent<
  CircledImageContentProps
> = ({
  Image,
  logoName,
  displayName,
  text,
  className,
  textClassName,
  imageContainerSize = 40,
  imageSize = 34,
  displayImage = true
}) => {
  return (
    <div
      className={classNames(className, classes.CircledImageContent)}
      data-testid="circled-image-container"
    >
      {displayImage && Image && (
        <CircledImage
          Image={logoName && Image}
          logoName={logoName}
          className={classNames(classes.CircledImageContent__Circle)}
          imageContainerSize={imageContainerSize}
          imageSize={imageSize}
          data-testid="circled-image-image"
        />
      )}

      <div
        className={classNames(
          classes.CircledImageContent__TextContainer,
          textClassName
        )}
      >
        <Typography
          format="bcm"
          className={classNames(classes.CircledImageContent__Text)}
          title={displayName}
          data-testid="circled-image-display-name"
        >
          {displayName}
        </Typography>
        {text && (
          <Typography
            format="bcs"
            className={classNames(
              classes.CircledImageContent__Text,
              classes['CircledImageContent__Text--highlight']
            )}
            title={text}
            data-testid="circled-image-text"
          >
            {text}
          </Typography>
        )}
      </div>
    </div>
  )
}
