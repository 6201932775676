import { SortState } from '@matillion/component-library'
import { GETEnvironmentsResponseParams } from 'api/types'

import { ColumnKeys, EnvironmentTypes } from '.'

export const environmentsSort =
  (sortState: Partial<SortState<GETEnvironmentsResponseParams>>) =>
  (
    a: GETEnvironmentsResponseParams,
    b: GETEnvironmentsResponseParams
  ): number => {
    const sortStateType = Object.keys(sortState)[0] as EnvironmentTypes
    let aCompare
    let bCompare

    // Special case for cdwAccountName to sort on its URL prop
    if (sortStateType === ColumnKeys.cdwAccountName) {
      aCompare = a.warehouseConnection.url
      bCompare = b.warehouseConnection.url
    } else {
      aCompare = a[sortStateType]
      bCompare = b[sortStateType]
    }

    if (Object.values(sortState)[0] === 'ASC') {
      return aCompare.localeCompare(bCompare)
    } else {
      return bCompare.localeCompare(aCompare)
    }
  }
