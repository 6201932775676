import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@matillion/component-library'

const NoCreatePermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* // TO-DO: Replace this with template of the 'No Resource' page when improving the UI  */}
      <Typography format="bcm">
        {t('branchesListing.noCreatePermission.content')}
      </Typography>
    </>
  )
}

export default NoCreatePermission
