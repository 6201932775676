import { ChangeEvent, FunctionComponent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AutoComplete, Field } from '@matillion/component-library'

import { useGetSecretNames } from 'api/createProjectForm/hooks'
import classes from 'components/Form/Form.module.scss'
import { renderFormikError } from 'modules/utils'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const SecretName: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setTouched,
    setValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const isEnabled = !!values.secretLocationId && !!values.etlAgent?.id

  const {
    data: secretNamesData = [],
    isError,
    isLoading,
    refetch
  } = useGetSecretNames(values.secretLocationId, values.etlAgent?.id, {
    enabled: isEnabled
  })

  const refetchOnOpen = useCallback(async () => {
    await refetch()
  }, [refetch])

  const secretNameError = renderFormikError(
    errors.secretName,
    Boolean(touched.secretName)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('fields.secretName.error.loadingError')
    }

    if (!secretNamesData.length && isEnabled) {
      return t('fields.secretName.error.noItemsFound')
    }

    if (secretNameError) {
      return t(secretNameError)
    }

    return null
  }, [isError, secretNamesData.length, isEnabled, secretNameError, t])

  const updateValues = (e: ChangeEvent<HTMLInputElement>) => {
    const notTouchedAutocomplete = undefined
    const emptyAutocomplete = { name: '', id: '' }
    setValues({
      ...values,
      [e.target.name]: e.target?.value || '',
      secretKey: emptyAutocomplete
    })

    setTouched({
      ...touched,
      secretName: notTouchedAutocomplete,
      secretKey: notTouchedAutocomplete
    })
  }

  return (
    <div data-testid={`${values.type.toLowerCase()}-credentials-secret-name`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={secretNamesData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.secretName.title')}
        name="secretName"
        data-testid={`${values.type.toLowerCase()}-credentials-secret-name-input`}
        value={values.secretName}
        placeholder={t('fields.secretName.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          updateValues(e)
        }}
        onOpen={refetchOnOpen}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.secretName.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.secretName?.id) &&
          Boolean(touched.secretName) &&
          !isLoading
        }
        required
        disabled={isError || !isEnabled || !secretNamesData.length}
      />
    </div>
  )
}

export default SecretName
