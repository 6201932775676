import { AxiosInstance } from 'axios'

import { mockAxios } from '__mocks__/axiosMock'

import { GETProjectsStatusResponse } from 'api/types/ProjectsStatusResponse'
import {
  DELETEBranchParams,
  DELETEProjectMembersParams,
  DELETEProjectMembersRequest,
  DELETEProjectRequest,
  DELETEScheduleValues,
  DELETESecretReferenceParams,
  DELETESecretReferenceValues,
  GETAccountMembersResponse,
  GETBranchesRequest,
  GETBranchesResponse,
  GETBranchRequest,
  GETBranchResponse,
  GETEnvironmentAgentsRequest,
  GETEnvironmentAgentsResponse,
  GETEnvironmentsRequest,
  GETEnvironmentsResponse,
  GETJobsQueryStringParams,
  GETJobsResponse,
  GETMhaResponse,
  GETOAuthSchemaQueryStringParams,
  GETOAuthSchemaResponse,
  GETPermissionsParams,
  GETPermissionsResponse,
  GETProjectMembersParams,
  GETProjectMembersResponse,
  GETProjectRequest,
  GETProjectResponse,
  GETSchedulesRequest,
  GETSchedulesResponseParams,
  GETSecretReferencesQueryStringParams,
  GETSecretReferencesResponse,
  GETWarehouseProvisioningResponse,
  PATCHBranchParams,
  PATCHBranchRequest,
  PATCHSecretValueParams,
  PATCHSecretValueRequest,
  POSTBranchQueryStringParams,
  POSTBranchRequest,
  POSTBranchResponse,
  POSTCompleteOAuthRequestV2,
  POSTEnvironmentQueryStringParams,
  POSTEnvironmentRequest,
  POSTEnvironmentResponse,
  POSTOAuthQueryStringParams,
  POSTRolesParams,
  POSTRolesRequest,
  POSTScheduleRequest,
  POSTSecretReferenceQueryStringParams,
  POSTSecretReferenceRequest,
  POSTSecretReferenceResponse,
  POSTSecretValueQueryStringParams,
  POSTSecretValueResponse,
  POSTSecretValueV2Request,
  POSTSetupOAuthV2Request,
  POSTSetupOAuthV2Response,
  POSTWarehouseProvisioningRequest,
  PUTEnvironmentRequestBody,
  PUTEnvironmentRequestPathVariables,
  PUTScheduleRequest,
  PUTScheduleValues
} from './types'

export const getProjects = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectResponse[]> => {
  const path = '/v1/projects'
  const { data } = await client.get(path)

  return data
}

export const getProjectsStatus = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectsStatusResponse> => {
  const path = '/v1/projects/status'
  const { data } = await client.get(path)

  return data
}

export const getProject = async (
  params: GETProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}`
  const { data } = await client.get(path)

  return data
}

export const getBranches = async (
  params: GETBranchesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETBranchesResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/branches`
  const { data } = await client.get(path)

  return data
}

export const getBranch = async (
  params: GETBranchRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETBranchResponse> => {
  const { branchId, projectId } = params

  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.get(path)

  return data
}

export const postBranch = async (
  values: POSTBranchRequest,
  params: POSTBranchQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTBranchResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/branches`
  const { data } = await client.post(path, values)

  return data
}

export const patchBranch = async (
  values: PATCHBranchRequest,
  params: PATCHBranchParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, branchId } = params

  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.patch(path, values)

  return data
}

export const getEnvironments = async (
  params: GETEnvironmentsRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETEnvironmentsResponse> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/environments`
  const { data } = await client.get(path)

  return data
}

export const postEnvironment = async (
  values: POSTEnvironmentRequest,
  params: POSTEnvironmentQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTEnvironmentResponse> => {
  const { projectId } = params

  const path = `/v1/environments?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.post(path, values)

  return data
}

export const updateEnvironment = async (
  pathParams: PUTEnvironmentRequestPathVariables,
  bodyValues: PUTEnvironmentRequestBody,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { environmentId, projectId } = pathParams

  const path = `/v1/environments/${encodeURIComponent(
    environmentId
  )}?projectId=${encodeURIComponent(projectId)}`
  const { data } = await client.put(path, bodyValues)

  return data
}

export const getJobs = async (
  params: GETJobsQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETJobsResponse> => {
  const { environmentId, projectId } = params

  const path = `/v1/gateway/workflow/jobs?projectId=${encodeURIComponent(
    projectId
  )}&environmentId=${encodeURIComponent(environmentId)}`
  const { data } = await client.get(path)

  return data.jobs
}

export const getSecretReferences = async (
  params: GETSecretReferencesQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretReferencesResponse> => {
  const { projectId, secretType } = params

  const path = '/v1/gateway/srs/secret-references'
  const { data } = await client.get(path, {
    params: {
      projectId: encodeURIComponent(projectId),
      secretType: Array.isArray(secretType)
        ? secretType.map(encodeURIComponent).join(',')
        : encodeURIComponent(secretType)
    }
  })

  return data.secrets
}

export const postSecretReference = async (
  values: POSTSecretReferenceRequest,
  params: POSTSecretReferenceQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretReferenceResponse> => {
  const { projectId } = params
  let path = '/v1/gateway/srs/secret-reference'
  path = projectId ? `${path}?projectId=${encodeURIComponent(projectId)}` : path
  const { data } = await client.post(path, values)

  return data
}

export const deleteSecretReference = async (
  { id }: DELETESecretReferenceValues,
  { projectId }: DELETESecretReferenceParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const path = `/v1/gateway/srs/secret-references/${encodeURIComponent(id)}`
  const { data } = await client.delete(path, {
    params: {
      projectId
    }
  })

  return data
}

export const postSchedule = async (
  values: POSTScheduleRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const path = '/v1/gateway/scheduler/schedules'
  const { data } = await client.post(path, values)

  return data
}

export const deleteSchedule = async (
  params: DELETEScheduleValues,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { id } = params
  const path = `/v1/gateway/scheduler/schedules/${encodeURIComponent(id)}`
  const { data } = await client.delete(path)

  return data
}

export const getSchedules = async (
  params: GETSchedulesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSchedulesResponseParams> => {
  const { projectId } = params

  const path = `/v1/gateway/scheduler/schedules?projectId=${encodeURIComponent(
    projectId
  )}`

  const { data } = await client.get(path)
  return data
}

export const updateSchedule = async (
  scheduleId: string,
  values: PUTScheduleValues,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<PUTScheduleRequest> => {
  const path = `/v1/gateway/scheduler/schedules/${encodeURIComponent(
    scheduleId
  )}`
  const { data } = await client.put(path, values)
  return data
}

export const getMha = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETMhaResponse> => {
  const { data } = await client.get('/v1/mha')

  return data
}

export const postMha = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { data } = await client.post('/v1/mha')
  return data
}

export const postSecretValueV2 = async (
  values: POSTSecretValueV2Request,
  params: POSTSecretValueQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretValueResponse> => {
  const { projectId } = params
  const path = '/v2/gateway/srs/secret-value'

  const { data } = await client.post(path, values, {
    params: {
      projectId: projectId && encodeURIComponent(projectId)
    }
  })
  return data
}

export const patchSecretValue = async (
  values: PATCHSecretValueRequest,
  params: PATCHSecretValueParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId, secretReferenceId } = params
  const path = `/v1/gateway/srs/secret-value/${encodeURIComponent(
    secretReferenceId
  )}`

  const { data } = await client.patch(path, values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}

export const getAccountMembers = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETAccountMembersResponse> => {
  const path = '/members/v1'

  const { data: members } = await client.get(path)

  return members
}

export const getProjectMembers = async (
  params: GETProjectMembersParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETProjectMembersResponse> => {
  const path = '/members/v1'

  const { data: members } = await client.get(path, {
    params: {
      'resource-type': 'project',
      'resource-id': encodeURIComponent(params.projectId)
    }
  })

  return members
}

export const postRoles = async (
  params: POSTRolesParams,
  values: POSTRolesRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params

  const path = `/v1/projects/${encodeURIComponent(projectId)}/roles`

  const { data } = await client.post(path, values)

  return data
}

export const deleteProjectMembers = async (
  params: DELETEProjectMembersParams,
  values: DELETEProjectMembersRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const path = `/v1/projects/${encodeURIComponent(projectId)}/roles`
  const { data } = await client.delete(path, { data: { ...values } })
  return data
}

export const deleteBranch = async (
  params: DELETEBranchParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { branchId, projectId } = params
  const path = `/v1/projects/${encodeURIComponent(
    projectId
  )}/branches/${encodeURIComponent(branchId)}`
  const { data } = await client.delete(path)

  return data
}

export const deleteProject = async (
  params: DELETEProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const path = `/v1/projects/${encodeURIComponent(projectId)}`
  const { data } = await client.delete(path)

  return data
}

export const createOAuthV2 = async (
  values: POSTSetupOAuthV2Request,
  params: POSTOAuthQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSetupOAuthV2Response> => {
  const { projectId } = params
  const { data } = await client.post('/v2/gateway/oauth/setup', values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}

export const getOAuthSchema = async (
  params: GETOAuthSchemaQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETOAuthSchemaResponse> => {
  const { projectId, agentId } = params
  const { data } = await client.get('/v1/gateway/oauth/schema', {
    params: {
      projectId: encodeURIComponent(projectId),
      agentId: encodeURIComponent(agentId)
    }
  })

  return data
}

export const postWarehouseProvisioning = async (
  values: POSTWarehouseProvisioningRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { data } = await client.post('/v1/warehouse', values)

  return data
}

export const getWarehouseProvisioning = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETWarehouseProvisioningResponse> => {
  const { data } = await client.get('/v1/warehouse')

  return data
}

export const getPermissions = async (
  params: GETPermissionsParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETPermissionsResponse> => {
  const { projectId, permission } = params
  const path = `/v1/projects/${projectId}/permissions/${permission}`
  const { data } = await client.get(path)

  return data
}

export const getEnvironmentAgents = async (
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios,
  params?: GETEnvironmentAgentsRequest
): Promise<GETEnvironmentAgentsResponse> => {
  const { data } = await client.get('/v1/gateway/agents', { params })

  return data
}

export const completeOAuthV2 = async (
  values: POSTCompleteOAuthRequestV2,
  params: POSTOAuthQueryStringParams,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<void> => {
  const { projectId } = params
  const { data } = await client.post('/v2/gateway/oauth/complete', values, {
    params: {
      projectId: encodeURIComponent(projectId)
    }
  })

  return data
}
