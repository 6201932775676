import { PROJECTS_CREDENTIALS, PROJECTS_DEFAULTS } from 'constants/route'
import { FunctionComponent } from 'react'
import { useLocation } from 'react-router-dom'
import { RedshiftCredentials } from './Credentials'
import { RedshiftDefaults } from './Defaults'

interface RedshiftProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

const Redshift: FunctionComponent<RedshiftProps> = ({ ...props }) => {
  const location = useLocation()

  const currentPath = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  )

  const paths: { [key: string]: JSX.Element } = {
    [PROJECTS_CREDENTIALS]: <RedshiftCredentials {...props} />,
    [PROJECTS_DEFAULTS]: <RedshiftDefaults {...props} />
  }

  return paths[currentPath] ?? null
}

export default Redshift
