import { GETEnvironmentsResponseParams } from 'api/types'

export enum ColumnKeys {
  environmentName = 'name',
  defaultAgent = 'agentName',
  cdwAccountName = 'cdwAccountName',
  action = 'action'
}

export type EnvironmentTypes = Exclude<ColumnKeys, 'action'>

export type SortTypes = { [key in ColumnKeys]: ColumnKeys }

export interface SortedEnvironmentsTypes extends GETEnvironmentsResponseParams {
  id: string
}

export interface ActionCellTypes {
  onEditClick: (item: SortedEnvironmentsTypes) => void
  onAssociateCredsClick: (item: SortedEnvironmentsTypes) => void
  item: SortedEnvironmentsTypes
}
