import {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

interface AccountProps {
  disableOnBlur?: boolean
  translationProxy?: string
}

const Account: FunctionComponent<AccountProps> = ({
  disableOnBlur = false,
  translationProxy = 'account'
}) => {
  const { errors, handleBlur, setFieldValue, touched, values, validateField } =
    useFormikContext<CreateProjectFormikValueTypes>()

  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState(values.account)

  useEffect(() => {
    setCurrentValue(values.account)
  }, [values.account])

  return (
    <div data-testid={`${values.type.toLowerCase()}-credentials-account`}>
      <Field
        title={t(`fields.${translationProxy}.title`)}
        name="account"
        data-testid={`${values.type.toLowerCase()}-credentials-account-input`}
        value={currentValue}
        placeholder={t(`fields.${translationProxy}.placeholderText`)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCurrentValue(e.target.value)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          handleBlur(e)
          setFieldValue('account', currentValue.trim())
          setCurrentValue(currentValue.trim())
          validateField('account')
        }}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t(`fields.${translationProxy}.supportText`)}
        errorText={
          errors?.account && touched.account ? t(errors.account) : null
        }
        hasError={Boolean(errors?.account) && Boolean(touched.account)}
        required
      />
    </div>
  )
}

export default Account
