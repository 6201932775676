import { getWarehouseDefaults } from 'api/createProjectForm/client'
import {
  GETWarehouseDefaultsRequest,
  GETWarehouseDefaultsResponse,
  WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { useClient } from 'api/hooks/useClient'
import { AxiosError } from 'axios'
import { QueryKey } from 'constants/endpoint'
import { useMutation, UseMutationOptions } from 'react-query'

type TData = GETWarehouseDefaultsResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: GETWarehouseDefaultsRequest
}
type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const useMutateWarehouseDefaults = (
  lookupType: WarehouseLookupTypes,
  options?: Options,
  showErrorDetailMessage = false
) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.WAREHOUSE_DEFAULTS, lookupType],
    async (data: TVariables) => {
      const { values } = data
      return getWarehouseDefaults(values, lookupType, client)
    },
    options
  )
}
