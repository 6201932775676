import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { DELETEBranchParams } from 'api/types'
import { useClient } from './useClient'
import { deleteBranch } from 'api/clients'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETEBranchParams
  }
>

export const useDeleteBranch = (options?: Options) => {
  const client = useClient({ showErrorDetailMessage: true })

  return useMutation(async (data: { values: DELETEBranchParams }) => {
    const { values } = data

    return deleteBranch(values, client)
  }, options)
}
