import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Header, HeaderTitle } from 'components/Header'

import classes from 'components/Form/Form.module.scss'

import {
  Role,
  Schema,
  Warehouse,
  Database
} from 'modules/FormsContent/Sources/Snowflake/Defaults/Fields'
import { Warehouse as WarehouseType } from 'types'
import { capitalize } from 'lodash'

interface SnowflakeDefaultsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  isEditEnvironment?: boolean
}

export const SnowflakeDefaults: FunctionComponent<SnowflakeDefaultsProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  isEditEnvironment = false
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="snowflake-default-title">
          {t(
            isEditEnvironment
              ? 'formContent.editEnvironmentDefaults.title'
              : 'formContent.defaults.title',
            {
              projectType: capitalize(WarehouseType.Snowflake)
            }
          )}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <Role />
        <Warehouse />
        <Database />
        <Schema />
      </div>
    </>
  )
}
