import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postEnvironment } from 'api/clients'
import { POSTEnvironmentRequest, POSTEnvironmentResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type TData = POSTEnvironmentResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTEnvironmentRequest
}

type Options = UseMutationOptions<
  POSTEnvironmentResponse,
  AxiosError,
  TVariables
>

export const usePostEnvironment = (projectId: string, options?: Options) => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.CREATE_ENVIRONMENT, projectId],
    async (data: { values: POSTEnvironmentRequest }) => {
      const { values } = data
      return postEnvironment(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
