import { FunctionComponent, useCallback, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { Loader, Toaster } from '@matillion/component-library'

import {
  useGetEnvironments,
  useGetSecretReferences,
  useNetworkErrorToast
} from 'api/hooks'
import { updateEnvironmentWithMultipleCloudCredentialsRequest } from 'api/mutation'
import Form from 'components/Form'
import { QueryKey } from 'constants/endpoint'
import { ASSOCIATE_CREDENTIALS_STORAGE_ITEM } from 'constants/persistance'
import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'
import AssociateCredentialsToEnvironment from 'modules/FormsContent/AssociateCloudCredentialsToEnvironment'
import { SecretReferenceTypes } from 'types'

import {
  FormValues,
  formSchema,
  initialAssociateCredentialsValues
} from './AssociateCloudCredentialsToEnvironment.util'
import { AutoCompleteProps } from 'types/FormTypes'
import { useSubmitAssociateCloudCredentials } from 'modules/Projects/EditEnvironment/AssociateCloudCredentials/hooks'

const AssociateCloudCredentialsToEnvironmentForm: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const navigate = useNavigate()
  const { projectId, envId } = useParams()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const {
    data: listOfSecretReferences = [],
    isLoading: isSecretReferencesLoading
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
  )
  const {
    data: listOfEnvironments = [],
    isError: isEnvironmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)
  const updateEnvironment = useSubmitAssociateCloudCredentials()
  const makeErrorToast = useNetworkErrorToast()
  const selectedEnvironment = listOfEnvironments?.find((x) => x.id === envId)

  const navigateToEnvironmentList = useCallback(() => {
    queryClient.invalidateQueries([
      QueryKey.SECRET_REFERENCES,
      projectId!,
      SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
    ])
    queryClient.invalidateQueries([QueryKey.ENVIRONMENTS_LIST, projectId!])

    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }, [navigate, projectId, queryClient])

  const submitForm = (
    updatedValues: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    if (!selectedEnvironment) {
      makeErrorToast({})
      return
    }

    const requestBody = updateEnvironmentWithMultipleCloudCredentialsRequest({
      ...selectedEnvironment,
      agent: selectedEnvironment.agentId,
      azureCredentials: updatedValues.azureCredentials,
      awsCredentials: updatedValues.awsCredentials as AutoCompleteProps
    })
    updateEnvironment({
      requestBody,
      environment: { id: envId!, name: selectedEnvironment.name },
      setSubmitting
    })
  }

  useEffect(() => {
    // redirect if for some reason the selected environment data isn't found
    if (
      !isEnvironmentsLoading &&
      (!selectedEnvironment || isEnvironmentsError)
    ) {
      makeToast({
        title: t(
          'associateCredentials.responseMessage.loadingEnvironmentError'
        ),
        message: '',
        type: 'error'
      })
      return navigateToEnvironmentList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnvironmentsLoading, selectedEnvironment])

  if (isEnvironmentsLoading || isSecretReferencesLoading) {
    return <Loader />
  }

  return (
    <Form<FormValues>
      formikValues={{
        onSubmit: submitForm,
        initialValues: initialAssociateCredentialsValues(
          selectedEnvironment!,
          listOfSecretReferences
        ),
        validateOnMount: true,
        enableReinitialize: true,
        validationSchema: formSchema,
        initialTouched: false
      }}
      translationPrefix="associateCredentials"
      persistingStorageId={ASSOCIATE_CREDENTIALS_STORAGE_ITEM}
    >
      <AssociateCredentialsToEnvironment
        onFormCancel={navigateToEnvironmentList}
      />
    </Form>
  )
}

export default AssociateCloudCredentialsToEnvironmentForm
