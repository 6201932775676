import { FunctionComponent, PropsWithChildren, StrictMode } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from '@matillion/component-library'
import {
  AuthProvider,
  FlagProvider,
  RegionGuard,
  FlaggedServiceRegistryProvider,
  ServiceKey
} from '@matillion/hub-client'

import { queryClient } from 'api/queryClient'

import config from 'config'

const Providers: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <Toaster.Provider theme="dark" withFade position="top" align="right">
      <QueryClientProvider client={queryClient}>
        <AuthProvider environment={config.authEnvironment}>
          <FlagProvider
            launchDarklyClientId={config.launchDarkly.clientId}
            flags={config.launchDarkly.defaultFlags}
            waitForFlags
          >
            <RegionGuard strictAccountRegion>
              <FlaggedServiceRegistryProvider
                enableServiceRegistryFlagName="rolloutEnableServiceRegistry"
                endpoints={{
                  [ServiceKey.designerFrontend]: `${config.etlDesigner.baseUrl}`,
                  [ServiceKey.startFrontend]: `${config.hub.homeUrlTemplate}`
                }}
              >
                <BrowserRouter>{children}</BrowserRouter>
              </FlaggedServiceRegistryProvider>
            </RegionGuard>
          </FlagProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Toaster.Provider>
  )
}

const RenderProviders: FunctionComponent<PropsWithChildren> = ({ children }) =>
  config.strictMode ? (
    <StrictMode>{Providers({ children })}</StrictMode>
  ) : (
    Providers({ children })
  )

export default RenderProviders
