import { useFormNavigation } from 'hooks'
import { OptionalObjectSchema } from 'yup/lib/object'
import { object } from 'yup'
import { useParams } from 'react-router-dom'
import { useGetProject } from 'api/hooks'
import { AnyObject } from 'yup/lib/types'
import { EditEnvironmentSteps } from 'modules/Projects/EditEnvironment/types'
import { editEnvironmentSchema } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.util'
import { steps } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.steps'

export const useValidationSchema = (): OptionalObjectSchema<AnyObject> => {
  const { currentStep } = useFormNavigation<EditEnvironmentSteps>(steps)
  const { projectId } = useParams()
  const { data: projectData } = useGetProject(projectId!)
  const projectType = projectData?.warehouse ?? ''

  return currentStep
    ? editEnvironmentSchema(projectType)[currentStep]
    : object({})
}
