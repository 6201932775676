import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import { useResetFieldOnProviderChange } from 'modules/FormsContent/CloudProviderCredentials/Fields/hooks'
import classes from 'modules/FormsContent/Form.module.scss'
import { FormValues } from 'modules/Projects/CloudProviderCredentials'

const TenantID: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  useResetFieldOnProviderChange('tenantID', {
    setFieldTouched,
    setFieldValue
  })

  return (
    <div data-testid="cloud-provider-credentials-azure-tenant-id">
      <Field
        title={t('cloudProviderCredentials.fields.azure.tenantID.title')}
        name="tenantID"
        data-testid="cloud-provider-credentials-azure-tenant-id-input"
        value={values.tenantID}
        placeholder={t(
          'cloudProviderCredentials.fields.azure.tenantID.placeholderText'
        )}
        supportText={t(
          'cloudProviderCredentials.fields.azure.tenantID.supportText'
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        errorText={
          errors?.tenantID && touched.tenantID ? t(errors.tenantID) : null
        }
        hasError={!!errors?.tenantID && touched.tenantID}
        required
      />
    </div>
  )
}

export default TenantID
