import { SortState } from '@matillion/component-library'

import { ScheduleDataTypes, ScheduleTypes } from '.'

export const schedulesSort =
  (sortState: Partial<SortState<ScheduleDataTypes>>) =>
  (a: ScheduleDataTypes, b: ScheduleDataTypes): number => {
    const sortStateType = Object.keys(sortState)[0] as ScheduleTypes

    const aCompare = a[sortStateType]
    const bCompare = b[sortStateType]

    if (Object.values(sortState)[0] === 'ASC') {
      return aCompare.localeCompare(bCompare)
    } else {
      return bCompare.localeCompare(aCompare)
    }
  }
