import { Field, Radio, Typography } from '@matillion/component-library'
import { useFormikContext } from 'formik'
import { ChangeEvent, FunctionComponent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { CommonFormI } from 'types/FormTypes'
import classes from 'components/Form/Form.module.scss'

import classesInfrastructureSelection from 'modules/FormsContent/InfrastructureSelection/InfrastructureSelection.module.scss'
import classNames from 'classnames'
import { AgentsSecretsHost } from 'types'

type FormikValueTypes = CommonFormI

const ProjectConfiguration: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, setFieldValue } =
    useFormikContext<FormikValueTypes>()

  return (
    <div
      data-testid="infrastructure-selection-field"
      className={classNames(
        classesInfrastructureSelection.InfrastructureSelection__Container
      )}
    >
      <Field
        name="projectConfiguration"
        title={t('infrastructureSelection.fields.projectConfiguration.title')}
        text={t(
          'infrastructureSelection.fields.projectConfiguration.radioChoice1.label'
        )}
        value={values.projectConfiguration}
        className={classNames(classes.Form__SpacingStyles)}
        checked={
          values?.projectConfiguration === AgentsSecretsHost.MatillionHosted
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          setFieldValue(
            'projectConfiguration',
            AgentsSecretsHost.MatillionHosted
          )
        }}
        inputComponent={Radio}
        data-testid="matillion-infrastructure-radio"
      ></Field>
      <Field
        name="projectConfiguration"
        text={t(
          'infrastructureSelection.fields.projectConfiguration.radioChoice2.label'
        )}
        supportText={
          <Typography
            format="bcs"
            className={
              classesInfrastructureSelection.InfrastructureSelection__RadioSupportText
            }
            data-testid="customer-infrastructure-radio-support-text"
          >
            {t(
              'infrastructureSelection.fields.projectConfiguration.radioChoice2.supportText'
            )}
            <span
              className={
                classesInfrastructureSelection.InfrastructureSelection__RadioHyperlink
              }
            >
              <Trans
                i18nKey="infrastructureSelection.fields.projectConfiguration.radioChoice2.supportText2"
                components={{
                  Link: (
                    <a
                      href="https://docs.matillion.com/data-productivity-cloud/agent/docs/create-an-agent/"
                      target="_blank"
                      rel="noreferrer"
                      data-testid="customer-infrastructure-radio-support-link"
                    />
                  )
                }}
              />
            </span>
          </Typography>
        }
        value={values.projectConfiguration}
        className={classNames(classes.Form__RadioButtonSpacing)}
        checked={
          values?.projectConfiguration === AgentsSecretsHost.CustomerHosted
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          setFieldValue(
            'projectConfiguration',
            AgentsSecretsHost.CustomerHosted
          )
        }}
        inputComponent={Radio}
        data-testid="customer-infrastructure-radio"
      ></Field>
    </div>
  )
}

export default ProjectConfiguration
