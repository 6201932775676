import { startCase, upperCase } from 'lodash'
import { SortState } from '@matillion/component-library'

import { GETSecretReferencesResponseParams } from 'api/types'
import { CloudProviders } from 'types/CloudProviders'

export enum ColumnKeys {
  name = 'name',
  provider = 'provider',
  description = 'description',
  type = 'type',
  actions = 'actions'
}

export const sortByColumn = <T extends GETSecretReferencesResponseParams>(
  items?: T[],
  sortState?: Partial<SortState<T>>
) => {
  if (!sortState || !items) {
    return items
  }

  const sortField = Object.keys(sortState)[0] as keyof T
  let sortedData = [...items].sort((itemA, itemB) =>
    String(itemA[sortField]).localeCompare(String(itemB[sortField]))
  )

  if (sortField === 'provider') {
    sortedData = [...items].sort((itemA, itemB) =>
      String(itemA.metadata?.[sortField]).localeCompare(
        String(itemB.metadata?.[sortField])
      )
    )
  }

  return sortState[sortField] === 'ASC' ? sortedData : sortedData.reverse()
}

export const formatCloudProviderSecrets = (provider?: string) => {
  switch (provider) {
    case CloudProviders.AWS:
    case CloudProviders.GCP:
      return upperCase(provider)
    default: {
      return startCase(provider)
    }
  }
}
