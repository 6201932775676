import { useMemo } from 'react'
import {
  OnboardingFormNavigationStepsType,
  steps
} from 'modules/Onboarding/OnboardingForm/utils/OnboardingForm.util'

export const useOnboardingSteps = (
  isTrialWarehouse = false
): OnboardingFormNavigationStepsType => {
  return useMemo(() => {
    return steps(isTrialWarehouse)
  }, [isTrialWarehouse])
}
