import { v4 as uuidv4 } from 'uuid'

import { POSTSecretReferenceRequest } from 'api/types'
import { SecretReferenceTypes } from 'types'
import { AutoCompleteProps } from 'types/FormTypes'

interface FormValuesTypes {
  environmentName: string
  secretName: AutoCompleteProps
  secretKey: AutoCompleteProps
  secretLocationId: string
}

export const createSecretReferenceMutationData = (
  formValues: FormValuesTypes,
  secretType: SecretReferenceTypes
): POSTSecretReferenceRequest => {
  const shortUuid = uuidv4().substring(0, 8)

  return {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    name: `${formValues.environmentName.trim()}-dwh-pwd-${shortUuid}`,
    type: secretType,
    secretName: formValues.secretName.id,
    secretKey: formValues.secretKey.id,
    locationId: formValues.secretLocationId
  }
}
