import { FunctionComponent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'

import { Header, HeaderTitle } from 'components/Header'
import {
  CredentialName,
  ProviderSelection
} from 'modules/FormsContent/CloudProviderCredentials/Fields/Common'
import {
  AWSAccessKeyID,
  AWSSecretKey
} from 'modules/FormsContent/CloudProviderCredentials/Fields/AWS'
import {
  AzureTenantID,
  AzureClientID,
  AzureSecretKey
} from 'modules/FormsContent/CloudProviderCredentials/Fields/Azure'
import { Agent } from 'modules/Common/Fields'
import {
  FormValues,
  dynamicProvidersSchema,
  useCloudProviderCredentialsContext
} from 'modules/Projects/CloudProviderCredentials'
import { CloudProviders } from 'types/CloudProviders'

import classes from 'modules/FormsContent/Form.module.scss'

export interface CloudProviderCredentialsProps {
  onCancel?: () => void
  showCancel?: boolean
  isCHA?: boolean
}

const CloudProviderCredentials: FunctionComponent<
  CloudProviderCredentialsProps
> = ({ onCancel, showCancel, isCHA }) => {
  const { values } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  const { setValidationSchema } = useCloudProviderCredentialsContext()

  useEffect(() => {
    const providerSchema =
      dynamicProvidersSchema[
        values.provider as keyof typeof dynamicProvidersSchema
      ]
    setValidationSchema(providerSchema)
  }, [setValidationSchema, values.provider])

  return (
    <>
      <Header onCancel={onCancel} showCancel={showCancel}>
        <HeaderTitle data-testid="cloud-provider-credentials-title">
          {t('cloudProviderCredentials.title')}
        </HeaderTitle>
      </Header>
      <div className={classNames(classes.Form)}>
        <CredentialName />
        <ProviderSelection />
        {isCHA && <Agent />}
        {values.provider === CloudProviders.AWS && (
          <>
            <AWSAccessKeyID />
            <AWSSecretKey />
          </>
        )}
        {values.provider === CloudProviders.AZURE && (
          <>
            <AzureTenantID />
            <AzureClientID />
            <AzureSecretKey />
          </>
        )}
      </div>
    </>
  )
}

export default CloudProviderCredentials
