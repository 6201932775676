import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Icon } from '@matillion/component-library'

import { useGetPermissions } from 'api/hooks'
import EllipsesActionItem from 'components/EllipsesActionItem/EllipsesActionItem'
import { PermissionsType } from 'types'

import { ActionCellTypes } from '.'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  onEditClick,
  onAssociateCredsClick,
  item
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data: editPermission } = useGetPermissions(
    projectId!,
    PermissionsType.EditEnvironments
  )

  const handleEditClick = () => {
    onEditClick(item)
  }
  const handleAssociateCredsClick = () => {
    onAssociateCredsClick(item)
  }

  return editPermission?.result ? (
    <EllipsesActionItem
      actions={[
        {
          title: t('environmentsListing.actions.associateCreds'),
          action: handleAssociateCredsClick,
          icon: <Icon.Cloud />,
          dataTestId: 'ellipses-action-associate-credentials'
        },
        {
          title: t('environmentsListing.actions.edit'),
          action: handleEditClick,
          icon: <Icon.Pencil />,
          dataTestId: 'ellipses-action-edit-environment'
        }
      ]}
    />
  ) : null
}

export default ActionCell
