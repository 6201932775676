import { FunctionComponent, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const OAuthHandler: FunctionComponent = () => {
  const { search } = useLocation()

  useEffect(() => {
    const {
      state: stateString,
      code,
      ...params
    }: Record<string, string> = [
      ...new URLSearchParams(search).entries()
    ].reduce(
      (previous, [key, value]) => ({
        ...previous,
        [key]: value
      }),
      {}
    )

    /**
     * UOS will send PE the state in the format of '123456789_BASE64(ORGANISATION SUBDOMAIN)'
     * so now we're having to split the string up to extract the first state ID part, up to the underscore
     * where we will have a BASE 64 encoded subdomain. With this information we can effectively create
     * a matching url to POST a message event to that includes necessary information required
     */
    const stateId = stateString.split('_')[0]
    const accountEncoded = stateString.substring(stateString.indexOf('_') + 1)
    const account = atob(accountEncoded)

    const originWithSubdomain = window.origin.replace(
      /^https:\/\//,
      `https://${account}.`
    )

    window.opener?.postMessage(
      {
        ...params,
        attemptId: stateId,
        code,
        state: stateString,
        type: 'matillion.project-explorer-frontend.oauth.redirect'
      },
      originWithSubdomain
    )

    window.close()
  }, [search])

  return null
}

export default OAuthHandler
