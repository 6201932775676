import { useTranslation } from 'react-i18next'
import { Toaster } from '@matillion/component-library'

export interface UseNetworkErrorToastProps {
  title?: string
  message?: string
  clear?: boolean
}

export const useNetworkErrorToast = () => {
  const { t } = useTranslation()
  const { makeToast, clearToasts } = Toaster.useToaster()

  return ({ title, message, clear = true }: UseNetworkErrorToastProps) => {
    if (clear) clearToasts()
    makeToast({
      title: title ?? t('error.query.title'),
      message: message ?? t('error.query.timeout'),
      type: 'error',
      alt: 'red'
    })
  }
}
