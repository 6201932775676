import { useFormikContext } from 'formik'
import DefaultSchema from 'modules/Common/Fields/DefaultSchema/DefaultSchema'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const Schema = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.catalog && !!values.catalog.id

  return (
    <DefaultSchema listenerField={'catalog'} isFieldEnabled={isFieldEnabled} />
  )
}

export default Schema
