import { ChangeEvent, FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AutoComplete, Field, Toaster } from '@matillion/component-library'

import { useGetSecretKeys } from 'api/createProjectForm/hooks'
import classes from 'components/Form/Form.module.scss'
import { renderFormikError } from 'modules/utils'
import { useCreateEnvironmentContext } from 'context'
import { useLocation, useParams } from 'react-router-dom'
import { AppRoutes } from 'constants/route'
import { usePostSecretReference } from 'api/hooks'
import { createSecretReferenceMutationData } from 'api/mutation'
import { AutoCompleteProps } from 'types/FormTypes'
import { SecretReferenceTypes } from 'types'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const SecretKey: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue,
    setTouched
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { secretLocationId: contextSecretLocationId } =
    useCreateEnvironmentContext()
  const { t } = useTranslation()
  const location = useLocation()
  const { projectId, envId } = useParams()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()

  const secretLocationId = values.secretLocationId || contextSecretLocationId

  const isEnabled =
    !!values.secretName?.id && !!secretLocationId && !!values.etlAgent?.id

  const {
    data: secretKeysData = [],
    isError,
    isLoading
  } = useGetSecretKeys(
    values.secretName?.id,
    secretLocationId,
    values.etlAgent?.id,
    { enabled: isEnabled }
  )

  const secretKeyError = renderFormikError(
    errors.secretKey,
    Boolean(touched.secretKey)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('fields.secretKey.error.loadingError')
    }

    if (!secretKeysData?.length && isEnabled) {
      return t('fields.secretKey.error.noItemsFound')
    }

    if (secretKeyError) {
      return t(secretKeyError)
    }

    return null
  }, [isEnabled, isError, secretKeysData, secretKeyError, t])

  const refetchSecretReferenceId = async (e: ChangeEvent<HTMLInputElement>) => {
    // We need to create a new Secret Reference when the Secret Key changes
    // Only used in Edit Environment because it is a single page
    // For create Environment it happens on the "continue" button
    if (projectId && envId) {
      if (
        AppRoutes.getEnvironmentEdit(projectId, envId) === location.pathname
      ) {
        clearToasts()
        const value = e.target.value as unknown as AutoCompleteProps
        try {
          if (value) {
            const response = await mutateAsyncSecretReference({
              values: createSecretReferenceMutationData(
                {
                  ...values,
                  secretKey: value,
                  secretLocationId
                },
                SecretReferenceTypes.DWH_PASSWORD
              )
            })
            setFieldValue('secretReferenceId', response.secretId)
          }
        } catch (error: unknown) {
          makeToast({
            title: t('editEnvironment.responseMessage.secretReferenceError'),
            message: '',
            type: 'error'
          })
        }
      }
    }
  }

  // We need to update the fields when we Edit the environment
  const handleValueUpdates = (_e: ChangeEvent<HTMLInputElement>) => {
    const notTouchedAutocomplete = undefined
    setTouched({
      ...touched,
      secretKey: notTouchedAutocomplete
    })
  }

  return (
    <div data-testid={`${values.type.toLowerCase()}-credentials-secret-key`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={secretKeysData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.secretKey.title')}
        name="secretKey"
        data-testid={`${values.type.toLowerCase()}-credentials-secret-key-input`}
        value={values.secretKey}
        placeholder={t('fields.secretKey.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          refetchSecretReferenceId(e)
          handleChange(e)
          handleValueUpdates(e)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.secretKey.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.secretKey?.id) &&
          Boolean(touched.secretKey) &&
          !isLoading
        }
        required
        disabled={isError || !isEnabled || !secretKeysData.length}
      />
    </div>
  )
}

export default SecretKey
