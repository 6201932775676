import { useFormNavigation } from 'hooks'
import { OptionalObjectSchema } from 'yup/lib/object'
import { object } from 'yup'
import {
  genericValuesSchema,
  steps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/CreateEnvironmentForm.util'

import { warehouseValuesSchema } from 'modules/Projects/CreateProject/CreateProjectForm'
import { CreateEnvironmentSteps } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'
import { useParams } from 'react-router-dom'
import { useGetProject } from 'api/hooks'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useValidationSchema = (): OptionalObjectSchema<any> => {
  const { currentStep } = useFormNavigation<CreateEnvironmentSteps>(steps)
  const { projectId } = useParams()
  const { data: projectData } = useGetProject(projectId!)
  const projectType = projectData?.warehouse ?? ''

  const warehouseSchema = warehouseValuesSchema(projectType)

  const valuesSchema = {
    ...genericValuesSchema,
    ...warehouseSchema
  }

  return currentStep ? valuesSchema[currentStep] : object({})
}
