import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AgentsSecretsHost, Warehouse } from 'types'

import { Header, HeaderTitle } from 'components/Header'

import classes from 'components/Form/Form.module.scss'
import { CommonFormI } from 'types/FormTypes'
import {
  Account,
  SecretKey,
  SecretName,
  SecretValue,
  Username
} from 'modules/FormsContent/Sources/Fields'
import { capitalize } from 'lodash'

interface DatabricksCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

export const DatabricksCredentials: FunctionComponent<
  DatabricksCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent,
  excludeHeader = false
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { projectConfiguration } = values

  const fields = (
    <>
      <Account translationProxy={'workspaceId'} />
      <Username />
      {projectConfiguration === AgentsSecretsHost.MatillionHosted ? (
        <SecretValue />
      ) : (
        <>
          <SecretName />
          <SecretKey />
        </>
      )}
    </>
  )
  if (excludeHeader) {
    return fields
  }

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-credentials-title">
          {t('formContent.credentials.title', {
            projectType: capitalize(Warehouse.Databricks)
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
    </>
  )
}
