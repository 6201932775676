import { FunctionComponent, useEffect, useMemo } from 'react'
import { AutoComplete, Field } from '@matillion/component-library'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { renderFormikError } from 'modules/utils'
import classes from 'components/Form/Form.module.scss'
import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { ErrorResponse } from 'api/types'
import { StatusCodes } from 'constants/statusCodes'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'

export interface SchemaProps {
  isFieldEnabled?: boolean
  listenerField?: keyof CreateProjectFormikValueTypes
}

const DefaultSchema: FunctionComponent<SchemaProps> = ({
  isFieldEnabled = true,
  listenerField
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()

  const projectType = values.type.toLowerCase() || 'common'

  const {
    data: schemaDefaultData = [],
    isError,
    isLoading,
    error,
    mutate: mutateSchema
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.SCHEMA)

  const defaultSchemaError = renderFormikError(
    errors.defaultSchema,
    Boolean(touched.defaultSchema)
  )

  const getErrorMessage = useMemo(() => {
    if (isError && isFieldEnabled) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultSchema.error.loadingError')
    }
    if (!schemaDefaultData.length && isFieldEnabled) {
      return t('fields.defaultSchema.error.noItemsFound')
    }
    if (defaultSchemaError) return t(defaultSchemaError)
    return null
  }, [
    defaultSchemaError,
    isFieldEnabled,
    isError,
    schemaDefaultData.length,
    t,
    error
  ])

  const listenerFieldValue = listenerField ? values[listenerField] : ''
  const initialListenerFieldValue = listenerField
    ? initialValues[listenerField]
    : ''

  useEffect(() => {
    if (
      listenerFieldValue?.id !== initialListenerFieldValue?.id ||
      !isFieldEnabled
    ) {
      setFieldTouched('defaultSchema', false)
      setFieldValue('defaultSchema', { id: '', name: '' })
    }
    if (isFieldEnabled) {
      mutateSchema({
        values: createWarehouseDefaultsMutation(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFieldEnabled,
    mutateSchema,
    setFieldTouched,
    values.compute,
    listenerFieldValue
  ])

  return (
    <div data-testid={`${projectType}-default-schema`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={schemaDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultSchema.title')}
        name="defaultSchema"
        data-testid={`${projectType}-default-schema-input`}
        value={values.defaultSchema}
        placeholder={
          isError
            ? t('fields.defaultSchema.error.placeholderText')
            : t('fields.defaultSchema.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.defaultSchema.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultSchema?.id) &&
          Boolean(touched.defaultSchema) &&
          !isLoading
        }
        required
        disabled={(isError && !isLoading) || !isFieldEnabled}
      />
    </div>
  )
}

export default DefaultSchema
