import { POSTBranchRequest } from 'api/types'
import { FormValues } from 'modules/Projects/CreateBranch/CreateBranch.util'

export const createBranchMutationData = (
  formValues: FormValues
): POSTBranchRequest => ({
  name: formValues.branchName.trim(),
  parentBranch: formValues.branchFrom,
  defaultEnvironmentId: formValues.defaultEnvironment
})
