import { createContext, useContext } from 'react'
import { noop } from 'lodash'

import { Warehouse } from 'types'

interface CreateProjectsContextProviderProps {
  projectType: Warehouse | ''
  setProjectType: (projectType: Warehouse | '') => void
  secretLocationId: string
  setSecretLocationId: (id: string) => void
  secretReferenceId: string
  setSecretReferenceId: (id: string) => void
}

export const CreateProjectsContext =
  createContext<CreateProjectsContextProviderProps>({
    projectType: '',
    setProjectType: noop,
    secretLocationId: '',
    setSecretLocationId: noop,
    secretReferenceId: '',
    setSecretReferenceId: noop
  })

export const useCreateProjectsContext = (context = CreateProjectsContext) =>
  useContext(context)
