import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

const NoReadPermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('secrets.secretDefinitionListing.noReadPermission.title')}
      supportText={t(
        'secrets.secretDefinitionListing.noReadPermission.content'
      )}
      data-testid="no-read-permission-secret-definition"
    />
  )
}

export default NoReadPermission
