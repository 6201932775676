import { useFormikContext } from 'formik'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import DefaultDatabase from 'modules/Common/Fields/DefaultDatabase/DefaultDatabase'

const Database = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultRole?.id

  return (
    <DefaultDatabase
      listenerFields={['defaultRole']}
      isFieldEnabled={isFieldEnabled}
    />
  )
}

export default Database
