import { Button, Typography } from '@matillion/component-library'
import { MorePopover } from 'components/MorePopover'

import classes from 'components/EllipsesActionItem/EllipsesActionItem.module.scss'
import classNames from 'classnames'

export type ThemeType = 'danger' | 'normal'

interface EllipsesActionItemProps {
  actions: Array<{
    title: string
    icon?: JSX.Element
    action: () => void
    type?: ThemeType
    dataTestId?: string
  }>
}

const EllipsesActionItem = (props: EllipsesActionItemProps) => {
  const { actions } = props
  return (
    <MorePopover className={classes.EllipsesActionItem}>
      <div data-testid="ellipses-actions">
        {actions.map((action, index) => {
          return (
            <Button
              alt="text"
              key={`${action.title}-${index}`}
              onClick={action.action}
              className={classNames(
                classes.EllipsesActionItem__Button,
                action.type === 'danger' &&
                  classes['EllipsesActionItem__Button--danger']
              )}
              data-testid={action.dataTestId}
            >
              <>
                {action?.icon && (
                  <div className={classes.EllipsesActionItem__Icons}>
                    {action.icon}
                  </div>
                )}
                <Typography>{action.title}</Typography>
              </>
            </Button>
          )
        })}
      </div>
    </MorePopover>
  )
}
export default EllipsesActionItem
