import { FunctionComponent, PropsWithChildren, useMemo, useState } from 'react'

import { Warehouse } from 'types'
import { CreateProjectsContext } from './CreateProjectsContext'

export const CreateProjectsContextProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [projectType, setProjectType] = useState<Warehouse | ''>(
    Warehouse.Snowflake
  )
  const [secretLocationId, setSecretLocationId] = useState<string>('')
  const [secretReferenceId, setSecretReferenceId] = useState<string>('')

  const values = useMemo(
    () => ({
      projectType,
      setProjectType,
      secretLocationId,
      setSecretLocationId,
      secretReferenceId,
      setSecretReferenceId
    }),
    [projectType, secretLocationId, secretReferenceId]
  )

  return (
    <CreateProjectsContext.Provider value={values}>
      {children}
    </CreateProjectsContext.Provider>
  )
}
