import { Field } from '@matillion/component-library'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import classes from 'components/Form/Form.module.scss'
import { ChangeEvent, FocusEvent, FunctionComponent } from 'react'
import { ProjectDetailsI } from 'types/FormTypes'

type FormikValueTypes = Pick<ProjectDetailsI, 'projectName'>

const ProjectName: FunctionComponent = () => {
  const { t } = useTranslation()
  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    useFormikContext<FormikValueTypes>()

  return (
    <div data-testid="add-new-project-project-name">
      <Field
        maxLength={255}
        title={t('fields.projectName.title')}
        name="projectName"
        data-testid="add-new-project-project-name-input"
        value={values.projectName}
        placeholder={t('fields.projectName.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          setFieldValue('projectName', e.target.value.trim())
          handleBlur(e)
        }}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.projectName.supportText')}
        errorText={
          errors?.projectName && touched.projectName
            ? t(errors.projectName, { projectName: values.projectName })
            : null
        }
        hasError={!!errors?.projectName && touched.projectName}
        required
      />
    </div>
  )
}

export default ProjectName
