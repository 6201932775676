import { createContext } from 'react'
import { type EventContextBag } from './types'

export const EventContext = createContext<EventContextBag>({
  refreshWorkingTreeStatus: async () => {
    console.debug(
      'Cannot notify of WTS refresh status event as the GitEventContext is not initialised.'
    )
  }
})
