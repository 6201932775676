import { FunctionComponent, PropsWithChildren, useMemo, useState } from 'react'

import { Warehouse } from 'types'
import { CreateEnvironmentContext } from './CreateEnvironmentContext'

export const CreateEnvironmentProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [projectType, setProjectType] = useState<Warehouse>(Warehouse.Snowflake)
  const [secretLocationId, setSecretLocationId] = useState('')
  const [secretReferenceId, setSecretReferenceId] = useState('')
  const [tempSecretReferenceId, setTempSecretReferenceId] = useState('')

  const values = useMemo(
    () => ({
      projectType,
      setProjectType,
      secretLocationId,
      setSecretLocationId,
      secretReferenceId,
      setSecretReferenceId,
      tempSecretReferenceId,
      setTempSecretReferenceId
    }),
    [projectType, secretLocationId, secretReferenceId, tempSecretReferenceId]
  )

  return (
    <CreateEnvironmentContext.Provider value={values}>
      {children}
    </CreateEnvironmentContext.Provider>
  )
}
