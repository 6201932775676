import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { AgentsSecretsHost, WarehouseLabels } from 'types'

import { Header, HeaderTitle } from 'components/Header'
import {
  Account,
  Port,
  SecretKey,
  Ssl,
  SecretValue,
  SecretName,
  Username
} from 'modules/FormsContent/Sources/Fields'

import classes from 'components/Form/Form.module.scss'
import { CommonFormI } from 'types/FormTypes'

interface RedshiftCredentialsProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
  excludeHeader?: boolean
}

export const RedshiftCredentials: FunctionComponent<
  RedshiftCredentialsProps
> = ({
  onCancel,
  onPrevious,
  showCancel = true,
  showPrevious = false,
  modalContent,
  excludeHeader = false
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { projectConfiguration } = values

  const fields = (
    <>
      <Account translationProxy={'endpoint'} />
      <Port />
      <Ssl />
      <Username />
      {projectConfiguration === AgentsSecretsHost.MatillionHosted ? (
        <SecretValue />
      ) : (
        <>
          <SecretName />
          <SecretKey />
        </>
      )}
    </>
  )

  if (excludeHeader) {
    return fields
  }
  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="specify-credentials-title">
          {t('formContent.credentials.title', {
            projectType: WarehouseLabels.Redshift
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        {fields}
      </div>
    </>
  )
}
