import {
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_HOST_TYPE,
  PROJECTS_INFRASTRUCTURE_TYPE,
  PROJECTS_NEW_PROJECT,
  PROJECTS_REPOSITORY_TYPE
} from 'constants/route'

import { CreateFormNavigationSteps } from './types'

export type CreateFormNavigationStepsType = Record<
  CreateFormNavigationSteps,
  {
    previousStep?: CreateFormNavigationSteps
    currentStep: CreateFormNavigationSteps
    nextStep?: CreateFormNavigationSteps
  }
>

/**
 * The default steps for the CreateProject flow.
 *
 * The ordering of the steps is determined by the 'nextStep', where the order will begin from step that holds
 * undefined against 'previousStep' and finish on the step that holds undefined against the 'nextStep'.
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const steps: Partial<CreateFormNavigationStepsType> = {
  [PROJECTS_NEW_PROJECT]: {
    previousStep: undefined,
    currentStep: PROJECTS_NEW_PROJECT,
    nextStep: PROJECTS_INFRASTRUCTURE_TYPE
  },
  [PROJECTS_INFRASTRUCTURE_TYPE]: {
    previousStep: PROJECTS_NEW_PROJECT,
    currentStep: PROJECTS_INFRASTRUCTURE_TYPE,
    nextStep: PROJECTS_CREATE_ENVIRONMENT
  },
  [PROJECTS_CREATE_AGENT]: {
    previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
    currentStep: PROJECTS_CREATE_AGENT,
    nextStep: PROJECTS_CREATE_ENVIRONMENT
  },
  [PROJECTS_CREATE_ENVIRONMENT]: {
    previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
    currentStep: PROJECTS_CREATE_ENVIRONMENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_ENVIRONMENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
}

/**
 * The steps that is used for the CreateProject flow when the 'enable-host-type-screen' flag is on and 'enableByog' is off and HostType === Hybrid or not selected
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const stepsWithConditionalHostTypeNotSelectedOrHybrid: Partial<CreateFormNavigationStepsType> =
  {
    [PROJECTS_NEW_PROJECT]: {
      previousStep: undefined,
      currentStep: PROJECTS_NEW_PROJECT,
      nextStep: PROJECTS_HOST_TYPE
    },
    [PROJECTS_HOST_TYPE]: {
      previousStep: PROJECTS_NEW_PROJECT,
      currentStep: PROJECTS_HOST_TYPE,
      nextStep: PROJECTS_INFRASTRUCTURE_TYPE
    },
    [PROJECTS_INFRASTRUCTURE_TYPE]: {
      previousStep: PROJECTS_HOST_TYPE,
      currentStep: PROJECTS_INFRASTRUCTURE_TYPE,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_AGENT]: {
      previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
      currentStep: PROJECTS_CREATE_AGENT,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_ENVIRONMENT]: {
      previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
      currentStep: PROJECTS_CREATE_ENVIRONMENT,
      nextStep: PROJECTS_CREDENTIALS
    },
    [PROJECTS_CREDENTIALS]: {
      previousStep: PROJECTS_CREATE_ENVIRONMENT,
      currentStep: PROJECTS_CREDENTIALS,
      nextStep: PROJECTS_DEFAULTS
    },
    [PROJECTS_DEFAULTS]: {
      previousStep: PROJECTS_CREDENTIALS,
      currentStep: PROJECTS_DEFAULTS,
      nextStep: undefined
    }
  }

/**
 * The steps that is used for the CreateProject flow when the 'enable-host-type-screen' flag is on and HostType === FULL Saas
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const stepsWithConditionalHostTypeFullSaas: Partial<CreateFormNavigationStepsType> =
  {
    [PROJECTS_NEW_PROJECT]: {
      previousStep: undefined,
      currentStep: PROJECTS_NEW_PROJECT,
      nextStep: PROJECTS_HOST_TYPE
    },
    [PROJECTS_HOST_TYPE]: {
      previousStep: PROJECTS_NEW_PROJECT,
      currentStep: PROJECTS_HOST_TYPE,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_AGENT]: {
      previousStep: PROJECTS_HOST_TYPE,
      currentStep: PROJECTS_CREATE_AGENT,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_ENVIRONMENT]: {
      previousStep: PROJECTS_HOST_TYPE,
      currentStep: PROJECTS_CREATE_ENVIRONMENT,
      nextStep: PROJECTS_CREDENTIALS
    },
    [PROJECTS_CREDENTIALS]: {
      previousStep: PROJECTS_CREATE_ENVIRONMENT,
      currentStep: PROJECTS_CREDENTIALS,
      nextStep: PROJECTS_DEFAULTS
    },
    [PROJECTS_DEFAULTS]: {
      previousStep: PROJECTS_CREDENTIALS,
      currentStep: PROJECTS_DEFAULTS,
      nextStep: undefined
    }
  }

/**
 * The steps that is used for the CreateProject flow when the 'enable-host-type-screen' and 'enable-byog' flags are on,
 * and when 'HostType' is 'Hybrid'.
 *
 * NOTE: PROJECTS_CREATE_AGENT step is required because when nextStep is PROJECTS_CREATE_ENVIRONMENT logic is present in CreateProjectForm.tsx
 * to call POST MHA and if the response is not HEALTHY, user will be navigated to PROJECTS_CREATE_AGENT
 */
export const stepsWithConditionalBYOG: Partial<CreateFormNavigationStepsType> =
  {
    [PROJECTS_NEW_PROJECT]: {
      previousStep: undefined,
      currentStep: PROJECTS_NEW_PROJECT,
      nextStep: PROJECTS_HOST_TYPE
    },
    [PROJECTS_HOST_TYPE]: {
      previousStep: PROJECTS_NEW_PROJECT,
      currentStep: PROJECTS_HOST_TYPE,
      nextStep: PROJECTS_REPOSITORY_TYPE
    },
    [PROJECTS_REPOSITORY_TYPE]: {
      previousStep: PROJECTS_HOST_TYPE,
      currentStep: PROJECTS_REPOSITORY_TYPE,
      nextStep: PROJECTS_INFRASTRUCTURE_TYPE
    },
    [PROJECTS_INFRASTRUCTURE_TYPE]: {
      previousStep: PROJECTS_REPOSITORY_TYPE,
      currentStep: PROJECTS_INFRASTRUCTURE_TYPE,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_AGENT]: {
      previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
      currentStep: PROJECTS_CREATE_AGENT,
      nextStep: PROJECTS_CREATE_ENVIRONMENT
    },
    [PROJECTS_CREATE_ENVIRONMENT]: {
      previousStep: PROJECTS_INFRASTRUCTURE_TYPE,
      currentStep: PROJECTS_CREATE_ENVIRONMENT,
      nextStep: PROJECTS_CREDENTIALS
    },
    [PROJECTS_CREDENTIALS]: {
      previousStep: PROJECTS_CREATE_ENVIRONMENT,
      currentStep: PROJECTS_CREDENTIALS,
      nextStep: PROJECTS_DEFAULTS
    },
    [PROJECTS_DEFAULTS]: {
      previousStep: PROJECTS_CREDENTIALS,
      currentStep: PROJECTS_DEFAULTS,
      nextStep: undefined
    }
  }
