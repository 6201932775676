import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postSchedule } from 'api/clients'
import { POSTScheduleValues } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: POSTScheduleValues
  }
>

export const usePostSchedule = (projectId: string, options?: Options) => {
  const client = useClient()

  return useMutation(
    [QueryKey.CREATE_SCHEDULE, projectId],
    async (data: { values: POSTScheduleValues }) => {
      const { values } = data
      return postSchedule({ projectId, ...values }, client)
    },
    options
  )
}
