export const ASSOCIATE_CREDENTIALS_STORAGE_ITEM = 'associateCredentialsForm'
export const ASSOCIATE_ENVIRONMENT_TO_CREDENTIALS_STORAGE_ITEM =
  'associateEnvironmentToCredentialsForm'
export const CLOUD_PROVIDER_CREDS_STORAGE_ITEM = 'cloudProviderCredentials'
export const CREATE_BRANCH = 'createBranch'
export const CREATE_PROJECT_STORAGE_ITEM = 'createProjectForm'
export const CREATE_SCHEDULE = 'createSchedule'
export const EDIT_BRANCH = 'editBranch'
export const ENVIRONMENT_STORAGE_ITEM = 'environmentForm'
export const OAUTH_STORAGE_ITEM = 'oAuthForm'
export const ONBOARDING_STORAGE_ITEM = 'onboardingForm'
