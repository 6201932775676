import { useFormikContext } from 'formik'
import DefaultSchema from 'modules/Common/Fields/DefaultSchema/DefaultSchema'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const Schema = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultDatabase?.id

  return (
    <DefaultSchema
      listenerField="defaultDatabase"
      isFieldEnabled={isFieldEnabled}
    />
  )
}

export default Schema
