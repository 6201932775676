import { UseQueryOptions, useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { getEnvironmentAgents } from 'api/clients'
import { useClient } from 'api/hooks'
import { GETEnvironmentAgentsResponse } from 'api/types/EnvironmentAgentsResponse'
import { QueryKey } from 'constants/endpoint'

type TData = GETEnvironmentAgentsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetEnvironmentAgents = (options?: Options) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.ENVIRONMENTS_AGENTS],
    async () => getEnvironmentAgents(client),
    options
  )
}
