import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  LoadingSpinner,
  SortState,
  Toaster,
  Typography
} from '@matillion/component-library'

import { useGetPermissions, useGetSecretReferences } from 'api/hooks'
import { queryClient } from 'api/queryClient'
import { GETSecretReferencesResponseParams } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'
import { sortByColumn } from 'modules/Secrets/utils'
import { PermissionsType, SecretReferenceTypes } from 'types'

import ListingHeader from './components/ListingHeader'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import OAuthDataGrid from './components/OAuthDataGrid'

const OAuthListing: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [sortState, setSortState] = useState<
    Partial<SortState<GETSecretReferencesResponseParams>>
  >({ name: 'ASC' })

  const {
    data: oAuthsData = [],
    isError: isOAuthError,
    isLoading: isOAuthLoading,
    refetch
  } = useGetSecretReferences(projectId!, [
    SecretReferenceTypes.OAUTH_USER_TOKENS,
    SecretReferenceTypes.OAUTH_CLIENT_CRED,
    SecretReferenceTypes.COMPOSITE_TOKEN
  ])

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.CreateOAuths)
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.ViewOAuths)

  const sortedOAuths = useMemo(
    () => sortByColumn(oAuthsData, sortState),
    [sortState, oAuthsData]
  )

  const redirectCallback = () =>
    navigate(AppRoutes.getCreateOAuthConnection(projectId!))

  const handleDelete = (name: string, isSuccess = true) => {
    if (isSuccess) {
      refetch()

      makeToast({
        title: t('secrets.deleteOAuth.responseMessage.success', {
          oauth: name
        }),
        message: '',
        type: 'success'
      })
    } else {
      makeToast({
        title: t('secrets.deleteOAuth.responseMessage.error', {
          oauth: name
        }),
        message: '',
        type: 'error'
      })
    }
  }

  const getContent = () => {
    return isOAuthError ? (
      <Typography>{t('secrets.oauthListing.error')}</Typography>
    ) : (
      <OAuthDataGrid
        oauths={sortedOAuths!}
        onSort={setSortState}
        onDeleteHandler={handleDelete}
      />
    )
  }

  useEffect(() => {
    sortByColumn(oAuthsData, sortState)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateOAuths
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteOAuths
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !oAuthsData.length &&
    !isOAuthError &&
    !isOAuthLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission?.result) {
      return <NoCreatePermission />
    } else {
      return <NoData onAddOAuth={redirectCallback} />
    }
  }

  return (
    <>
      <ListingHeader
        onAddOAuth={redirectCallback}
        permission={createPermission?.result}
      />
      {isOAuthLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !oAuthsData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission?.result ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default OAuthListing
