import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Toaster } from '@matillion/component-library'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import { StatusCodes } from 'constants/statusCodes'
import {
  useMhaProvisioningWithPolling,
  useWarehouseProvisioningWithPolling
} from 'hooks'
import { useFinishOnboarding } from 'modules/Onboarding/OnboardingForm/hooks'
import { usePostWarehouseProvisioning } from 'api/hooks'
import { useCreateMha } from 'modules/Onboarding/OnboardingForm/hooks/useCreateMha'
import { useFormikContext } from 'formik'
import { OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'
import { ProvisioningContainer } from 'modules/FormsContent/ProvisioningContainer'
import { StatusHeader } from 'components/StatusHeader'

interface WarehouseProvisioningProps {
  onRedirectPrevious: () => void
}

const MAX_RETRIES = 12

const WarehouseProvisioning: FunctionComponent<WarehouseProvisioningProps> = ({
  onRedirectPrevious
}) => {
  const { t } = useTranslation()
  const { makeToast } = Toaster.useToaster()
  const { values } = useFormikContext<OnboardingFormikValueTypes>()
  const [createMhaComplete, setCreateMhaComplete] = useState(false)
  const [
    trialWarehouseProvisioningComplete,
    setTrialWarehouseProvisioningComplete
  ] = useState(false)
  const [currentRetries, setCurrentRetries] = useState(0)
  const createMha = useCreateMha(onRedirectPrevious)
  const { agentComplete, mhaData } = useMhaProvisioningWithPolling({
    onError: onRedirectPrevious,
    enabled: createMhaComplete
  })
  const {
    mutateAsync,
    isError: isCreateWarehouseError,
    error: createWarehouseError
  } = usePostWarehouseProvisioning()
  const isWarehouseError =
    isCreateWarehouseError &&
    createWarehouseError.response?.status !== StatusCodes.CONFLICT
  const [warehouseProvisioningComplete] = useWarehouseProvisioningWithPolling(
    onRedirectPrevious,
    trialWarehouseProvisioningComplete
  )
  const {
    isComplete: onboardingComplete,
    isError: onboardingError,
    mutateProject: finishOnboarding
  } = useFinishOnboarding(onRedirectPrevious)

  const initialisePostWarehouseProvisioning = useCallback(() => {
    if (!agentComplete || !mhaData?.agentId) return false
    mutateAsync({
      values: { agentId: mhaData.agentId }
    })
      .then(() => {
        setTrialWarehouseProvisioningComplete(true)
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === StatusCodes.CONFLICT) {
          return setTrialWarehouseProvisioningComplete(true)
        }

        if (currentRetries === MAX_RETRIES) {
          onRedirectPrevious()
          return makeToast({
            title: t('formContent.warehouse.responseMessage.error'),
            message: '',
            type: 'error'
          })
        }
        // For the time being we want to retry a lot of times (12 to be exact) every 10 seconds
        // This is due to the fact that the warehouse can take a while to provision (sometimes a couple of mins)
        // TO-DO: Remove retries when SEPE-1321 is done
        setTimeout(() => {
          // The change of retries will cause this callback to be fetched again as expected
          setCurrentRetries(currentRetries + 1)
        }, 1000 * 10)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentComplete, currentRetries, makeToast, mhaData, mutateAsync])

  useEffect(() => {
    initialisePostWarehouseProvisioning()
  }, [initialisePostWarehouseProvisioning])

  useEffect(() => {
    createMha().then(() => {
      setCreateMhaComplete(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      warehouseProvisioningComplete &&
      !!values.secretLocationId &&
      !!values.defaultRole?.id
    ) {
      finishOnboarding(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    warehouseProvisioningComplete,
    values.secretLocationId,
    values.defaultRole?.id
  ])

  return (
    <ProvisioningContainer>
      <StatusHeader
        primaryText={t('formContent.warehouse.primaryText')}
        secondaryText={t('formContent.warehouse.secondaryText')}
        isComplete={agentComplete && onboardingComplete}
        isError={isWarehouseError || onboardingError}
        data-testid="trial-warehouse-provisioning-agent-status"
      />
    </ProvisioningContainer>
  )
}

export default WarehouseProvisioning
