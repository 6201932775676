import { GETProjectMembersResponseParams } from 'api/types'

export enum ColumnKeys {
  userName = 'name',
  projectRole = 'projectRoleName',
  actions = 'actions'
}

export interface ActionCellTypes {
  member: GETProjectMembersResponseParams
  currentUserId: string
  currentUserRole: string
  onRemoveClick: (item: GETProjectMembersResponseParams) => void
  onEditClick: (item: GETProjectMembersResponseParams) => void
}
