import { FunctionComponent } from 'react'
import { To, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormikHelpers } from 'formik'
import { AxiosError } from 'axios'
import { Toaster } from '@matillion/component-library'

import {
  useGetEnvironments,
  useGetMha,
  useGetProject,
  useMutateWarehouseDefaults,
  usePostEnvironment,
  usePostSecretReference,
  usePostSecretValueV2
} from 'api/hooks'
import {
  createEnvironmentMutationData,
  createSecretReferenceMutationData,
  createSecretValueV2MutationData,
  createWarehouseDefaultsMutation
} from 'api/mutation'
import Form from 'components/Form'
import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECTS_DEFAULTS
} from 'constants/route'
import { useFormNavigation } from 'hooks'
import ConfigureEnvironmentFormsRoutes from 'modules/Projects/CreateEnvironment/ConfigureRoutes'
import {
  initialValues,
  steps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/CreateEnvironmentForm.util'
import { useValidationSchema } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/hooks'
import { AgentsSecretsHost, SecretReferenceTypes, Warehouse } from 'types'
import { queryClient } from 'api/queryClient'
import { QueryKey } from 'constants/endpoint'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import { ErrorResponse } from 'api/types'
import { StatusCodes } from 'constants/statusCodes'
import { CreateEnvironmentFormikValueTypes } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'
import { useMutateDatabricksComputeResources } from 'api/hooks/useMutateDatabricksComputeResources'
import { createGetWarehouseComputeResourceMutationData } from 'api/createProjectForm/mutation'

const CreateEnvironmentForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { data: projectData } = useGetProject(projectId!)
  const { mutateAsync: mutateAsyncEnvironment } = usePostEnvironment(projectId!)
  const { isLastStep, nextStep, progress, stepIndex } = useFormNavigation(steps)
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()

  const { mutateAsync: mutateAsyncSecretValueV2 } = usePostSecretValueV2()
  const { refetch } = useGetEnvironments(projectId!)
  const { refetch: mhaRefetch } = useGetMha({ enabled: false })

  const validationSchema = useValidationSchema()

  const projectName = projectData?.name as string
  const projectType = projectData?.warehouse ?? ''
  const secretLocationId = projectData?.secretLocationIds[0] as string
  const agentAndSecretHostLocation =
    projectData?.agentAndSecretHostLocation ?? AgentsSecretsHost.CustomerHosted

  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)
  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)
  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()

  const handleOnSubmit = () => {
    refetch()
    return navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS),
      {
        replace: true
      }
    )
  }

  const publishEnvironment = async (
    values: CreateEnvironmentFormikValueTypes,
    onPublishStatus: (success: boolean, environmentName?: string) => void,
    onFinish: () => void
  ) =>
    mutateAsyncEnvironment({
      values: createEnvironmentMutationData(values, projectName)
    })
      .then((res) => {
        onPublishStatus(true, res.name)
        queryClient.invalidateQueries([QueryKey.SECRET_REFERENCES, projectId])
      })
      .catch((_e) => {
        onPublishStatus(false)
      })
      .finally(onFinish)

  const submitForm = async (
    newValues: CreateEnvironmentFormikValueTypes,
    {
      resetForm,
      setSubmitting
    }: FormikHelpers<CreateEnvironmentFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)

    newValues = {
      ...newValues,
      secretLocationId
    }
    if (isLastStep) {
      await publishEnvironment(
        newValues,
        (success, environmentName) => {
          if (success) {
            makeToast({
              title: t('createEnvironment.responseMessage.success', {
                environmentName
              }),
              message: '',
              type: 'success'
            })

            window.sessionStorage.removeItem(ENVIRONMENT_STORAGE_ITEM)
            return handleOnSubmit()
          }

          makeToast({
            title: t('createEnvironment.responseMessage.error'),
            message: '',
            type: 'error'
          })
        },
        () => setSubmitting(false)
      )
    } else {
      try {
        if (nextStep === PROJECTS_DEFAULTS) {
          let response
          if (
            newValues.projectConfiguration === AgentsSecretsHost.MatillionHosted
          ) {
            // TO-DO: Sad path if the agent isn't ready -  SEPE:928
            const { data: currentMha = { agentId: '' } } = await mhaRefetch()
            response = await mutateAsyncSecretValueV2({
              values: createSecretValueV2MutationData({
                ...newValues,
                name: newValues.environmentName,
                secretValue: {
                  password: newValues.secretValue
                },
                matillionHostedAgentId: currentMha.agentId
              })
            })
            newValues = {
              ...newValues,
              matillionHostedAgentId: currentMha.agentId
            }
          } else {
            response = await mutateAsyncSecretReference({
              values: createSecretReferenceMutationData(
                newValues,
                SecretReferenceTypes.DWH_PASSWORD
              )
            })
          }
          newValues = {
            ...newValues,
            secretReferenceId: response.secretId,
            defaultRole: initialValues.defaultRole,
            defaultDatabase: initialValues.defaultDatabase,
            compute: initialValues.compute
          }

          // Ensure that there is a connection to warehouse defaults role in order to continue
          // Otherwise it throws an error which is caught below and does not proceed to the next page
          switch (newValues.type) {
            case Warehouse.Snowflake:
              await mutateAsyncWarehouseRoleLookup({
                values: createWarehouseDefaultsMutation(newValues)
              })
              break
            case Warehouse.Redshift:
              await mutateAsyncWarehouseDatabaseLookup({
                values: createWarehouseDefaultsMutation(newValues)
              })
              break
            case Warehouse.Databricks:
              await mutateAsyncWarehouseComputeResourceLookup({
                values: createGetWarehouseComputeResourceMutationData(newValues)
              })
              break
          }
        }

        resetForm({ values: newValues })
        navigate(nextStep as To, { replace: true })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>
        const errorDetail = (err?.response?.data as ErrorResponse)?.detail
        const errorResponseStatus = err?.response?.status

        makeToast({
          title: t('error.unexpected.title'),
          message:
            errorResponseStatus === StatusCodes.BAD_REQUEST &&
            errorDetail !== undefined
              ? errorDetail
              : t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <Form<CreateEnvironmentFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues: {
          ...initialValues,
          projectConfiguration: agentAndSecretHostLocation,
          type: projectType
        },
        validationSchema: validationSchema,
        initialTouched: false
      }}
      stepInfo={{
        stepIndex,
        isLastStep,
        progress
      }}
      translationPrefix="createEnvironment"
      persistingStorageId={ENVIRONMENT_STORAGE_ITEM}
      persistenceExclusions={['secretValue']}
    >
      <ConfigureEnvironmentFormsRoutes />
    </Form>
  )
}
export default CreateEnvironmentForm
