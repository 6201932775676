import {
  ChangeEvent,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import {
  AutoComplete,
  AutoCompleteItemId,
  Field
} from '@matillion/component-library'

import { renderFormikError } from 'modules/utils'
import { OAuthFormI } from 'types/FormTypes'
import classes from 'components/Form/Form.module.scss'

import { useFlags } from 'hooks/flags'

import { useGetOAuthSchema } from 'api/hooks'
import { useParams } from 'react-router-dom'
import { OAuthParameter, OAuthSchema } from 'api/types'
import { getProviderList } from 'modules/FormsContent/CreateOAuth/createOAuthHelper'

export interface ProviderProps {
  setSelectedOAuth: React.Dispatch<SetStateAction<OAuthSchema | undefined>>
  setOAuthParameter: React.Dispatch<
    SetStateAction<OAuthParameter[] | undefined>
  >
  oAuthParameter: OAuthParameter[] | undefined
}
export const Provider: FunctionComponent<ProviderProps> = ({
  setSelectedOAuth,
  setOAuthParameter,
  oAuthParameter
}) => {
  const { projectId } = useParams()
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldTouched,
    setFieldValue
  } = useFormikContext<OAuthFormI>()
  const { t } = useTranslation()
  const { oauthAllowedProviders } = useFlags()
  const isEnabled = !!values.environmentAgent?.id

  const {
    data: oauthSchema = [],
    isError,
    isLoading
  } = useGetOAuthSchema(
    {
      projectId: projectId!,
      agentId: values.environmentAgent?.id
    },
    { enabled: isEnabled }
  )

  const formikError = renderFormikError(
    errors.provider,
    Boolean(touched.provider)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('secrets.createOAuth.fields.provider.error.loadingError')
    } else if (formikError) {
      return t(formikError)
    } else if (!oauthSchema.length && isEnabled) {
      return t('secrets.createOAuth.fields.provider.error.noItemsFound')
    }
    return ''
  }, [formikError, t, isEnabled, isError, oauthSchema])

  const listOfProviders = getProviderList(
    oauthSchema,
    oauthAllowedProviders as unknown as string[],
    t
  )

  const handleOnProviderChange = useCallback(
    (value: AutoCompleteItemId) => {
      setFieldTouched('authType', false)
      setFieldValue('authType', { id: '', name: '' })
      oAuthParameter?.forEach((x) => {
        setFieldValue(x.id, '')
      })
      setOAuthParameter(undefined)
      if (!value) {
        setSelectedOAuth(undefined)
        return
      }
      const oAuth = oauthSchema.find((x) => x.provider === value.id)
      setSelectedOAuth(oAuth)
    },
    [
      oAuthParameter,
      oauthSchema,
      setFieldTouched,
      setFieldValue,
      setOAuthParameter,
      setSelectedOAuth
    ]
  )

  return (
    <div data-testid="create-oauth-provider">
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfProviders}
        loading={isLoading}
        title={t('secrets.createOAuth.fields.provider.title')}
        name="provider"
        data-testid="create-oauth-provider-input"
        value={values.provider}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target
          handleOnProviderChange(value as unknown as AutoCompleteItemId)
          handleChange(e)
        }}
        placeholder={
          isError
            ? t('secrets.createOAuth.fields.provider.error.placeholderText')
            : t('secrets.createOAuth.fields.provider.placeholderText')
        }
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('secrets.createOAuth.fields.provider.supportText')}
        errorText={isLoading ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.provider?.id) &&
          Boolean(touched.provider) &&
          !isLoading
        }
        disabled={isError || !isEnabled || !oauthSchema.length}
        required
      />
    </div>
  )
}
