import { GETWarehouseDefaultsRequest } from 'api/createProjectForm/types'
import { AutoCompleteProps } from 'types/FormTypes'
import { AgentsSecretsHost, Warehouse } from 'types'
import { GETDatabricksComputeResourceResponseParams } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'

interface FormValues {
  account: string
  tempAccount?: string
  matillionHostedAgentId: string
  etlAgent: AutoCompleteProps
  tempSecretReferenceId?: string
  secretReferenceId: string
  username: string
  type: '' | Warehouse
  tempUsername?: string
  role?: string
  database?: string
  catalog?: AutoCompleteProps
  compute?: GETDatabricksComputeResourceResponseParams & AutoCompleteProps
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  ssl?: boolean | null
  port?: number | null
  projectConfiguration: string
}

export const createWarehouseDefaultsMutation = (
  formValues: FormValues
): GETWarehouseDefaultsRequest => ({
  // Using temporary first so that if the page is refreshed before the credentials have been tested
  // It will fetch the defaults based on the old values
  // This way the user can revert the changes to the initial ones and everything will remain as expected
  account: formValues.tempAccount?.trim() || formValues.account.trim(),
  agentId:
    formValues.projectConfiguration === AgentsSecretsHost.MatillionHosted
      ? formValues.matillionHostedAgentId
      : formValues.etlAgent.id,
  type: formValues.type,
  secretReferenceId:
    formValues.tempSecretReferenceId || formValues.secretReferenceId,
  username: formValues.tempUsername?.trim() || formValues.username.trim(),
  ...(formValues.role && { role: formValues.role }),
  ...(formValues.database && { database: formValues.database }),
  ...(formValues.defaultRole && {
    role: formValues.defaultRole?.id || undefined
  }),
  ...(formValues.defaultDatabase && {
    database: formValues.defaultDatabase?.id || undefined
  }),
  ...(formValues.catalog && { catalog: formValues.catalog.id || undefined }),
  ...(formValues.compute && {
    clusterId: formValues.compute.clusterId || undefined,
    clusterName: formValues.compute.clusterName || undefined,
    clusterType: formValues.compute.clusterType || undefined
  }),
  ssl: formValues.ssl,
  port: formValues.port
})
