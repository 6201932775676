import { Fragment, useCallback, useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { CREATE_PROJECT_STORAGE_ITEM } from 'constants/persistance'
import {
  PROJECTS_ADD,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_HOST_TYPE,
  PROJECTS_INFRASTRUCTURE_TYPE,
  PROJECTS_NEW_PROJECT,
  PROJECTS_REPOSITORY_TYPE,
  ROOT
} from 'constants/route'
import AddNewProject from 'modules/FormsContent/AddNewProject'
import CreateEnvironment from 'modules/FormsContent/CreateEnvironment'
import CreateAgent from 'modules/FormsContent/CreateAgent'
import HostTypeSelection from 'modules/FormsContent/HostTypeSelection'
import RepositoryType from 'modules/FormsContent/RepositoryType'
import * as Sources from 'modules/FormsContent/Sources'
import {
  CreateProjectFormikValueTypes,
  getInitialValues,
  previousStepOrDefault,
  useSteps
} from 'modules/Projects/CreateProject/CreateProjectForm'
import { useFlags } from 'hooks/flags'
import { HostType } from 'types'
import InfrastructureSelection from 'modules/FormsContent/InfrastructureSelection'
import { capitalize } from 'lodash'

const CreateProjectRoutes = () => {
  const navigate = useNavigate()
  const { setValues, values } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const { enableByog, enableHostTypeScreen } = useFlags()
  const allSteps = useSteps({ hostType: values.hostType })
  const persistedFormValues = window.sessionStorage.getItem(
    CREATE_PROJECT_STORAGE_ITEM
  )
  const projectType = persistedFormValues
    ? JSON.parse(persistedFormValues).type
    : ''

  const warehouseRoutes = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(projectType)) {
        result.push(
          <Fragment key={`${label}-route`}>
            <Route
              path={`${PROJECTS_CREDENTIALS}/*`}
              element={
                <Component
                  showCancel
                  onCancel={() => navigate(ROOT)}
                  onPrevious={() =>
                    navigate(
                      previousStepOrDefault(PROJECTS_CREDENTIALS, allSteps),
                      {
                        replace: true
                      }
                    )
                  }
                  showPrevious
                  modalContent={t('createProject.modal.content')}
                />
              }
            />
            <Route
              path={`${PROJECTS_DEFAULTS}/*`}
              element={
                <Component
                  showCancel
                  onCancel={() => navigate(ROOT)}
                  onPrevious={() =>
                    navigate(
                      previousStepOrDefault(PROJECTS_DEFAULTS, allSteps),
                      {
                        replace: true
                      }
                    )
                  }
                  showPrevious
                  modalContent={t('createProject.modal.content')}
                />
              }
            />
          </Fragment>
        )
      }
      return result
    },
    []
  )

  const redirectToStartOfForm = useCallback(() => {
    navigate(PROJECTS_NEW_PROJECT, { replace: true })
  }, [navigate])

  // If session storage gets deleted then redirect to the start of the form
  useEffect(() => {
    if (
      !persistedFormValues &&
      window.location.pathname !== `/${PROJECTS_ADD}/${PROJECTS_NEW_PROJECT}`
    ) {
      redirectToStartOfForm()
      setValues(getInitialValues())
    }
  }, [navigate, persistedFormValues, redirectToStartOfForm, setValues])

  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate replace to={PROJECTS_NEW_PROJECT} />}
      />
      <Route
        path={`${PROJECTS_NEW_PROJECT}/*`}
        element={
          <AddNewProject
            showCancel
            onCancel={() => navigate(ROOT)}
            modalContent={t('createProject.modal.content')}
          />
        }
      />
      <Route
        path={`${PROJECTS_HOST_TYPE}/*`}
        element={
          enableHostTypeScreen ? (
            <HostTypeSelection
              onCancel={() => navigate(ROOT)}
              onPrevious={() =>
                navigate(previousStepOrDefault(PROJECTS_HOST_TYPE, allSteps), {
                  replace: true
                })
              }
              modalContent={t('createProject.modal.content')}
            />
          ) : (
            <Navigate to={`../${PROJECTS_NEW_PROJECT}`} />
          )
        }
      />
      {enableByog && values.hostType !== HostType.FullHosted && (
        <Route
          path={`${PROJECTS_REPOSITORY_TYPE}/*`}
          element={
            <RepositoryType
              showCancel
              onCancel={() => navigate(ROOT)}
              onPrevious={() =>
                navigate(
                  previousStepOrDefault(PROJECTS_REPOSITORY_TYPE, allSteps),
                  {
                    replace: true
                  }
                )
              }
              showPrevious
              modalContent={t('createProject.modal.content')}
            />
          }
        />
      )}
      <Route
        path={`${PROJECTS_INFRASTRUCTURE_TYPE}/*`}
        element={
          <InfrastructureSelection
            showCancel
            onCancel={() => navigate(ROOT)}
            onPrevious={() =>
              navigate(
                previousStepOrDefault(PROJECTS_INFRASTRUCTURE_TYPE, allSteps),
                {
                  replace: true
                }
              )
            }
            showPrevious
            modalContent={t('createProject.modal.content')}
          />
        }
      ></Route>
      <Route
        path={`${PROJECTS_CREATE_AGENT}/*`}
        element={
          <CreateAgent
            showCancel
            onCancel={() => navigate(ROOT)}
            onRedirectPrevious={() =>
              navigate(previousStepOrDefault(PROJECTS_CREATE_AGENT, allSteps), {
                replace: true
              })
            }
            onRedirectNext={() =>
              navigate(PROJECTS_CREATE_ENVIRONMENT, { replace: true })
            }
            modalContent={t('createProject.modal.content')}
          />
        }
      />
      <Route
        path={`${PROJECTS_CREATE_ENVIRONMENT}/*`}
        element={
          <CreateEnvironment
            showCancel
            onCancel={() => navigate(ROOT)}
            onPrevious={() =>
              navigate(
                previousStepOrDefault(PROJECTS_CREATE_ENVIRONMENT, allSteps),
                { replace: true }
              )
            }
            showPrevious
            modalContent={t('createProject.modal.content')}
          />
        }
      />
      {warehouseRoutes}
    </Routes>
  )
}

export default CreateProjectRoutes
