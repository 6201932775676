import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  BringYourOwnGitForm,
  GitProvider
} from '@matillion/git-component-library'

import { Header, HeaderTitle } from 'components/Header'
import config from 'config'
import classes from 'components/Form/Form.module.scss'

interface RepositoryTypeProps {
  onCancel?: () => void
  onPrevious: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
}

const RepositoryType: FunctionComponent<RepositoryTypeProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        showPrevious={showPrevious}
        modalContent={modalContent}
        onPrevious={onPrevious}
      >
        <HeaderTitle data-testid="repository-type-title">
          {t('formContent.repository.title')}
        </HeaderTitle>
      </Header>
      <GitProvider environment={config.environment}>
        <div
          className={classNames(
            classes.Form__InnerWrap,
            classes['Form__InnerWrap--space-top']
          )}
        >
          <BringYourOwnGitForm />
        </div>
      </GitProvider>
    </>
  )
}

export default RepositoryType
