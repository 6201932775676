import {
  ChangeEvent,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import {
  AutoComplete,
  AutoCompleteItemId,
  Field
} from '@matillion/component-library'

import { renderFormikError } from 'modules/utils'
import { OAuthFormI } from 'types/FormTypes'
import classes from 'components/Form/Form.module.scss'

import { OAuthParameter, OAuthSchema } from 'api/types'
import { getOAuthTypeList } from 'modules/FormsContent/CreateOAuth/createOAuthHelper'
import { useFlags } from 'hooks/flags'

export interface AuthenticationTypeProps {
  setOAuthParameter: React.Dispatch<
    SetStateAction<OAuthParameter[] | undefined>
  >
  selectedOAuth: OAuthSchema | undefined
  oAuthParameter: OAuthParameter[] | undefined
}

export const AuthenticationType: FunctionComponent<AuthenticationTypeProps> = ({
  setOAuthParameter,
  selectedOAuth,
  oAuthParameter
}) => {
  const { oauthAllowedAuthTypes } = useFlags()

  const { errors, handleBlur, handleChange, touched, values, setFieldValue } =
    useFormikContext<OAuthFormI>()
  const { t } = useTranslation()

  const formikError = renderFormikError(
    errors.authType,
    Boolean(touched.authType)
  )

  const getErrorMessage = useMemo(() => {
    if (formikError) {
      return t(formikError)
    }

    return ''
  }, [formikError, t])

  const listOfAuthenticationTypes = useMemo(() => {
    return selectedOAuth
      ? getOAuthTypeList(
          selectedOAuth,
          oauthAllowedAuthTypes as unknown as string[],
          t
        )
      : []
  }, [oauthAllowedAuthTypes, selectedOAuth, t])

  const handleOnAuthTypeChange = useCallback(
    (value: AutoCompleteItemId) => {
      oAuthParameter?.forEach((x) => {
        setFieldValue(x.id, '')
      })
      setOAuthParameter(undefined)
      if (!value || !selectedOAuth) {
        return
      }

      const selectedOauthType = selectedOAuth.authTypes.find(
        (x) => x.type === value.id
      )

      const parameters = selectedOauthType?.parameters

      setOAuthParameter(parameters)
    },
    [oAuthParameter, selectedOAuth, setFieldValue, setOAuthParameter]
  )

  return (
    <div data-testid="create-oauth-authtype">
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfAuthenticationTypes}
        title={t('secrets.createOAuth.fields.authType.title')}
        name="authType"
        data-testid="create-oauth-authtype-input"
        value={values.authType}
        placeholder={t('secrets.createOAuth.fields.authType.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target
          handleOnAuthTypeChange(value as unknown as AutoCompleteItemId)
          handleChange(e)
        }}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('secrets.createOAuth.fields.authType.supportText')}
        errorText={getErrorMessage}
        hasError={Boolean(errors?.authType?.id) && Boolean(touched.authType)}
        disabled={!listOfAuthenticationTypes.length}
        required
      />
    </div>
  )
}
