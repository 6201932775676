import { useQuery } from '@tanstack/react-query'

import { type BranchInformation } from 'api/hooks/useGetBranch/types'
import { queryKeys } from 'api/query-keys'
import useDesignerClient from 'api/clients/useDesignerClient'
import { type ReactQueryOptions } from 'types/ReactQueryOptions'

const useGetBranch = (branchName?: string, config?: ReactQueryOptions) => {
  const { project, client } = useDesignerClient()

  return useQuery(
    [queryKeys.legacyMerge, project, branchName, queryKeys.getBranch],
    async () => {
      const url = `branches/${branchName}?projectId=${project}`
      const { data } = await client.get<BranchInformation>(url)
      return data
    },
    { ...config }
  )
}

export default useGetBranch
