import { FunctionComponent, PropsWithChildren, useMemo, useState } from 'react'

import { Warehouse } from 'types'
import { OnboardingContext } from './OnboardingContext'

export const OnboardingContextProvider: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [projectType, setProjectType] = useState<Warehouse | ''>(
    Warehouse.Snowflake
  )
  const [secretLocationId, setSecretLocationId] = useState<string>('')
  const [secretReferenceId, setSecretReferenceId] = useState<string>('')
  const [isTrialWarehouse, setIsTrialWarehouse] = useState(true)

  const values = useMemo(
    () => ({
      projectType,
      setProjectType,
      secretLocationId,
      setSecretLocationId,
      secretReferenceId,
      setSecretReferenceId,
      isTrialWarehouse,
      setIsTrialWarehouse
    }),
    [isTrialWarehouse, projectType, secretLocationId, secretReferenceId]
  )

  return (
    <OnboardingContext.Provider value={values}>
      {children}
    </OnboardingContext.Provider>
  )
}
