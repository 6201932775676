import { useFormikContext } from 'formik'
import CommonRole from 'modules/Common/Fields/DefaultRole/Role'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export const Role = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled =
    !!values.secretReferenceId && !!values.account && !!values.username
  const listenerFields: Array<keyof CreateProjectFormikValueTypes> = [
    'secretReferenceId',
    'account',
    'username'
  ]
  return (
    <CommonRole
      isFieldEnabled={isFieldEnabled}
      listenerFields={listenerFields}
    />
  )
}
