import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'
import { EnvironmentName } from 'modules/FormsContent/CreateEnvironment/Fields'
import EnvironmentAgent from 'modules/FormsContent/CreateEnvironment/Fields/EnvironmentAgent'
import { useFormikContext } from 'formik'
import { AgentsSecretsHost } from 'types'
import { CommonFormI } from 'types/FormTypes'

interface CreateEnvironmentProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
}

const CreateEnvironment: FunctionComponent<CreateEnvironmentProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { projectConfiguration } = values

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="create-environment-title">
          {t('formContent.environment.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <EnvironmentName />
        {projectConfiguration === AgentsSecretsHost.CustomerHosted && (
          <EnvironmentAgent />
        )}
      </div>
    </>
  )
}

export default CreateEnvironment
