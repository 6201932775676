import { type DeploymentEnvironment } from 'api/context/types'

export interface GitFeatureFlags {
  [key: string]: boolean | string[]
  testFlagOn: boolean
  testFlagOff: boolean
  enableWorkingTreeMerge: boolean
}

export const FeatureFlagDefaults: Record<
  DeploymentEnvironment,
  GitFeatureFlags
> = {
  local: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  },
  test: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  },
  dev: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  },
  perf: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  },
  preprod: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  },
  prod: {
    testFlagOn: true,
    testFlagOff: false,
    enableWorkingTreeMerge: false
  }
}

export const launchDarklyClientId: Record<DeploymentEnvironment, string> = {
  local: '6516d673cfc87c12829607c5',
  test: '6516d673cfc87c12829607c5',
  perf: '6516d6c0cc1af31267a42c8f',
  dev: '6516d673cfc87c12829607c5',
  preprod: '6516d6b3de0eaf11af70c696',
  prod: '6513044ac4520b12efd4ab73'
}
