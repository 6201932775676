import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postRoles } from 'api/clients'
import { POSTRolesRequest } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: POSTRolesRequest
  }
>

export const usePostRoles = (projectId: string, options?: Options) => {
  const client = useClient()

  return useMutation(
    [QueryKey.NEW_PROJECT_ROLES, projectId],
    async (data: { values: POSTRolesRequest }) => {
      const { values } = data
      return postRoles({ projectId }, values, client)
    },
    options
  )
}
