import { Typography } from '@matillion/component-library'
import { FunctionComponent, PropsWithChildren } from 'react'

import classes from './Modal.module.scss'

interface ModalProps {
  'data-testid'?: string
}

export const ModalHeading: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  'data-testid': dataTestId,
  children
}) => {
  return (
    <div className={classes.Modal__Heading}>
      <Typography
        as="h2"
        format="tl"
        className={classes.Modal__Title}
        id="modal-title"
        data-testid={dataTestId}
      >
        {children}
      </Typography>
    </div>
  )
}
