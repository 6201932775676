import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

const NoCreatePermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('secrets.oauthListing.noCreatePermission.title')}
      supportText={t('secrets.oauthListing.noCreatePermission.content')}
      data-testid="no-create-permission-oauth"
    />
  )
}

export default NoCreatePermission
