// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u8Ega2pYeD_0m95UQtAx{width:100%}.u8Ega2pYeD_0m95UQtAx button,.u8Ega2pYeD_0m95UQtAx button:hover,.u8Ega2pYeD_0m95UQtAx button:focus{padding-left:0;border:0 solid rgba(0,0,0,0) !important;box-shadow:none}.u8Ega2pYeD_0m95UQtAx ul{z-index:1}`, "",{"version":3,"sources":["webpack://./src/components/ConflictVersionSelector/ConflictVersionSelector.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CAEA,mGAGE,cAAA,CACA,uCAAA,CACA,eAAA,CAGF,yBACE,SAAA","sourcesContent":[".Dropdown {\n  width: 100%;\n\n  button,\n  button:hover,\n  button:focus {\n    padding-left: 0;\n    border: 0 solid transparent !important;\n    box-shadow: none;\n  }\n\n  ul {\n    z-index: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dropdown": `u8Ega2pYeD_0m95UQtAx`
};
export default ___CSS_LOADER_EXPORT___;
