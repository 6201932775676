import { AnySchema, boolean, number, object, string } from 'yup'
import { OptionalObjectSchema } from 'yup/lib/object'
import { AnyObject } from 'yup/lib/types'
import { bringYourOwnGitValidationSchema } from '@matillion/git-component-library'

import {
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_HOST_TYPE,
  PROJECTS_INFRASTRUCTURE_TYPE,
  PROJECTS_NEW_PROJECT,
  PROJECTS_REPOSITORY_TYPE
} from 'constants/route'
import { useFlags } from 'hooks/flags'
import { AgentsSecretsHost, GitProvider, HostType, Warehouse } from 'types'

import {
  CreateFormNavigationSteps,
  CreateProjectFormikValueTypes
} from './types'
import {
  steps,
  CreateFormNavigationStepsType,
  stepsWithConditionalBYOG,
  stepsWithConditionalHostTypeFullSaas,
  stepsWithConditionalHostTypeNotSelectedOrHybrid
} from './CreateProjectForm.steps'

export const useSteps = ({
  hostType
}: {
  hostType?: HostType | ''
}): Partial<CreateFormNavigationStepsType> => {
  const { enableByog, enableHostTypeScreen } = useFlags()

  // TO-DO: rework all steps depending on what FF is removed
  if (enableHostTypeScreen) {
    if (enableByog && hostType === HostType.HybridHosted) {
      return stepsWithConditionalBYOG
    } else if (hostType === HostType.FullHosted) {
      return stepsWithConditionalHostTypeFullSaas
    } else {
      // hybrid hosted OR NO HOST selected
      return stepsWithConditionalHostTypeNotSelectedOrHybrid
    }
  }
  return steps
}

export const previousStepOrDefault = (
  step: CreateFormNavigationSteps,
  allSteps: Partial<CreateFormNavigationStepsType>
) => allSteps[step]?.previousStep ?? PROJECTS_NEW_PROJECT

export const alphaNumericMildCharsRegEx = /^([\w()-]\s?)+$/
export const alphaNumericMildCharsWithLeadEndSpacesRegEx =
  /^\s*([\w()-]\s?)+\s*$/

export const getInitialValues = (
  values?: CreateProjectFormikValueTypes
): CreateProjectFormikValueTypes => ({
  projectName: '',
  description: '',
  type: '',
  projectConfiguration: AgentsSecretsHost.MatillionHosted,
  hostType: HostType.FullHosted,
  provider: GitProvider.Matillion,
  providerParameters: {},
  repository: '',
  environmentName: '',
  etlAgent: {
    id: '',
    name: ''
  },
  matillionHostedAgentId: '',
  account: '',
  ssl: null,
  port: null,
  username: '',
  secretName: {
    id: '',
    name: ''
  },
  secretKey: {
    id: '',
    name: ''
  },
  secretValue: '',
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  secretLocationId: '',
  secretReferenceId: '',
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  catalog: {
    id: '',
    name: ''
  },
  ...values
})

export const getGenericValuesSchemaMap = (availableProjects: string[] = []) => {
  return {
    [PROJECTS_NEW_PROJECT]: object({
      projectName: string()
        .required('fields.projectName.error.required')
        .test('projectName', 'fields.projectName.error.notUnique', (value) => {
          if (availableProjects.length === 0) {
            return true
          }
          return availableProjects.findIndex((p) => p === value) === -1
        })
        .matches(
          alphaNumericMildCharsWithLeadEndSpacesRegEx,
          'fields.projectName.error.regEx'
        ),
      description: string(),
      type: string()
        .required('fields.type.error.required')
        .matches(alphaNumericMildCharsRegEx, 'fields.type.error.regEx')
    }),
    [PROJECTS_INFRASTRUCTURE_TYPE]: object({
      projectConfiguration: string().required(
        'fields.projectConfiguration.error.required'
      )
    }),
    [PROJECTS_CREATE_ENVIRONMENT]: object({
      environmentName: string()
        .required('fields.environmentName.error.required')
        .matches(
          alphaNumericMildCharsWithLeadEndSpacesRegEx,
          'fields.environmentName.error.regEx'
        ),
      etlAgent: object({
        id: string().required('fields.etlAgent.error.required'),
        name: string().required('fields.etlAgent.error.required')
      })
        .when('projectConfiguration', {
          is: AgentsSecretsHost.MatillionHosted,
          then: (_existingSchema) => {
            return object({
              id: string().optional(),
              name: string().optional()
            })
          }
        })
        .required('fields.etlAgent.error.unmatched')
        .nullable()
    }),
    [PROJECTS_CREATE_AGENT]: object({}),
    [PROJECTS_HOST_TYPE]: object({
      hostType: string().required()
    }),
    // Due to the 'bringYourOwnGitValidationSchema' returning a different schema, we have to cast this to a format that can
    // be used in our application whilst we are using 'yup' on v0.*.*. The GCL are on v1.*.* and the object schema for
    // bringYourOwnGitValidationSchema is rendered in a state where it's not currently use-able without us updating to v1.*.*
    [PROJECTS_REPOSITORY_TYPE]:
      bringYourOwnGitValidationSchema as unknown as OptionalObjectSchema<{
        provider: AnySchema
        repository: AnySchema
      }>
  }
}

type DynamicValuesSchema = Record<
  Warehouse,
  Array<OptionalObjectSchema<AnyObject>>
>
// TODO: remove this file from sonar exclusion once Project creation process is implemented for Redshift
// TODO: remove in favour of warehouseValuesSchema
export const dynamicValuesSchema = (
  isSecretValueOptional = false
): DynamicValuesSchema => {
  return {
    [Warehouse.Snowflake]: [
      object({
        account: string().required('fields.account.error.required'),
        username: string().required('fields.username.error.required'),
        secretName: object({
          id: string().required('fields.secretName.error.required'),
          name: string().required('fields.secretName.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretName.error.unmatched')
          .nullable(),
        secretKey: object({
          id: string().required('fields.secretKey.error.required'),
          name: string().required('fields.secretKey.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretKey.error.unmatched')
          .nullable(),
        secretValue: string()
          .optional()
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (existingSchema) => {
              if (isSecretValueOptional) {
                return existingSchema
              } else {
                return string().required('fields.secretValue.error.required')
              }
            }
          })
      }),
      object({
        defaultRole: object({
          id: string().required('fields.defaultRole.error.required'),
          name: string().required('fields.defaultRole.error.required')
        })
          .required('fields.defaultRole.error.unmatched')
          .nullable(),
        defaultWarehouse: object({
          id: string().required('fields.defaultWarehouse.error.required'),
          name: string().required('fields.defaultWarehouse.error.required')
        })
          .required('fields.defaultWarehouse.error.unmatched')
          .nullable(),
        defaultDatabase: object({
          id: string().required('fields.defaultDatabase.error.required'),
          name: string().required('fields.defaultDatabase.error.required')
        })
          .required('fields.defaultDatabase.error.unmatched')
          .nullable(),
        defaultSchema: object({
          id: string().required('fields.defaultSchema.error.required'),
          name: string().required('fields.defaultSchema.error.required')
        })
          .required('fields.defaultSchema.error.unmatched')
          .nullable()
      })
    ],
    [Warehouse.Redshift]: [
      object({
        account: string().required('fields.endpoint.error.required'),
        username: string().required('fields.username.error.required'),
        useSSL: boolean().optional(),
        port: number().required('fields.port.error.required'),
        secretName: object({
          id: string().required('fields.secretName.error.required'),
          name: string().required('fields.secretName.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretName.error.unmatched')
          .nullable(),
        secretKey: object({
          id: string().required('fields.secretKey.error.required'),
          name: string().required('fields.secretKey.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretKey.error.unmatched')
          .nullable(),
        secretValue: string()
          .optional()
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (existingSchema) => {
              if (isSecretValueOptional) {
                return existingSchema
              } else {
                return string().required('fields.secretValue.error.required')
              }
            }
          })
      }),
      object({
        defaultSchema: object({
          id: string().required('fields.defaultSchema.error.required'),
          name: string().required('fields.defaultSchema.error.required')
        })
          .required('fields.defaultSchema.error.unmatched')
          .nullable(),
        defaultDatabase: object({
          id: string().required('fields.defaultDatabase.error.required'),
          name: string().required('fields.defaultDatabase.error.required')
        })
          .required('fields.defaultDatabase.error.unmatched')
          .nullable()
      })
    ],
    [Warehouse.Databricks]: [
      object({
        account: string().required('fields.workspaceId.error.required'),
        username: string().required('fields.username.error.required'),
        secretName: object({
          id: string().required('fields.secretName.error.required'),
          name: string().required('fields.secretName.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretName.error.unmatched')
          .nullable(),
        secretKey: object({
          id: string().required('fields.secretKey.error.required'),
          name: string().required('fields.secretKey.error.required')
        })
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (_existingSchema) => {
              return object({
                id: string().nullable(),
                name: string().nullable()
              })
            }
          })
          .required('fields.secretKey.error.unmatched')
          .nullable(),
        secretValue: string()
          .optional()
          .when('projectConfiguration', {
            is: AgentsSecretsHost.MatillionHosted,
            then: (existingSchema) => {
              if (isSecretValueOptional) {
                return existingSchema
              } else {
                return string().required('fields.secretValue.error.required')
              }
            }
          })
      }),
      object({
        compute: object({
          clusterId: string().required('fields.compute.error.required'),
          clusterName: string().required('fields.compute.error.required'),
          clusterType: string().required('fields.compute.error.required')
        })
          .required('fields.compute.error.required')
          .nullable(),
        catalog: object({
          id: string().required('fields.catalog.error.required'),
          name: string().required('fields.catalog.error.required')
        }).required('fields.catalog.error.unmatched')
      })
    ]
  }
}

// TODO: remove this file from sonar exclusion once Project creation process is implemented for Redshift
export const warehouseValuesSchema = (
  warehouse: Warehouse | '',
  isSecretValueOptional = false
): {
  [PROJECTS_CREDENTIALS]: OptionalObjectSchema<AnyObject>
  [PROJECTS_DEFAULTS]: OptionalObjectSchema<AnyObject>
} => {
  switch (warehouse) {
    case Warehouse.Snowflake:
      return {
        [PROJECTS_CREDENTIALS]: object({
          account: string().required('fields.account.error.required'),
          username: string().required('fields.username.error.required'),
          secretName: object({
            id: string().required('fields.secretName.error.required'),
            name: string().required('fields.secretName.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretName.error.unmatched')
            .nullable(),
          secretKey: object({
            id: string().required('fields.secretKey.error.required'),
            name: string().required('fields.secretKey.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretKey.error.unmatched')
            .nullable(),
          secretValue: string()
            .optional()
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (existingSchema) => {
                if (isSecretValueOptional) {
                  return existingSchema
                } else {
                  return string().required('fields.secretValue.error.required')
                }
              }
            })
        }),
        [PROJECTS_DEFAULTS]: object({
          defaultRole: object({
            id: string().required('fields.defaultRole.error.required'),
            name: string().required('fields.defaultRole.error.required')
          })
            .required('fields.defaultRole.error.unmatched')
            .nullable(),
          defaultWarehouse: object({
            id: string().required('fields.defaultWarehouse.error.required'),
            name: string().required('fields.defaultWarehouse.error.required')
          })
            .required('fields.defaultWarehouse.error.unmatched')
            .nullable(),
          defaultDatabase: object({
            id: string().required('fields.defaultDatabase.error.required'),
            name: string().required('fields.defaultDatabase.error.required')
          })
            .required('fields.defaultDatabase.error.unmatched')
            .nullable(),
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable()
        })
      }
    case Warehouse.Redshift:
      return {
        [PROJECTS_CREDENTIALS]: object({
          account: string().required('fields.endpoint.error.required'),
          username: string().required('fields.username.error.required'),
          useSSL: boolean().optional(),
          port: number().required('fields.port.error.required'),
          secretName: object({
            id: string().required('fields.secretName.error.required'),
            name: string().required('fields.secretName.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretName.error.unmatched')
            .nullable(),
          secretKey: object({
            id: string().required('fields.secretKey.error.required'),
            name: string().required('fields.secretKey.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretKey.error.unmatched')
            .nullable(),
          secretValue: string()
            .optional()
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (existingSchema) => {
                if (isSecretValueOptional) {
                  return existingSchema
                } else {
                  return string().required('fields.secretValue.error.required')
                }
              }
            })
        }),
        [PROJECTS_DEFAULTS]: object({
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable(),
          defaultDatabase: object({
            id: string().required('fields.defaultDatabase.error.required'),
            name: string().required('fields.defaultDatabase.error.required')
          })
            .required('fields.defaultDatabase.error.unmatched')
            .nullable()
        })
      }
    case Warehouse.Databricks:
      return {
        [PROJECTS_CREDENTIALS]: object({
          account: string().required('fields.workspaceId.error.required'),
          username: string().required('fields.username.error.required'),
          secretName: object({
            id: string().required('fields.secretName.error.required'),
            name: string().required('fields.secretName.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretName.error.unmatched')
            .nullable(),
          secretKey: object({
            id: string().required('fields.secretKey.error.required'),
            name: string().required('fields.secretKey.error.required')
          })
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (_existingSchema) => {
                return object({
                  id: string().nullable(),
                  name: string().nullable()
                })
              }
            })
            .required('fields.secretKey.error.unmatched')
            .nullable(),
          secretValue: string()
            .optional()
            .when('projectConfiguration', {
              is: AgentsSecretsHost.MatillionHosted,
              then: (existingSchema) => {
                if (isSecretValueOptional) {
                  return existingSchema
                } else {
                  return string().required('fields.secretValue.error.required')
                }
              }
            })
        }),
        [PROJECTS_DEFAULTS]: object({
          compute: object({
            id: string().required('fields.compute.error.required'),
            name: string().required('fields.compute.error.required'),
            clusterId: string().required('fields.compute.error.required'),
            clusterName: string().required('fields.compute.error.required'),
            clusterType: string().required('fields.compute.error.required')
          })
            .required('fields.compute.error.unmatched')
            .nullable(),
          catalog: object({
            id: string().required('fields.catalog.error.required'),
            name: string().required('fields.catalog.error.required')
          })
            .required('fields.catalog.error.unmatched')
            .nullable(),
          defaultSchema: object({
            id: string().required('fields.defaultSchema.error.required'),
            name: string().required('fields.defaultSchema.error.required')
          })
            .required('fields.defaultSchema.error.unmatched')
            .nullable()
        })
      }
    default:
      return {
        [PROJECTS_DEFAULTS]: object({}),
        [PROJECTS_CREDENTIALS]: object({})
      }
  }
}
