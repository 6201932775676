import { FunctionComponent } from 'react'
import { Typography } from '@matillion/component-library'

interface DescriptionCellProps {
  description: string
}

const DescriptionCell: FunctionComponent<DescriptionCellProps> = ({
  description
}) => <Typography>{description}</Typography>

export default DescriptionCell
