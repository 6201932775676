import { object, string } from 'yup'

import { GETEnvironmentResponse, GETSecretReferencesResponse } from 'api/types'
import { AutoCompleteProps } from 'types/FormTypes'

export const formSchema = object({
  azureCredentials: object({
    id: string().required('associateCredentials.fields.azure.error.required'),
    name: string().required('associateCredentials.fields.azure.error.required')
  })
    .required('associateCredentials.fields.azure.error.unmatched')
    .nullable(),
  awsCredentials: object({
    id: string().required('associateCredentials.fields.aws.error.required'),
    name: string().required('associateCredentials.fields.aws.error.required')
  })
    .required('associateCredentials.fields.aws.error.unmatched')
    .nullable()
})

export interface FormValues {
  azureCredentials?: AutoCompleteProps
  awsCredentials?: AutoCompleteProps
  environmentName: string
}

export const initialAssociateCredentialsValues = (
  selectedEnvironment: GETEnvironmentResponse,
  secretReferences: GETSecretReferencesResponse
): FormValues => {
  const azureSecretCredentials = secretReferences.find(
    (x) => x.id === selectedEnvironment.platformProviderSecrets?.azure
  )
  const awsSecretCredentials = secretReferences.find(
    (x) => x.id === selectedEnvironment.platformProviderSecrets?.aws
  )

  return {
    azureCredentials: azureSecretCredentials ?? { id: '', name: '' },
    awsCredentials: awsSecretCredentials ?? { id: '', name: '' },
    environmentName: selectedEnvironment.name
  }
}
