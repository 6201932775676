import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

export const ScheduleName: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<EditScheduleFormValues>()
  const { t } = useTranslation()

  return (
    <div data-testid="edit-schedule-schedule-name">
      <Field
        title={t('editSchedule.fields.scheduleName.title')}
        name="name"
        data-testid="edit-schedule-schedule-name-input"
        value={values.name}
        placeholder={t('editSchedule.fields.scheduleName.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        supportText={t('editSchedule.fields.scheduleName.supportText')}
        errorText={errors?.name && touched.name ? t(errors.name) : null}
        hasError={!!errors?.name && touched.name}
        required
      />
    </div>
  )
}
