import { FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonProps,
  Modal,
  Typography
} from '@matillion/component-library'
import { ModalActions, ModalContent, ModalHeading } from 'components/Modal'

import classes from './ProjectExplorerModal.module.scss'

interface ProjectExplorerModalProps {
  heading?: string
  content?: string | ReactElement
  additionalContent?: string | ReactElement
  cancel?: string
  validate?: string
  waiting?: boolean
  hideCancel?: boolean
  onValidate?: () => void
  onCancel: () => void
  icon?: JSX.Element
  disableBackdropClick?: boolean
  isValidateDisabled?: boolean
  alt?: ButtonProps['alt']
}

const ProjectExplorerModal: FunctionComponent<ProjectExplorerModalProps> = ({
  heading,
  content,
  additionalContent,
  cancel,
  validate,
  waiting,
  hideCancel = false,
  onValidate,
  onCancel,
  icon,
  disableBackdropClick = false,
  isValidateDisabled = false,
  alt = 'danger'
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      size="mid"
      data-testid="modal"
      onCancel={() => (waiting ? null : onCancel())}
      ariaLabelledBy="modal-title"
      disableBackdropClick={disableBackdropClick}
    >
      {icon && (
        <div
          className={classes.ProjectExplorerModal__Container}
          data-testid="modal-icon"
        >
          {icon}
        </div>
      )}
      <ModalHeading data-testid="modal-title">
        {heading ?? t('modal.generic.heading')}
      </ModalHeading>
      <ModalContent>
        <Typography
          data-testid="modal-body-text"
          className={classes.ProjectExplorerModal__Content}
          format="bcm"
        >
          {content ?? t('modal.generic.content')}
        </Typography>
        {additionalContent}
      </ModalContent>
      <ModalActions>
        {!hideCancel && (
          <Button
            disabled={waiting}
            onClick={onCancel}
            alt="secondary"
            fontWeight="bold"
            text={cancel ?? t('modal.generic.cancel')}
            data-testid="modal-button-cancel"
          />
        )}
        {onValidate && (
          <Button
            waiting={waiting}
            onClick={onValidate}
            alt={alt}
            fontWeight="bold"
            text={validate ?? t('modal.generic.validate')}
            data-testid="modal-button-validate"
            disabled={isValidateDisabled}
          />
        )}
      </ModalActions>
    </Modal>
  )
}

export default ProjectExplorerModal
