import { SortState } from '@matillion/component-library'
import { GETProjectResponse } from 'api/types'

export const projectsSort =
  (sortState: Partial<SortState<GETProjectResponse>>) =>
  (a: GETProjectResponse, b: GETProjectResponse): number => {
    const sortStateType = Object.keys(sortState)[0] as keyof Omit<
      GETProjectResponse,
      'secretLocationIds'
    >
    if (Object.values(sortState)[0] === 'ASC') {
      return a[sortStateType].localeCompare(b[sortStateType])
    } else {
      return b[sortStateType].localeCompare(a[sortStateType])
    }
  }
