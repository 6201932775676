import { useFormikContext } from 'formik'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { object, string } from 'yup'

import { GenericSchemaType } from 'types'

export const useDynamicValidation = (
  validationField: string,
  title: string,
  setValidationSchema: (schema: GenericSchemaType) => void,
  validationSchema?: GenericSchemaType,
  required = true
) => {
  const { setFieldValue, setFieldError, setFieldTouched } = useFormikContext()
  const { t } = useTranslation()

  const addNewValidationToExistingSchema = useCallback(():
    | GenericSchemaType
    | undefined => {
    let concatVS = validationSchema
    if (
      validationSchema &&
      !Object.keys(validationSchema.fields).includes(validationField)
    ) {
      const newSchema = object({
        [validationField]: required
          ? string().required(t('error.required', { field: title }))
          : string().optional()
      })

      concatVS = validationSchema?.concat(newSchema)
      setValidationSchema(concatVS)
    }
    return concatVS
  }, [
    required,
    setValidationSchema,
    t,
    title,
    validationField,
    validationSchema
  ])

  // Preventing the schema from being overridden when multiple updates happen from different fields that use out of sync context
  useEffect(() => {
    addNewValidationToExistingSchema()
  }, [addNewValidationToExistingSchema])

  useEffect(() => {
    setFieldValue(validationField, '')
    setFieldError(validationField, undefined)
    setFieldTouched(validationField, false)
    const vs = addNewValidationToExistingSchema()

    return () => {
      if (vs && Object.keys(vs.fields).includes(validationField)) {
        setValidationSchema(vs.omit([validationField]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
