import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Field } from '@matillion/component-library'
import classes from 'modules/Projects/EditBranch/EditBranch.module.scss'

const Name: FunctionComponent = () => {
  const { t } = useTranslation()
  const { getFieldProps } = useFormikContext()
  const fieldName = 'branchName'
  const field = getFieldProps(fieldName)

  return (
    <div data-testid="edit-branch-name">
      <Field
        {...field}
        className={classes['EditBranch__Field--normal']}
        title={t('editBranch.fields.branchName.title')}
        name={fieldName}
        data-testid="edit-branch-name-input"
        disabled
      />
    </div>
  )
}

export default Name
