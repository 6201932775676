import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { getMha } from 'api/clients'
import { GETMhaResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type TData = GETMhaResponse
type TError = AxiosError<unknown>

export const useGetMha = (options?: UseQueryOptions<TData, TError>) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.MATILLION_HOSTED_AGENTS],
    async () => {
      return getMha(client)
    },
    options
  )
}
