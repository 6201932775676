import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postWarehouseProvisioning } from 'api/clients'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'
import { POSTWarehouseProvisioningRequest } from 'api/types/WarehouseProvisioningRequest'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: POSTWarehouseProvisioningRequest
  }
>

export const usePostWarehouseProvisioning = (options?: Options) => {
  const client = useClient()

  return useMutation(
    [QueryKey.CREATE_WAREHOUSE_PROVISIONING],
    async (data: { values: POSTWarehouseProvisioningRequest }) => {
      const { values } = data
      return postWarehouseProvisioning(values, client)
    },
    options
  )
}
