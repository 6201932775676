import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Typography } from '@matillion/component-library'

import { Header, HeaderTitle } from 'components/Header'
import HostTypeCard from 'modules/FormsContent/HostTypeSelection/HostTypeCard'
import { HostType } from 'types/HostType'

import classes from 'modules/FormsContent/HostTypeSelection/HostTypeSelection.module.scss'

interface HostTypeSelectionProps {
  onCancel?: () => void
  modalContent?: string
  onPrevious: () => void
}

const HostTypeSelection: FunctionComponent<HostTypeSelectionProps> = ({
  modalContent,
  onCancel,
  onPrevious
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={true}
        showPrevious={true}
        modalContent={modalContent}
        onPrevious={onPrevious}
      >
        <HeaderTitle data-testid="host-type-title">
          {t('hostTypeSelection.title')}
        </HeaderTitle>
        <Typography format="bcm" data-testid="host-type-support-text">
          {t('hostTypeSelection.supportText')}
        </Typography>
        <Typography format="bcm" data-testid="host-type-support-text2">
          {t('hostTypeSelection.supportText2')}{' '}
          <a
            href="https://docs.matillion.com/data-productivity-cloud/designer/docs/projects/#add-a-new-project"
            target="_blank"
            rel="noreferrer"
            data-testid="host-type-support-link"
            className={classes.HostTypeSelection__Link}
          >
            {t('hostTypeSelection.linkText')} <Icon.Export />
          </a>
        </Typography>
      </Header>
      <div className={classes.HostTypeSelection__Container}>
        <HostTypeCard
          title={t('hostTypeSelection.matillionHosted.title')}
          supportText={t('hostTypeSelection.matillionHosted.supportText')}
          typeOfSetUp={t('hostTypeSelection.matillionHosted.typeOfSetUp')}
          bullets={t('hostTypeSelection.matillionHosted.bulletPoints', {
            returnObjects: true
          })}
          desiredHostType={HostType.FullHosted}
        />
        <HostTypeCard
          title={t('hostTypeSelection.hybridHosted.title')}
          supportText={t('hostTypeSelection.hybridHosted.supportText')}
          typeOfSetUp={t('hostTypeSelection.hybridHosted.typeOfSetUp')}
          bullets={t('hostTypeSelection.hybridHosted.bulletPoints', {
            returnObjects: true
          })}
          desiredHostType={HostType.HybridHosted}
        />
      </div>
    </>
  )
}

export default HostTypeSelection
