import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Dropdown } from '@matillion/component-library'

import classes from './ConflictVersionSelector.module.scss'
import {
  type ConflictVersion,
  type ConflictVersionSelectorProps
} from './types'

const ConflictVersionSelector = ({
  version,
  onSelect
}: ConflictVersionSelectorProps) => {
  const { t } = useTranslation()

  const handleSelect = useCallback(
    (selected: any) => {
      onSelect((selected?.id as ConflictVersion) ?? 'NOT_SELECTED')
    },
    [onSelect]
  )

  return (
    <Dropdown
      value={version}
      colorTheme="white"
      className={classes.Dropdown}
      handleSelectedItem={handleSelect}
      data-testid="conflict-version-selector"
      placeholder={t('merge.version.placeholder')}
      items={[
        { id: 'OURS', name: t('merge.version.ours') },
        { id: 'THEIRS', name: t('merge.version.theirs') }
      ]}
    />
  )
}

export default ConflictVersionSelector
