import { createContext, useContext } from 'react'
import { noop } from 'lodash'

import { Warehouse } from 'types'

interface CreateEnvironmentContextProps {
  projectType?: Warehouse
  setProjectType: (projectType: Warehouse) => void
  secretLocationId: string
  setSecretLocationId: (id: string) => void
  secretReferenceId: string
  setSecretReferenceId: (id: string) => void
  tempSecretReferenceId: string
  setTempSecretReferenceId: (id: string) => void
}

export const CreateEnvironmentContext =
  createContext<CreateEnvironmentContextProps>({
    projectType: undefined,
    setProjectType: noop,
    secretLocationId: '',
    setSecretLocationId: noop,
    secretReferenceId: '',
    setSecretReferenceId: noop,
    tempSecretReferenceId: '',
    setTempSecretReferenceId: noop
  })

export const useCreateEnvironmentContext = (
  context = CreateEnvironmentContext
) => useContext(context)
