import {
  ScheduleDisplayMode,
  POSTScheduleValues,
  PUTScheduleRequest
} from 'api/types'
import add from 'date-fns/add'
import { FormValues } from 'modules/Projects/CreateSchedule/types'
import { EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

// As this is not a time picker it defaults the time to 00:00:00
// However, we need to ensure that the schedule is in the future
// Setting this to the next 10 minutes if the date pick is Todays
const getStartTime = (value: Date) => {
  const today = new Date()
  if (value.getDate() === today.getDate()) {
    const now = add(new Date(), { minutes: 10 })
    return now.toISOString().split('.')[0]
  }
  return value.toISOString().split('.')[0]
}

export const createScheduleMutationData = (
  formValues: FormValues
): POSTScheduleValues => ({
  environmentName: formValues.environment.name.trim(),
  jobName: formValues.pipeline.name,
  agentId: formValues.agent.id,
  schedule: {
    cronExpression: formValues.cronExpression,
    timezone: formValues.timezone.id,
    startTime: getStartTime(formValues.startTime),
    scheduleEnabled: true,
    name: formValues.name.trim(),
    displayMode: ScheduleDisplayMode.ADVANCED
  }
})

export const editScheduleMutationData = (
  formValues: EditScheduleFormValues,
  projectId: string
): PUTScheduleRequest => {
  const data = createScheduleMutationData(formValues)
  const request = { ...data, projectId }
  return request
}
