import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import { getPermissions } from 'api/clients'
import { GETPermissionsResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { PermissionsType } from 'types'
import { useClient } from './useClient'

type TData = GETPermissionsResponse
type TError = AxiosError<unknown>

export const useGetPermissions = (
  projectId: string,
  permission: PermissionsType,
  options?: UseQueryOptions<TData, TError>
): UseQueryResult<GETPermissionsResponse, TError> => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.USER_PERMISSIONS, permission, projectId],
    async () => {
      const response = await getPermissions(
        {
          projectId,
          permission
        },
        client
      )
      return {
        ...response,
        result: response.result
      }
    },
    {
      // Setting a default staleTime to 0 to prevent caching the data, but allowing
      // it to be overridden if passed within 'options'
      staleTime: 0,
      ...options
    }
  )
}
