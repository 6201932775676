import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { EnvironmentDetailsI } from 'types/FormTypes'
import { useFormikFieldSpacesToHyphens } from 'hooks'

type FormikValueTypes = Pick<EnvironmentDetailsI, 'environmentName'>

const EnvironmentsName = ({ disabled = false }: { disabled?: boolean }) => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormikValueTypes>()
  const fieldName = 'environmentName'
  const spacesToHyphens = useFormikFieldSpacesToHyphens({ fieldName })

  const { t } = useTranslation()
  return (
    <div data-testid="create-environment-environment-name">
      <Field
        maxLength={255}
        title={t('fields.environmentName.title')}
        name={fieldName}
        data-testid="create-environment-environment-name-input"
        value={values.environmentName}
        placeholder={t('fields.environmentName.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          handleChange(e)
          spacesToHyphens(e)
        }}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.environmentName.supportText')}
        errorText={
          errors?.environmentName && touched.environmentName
            ? t(errors.environmentName)
            : null
        }
        hasError={!!errors?.environmentName && touched.environmentName}
        required
        disabled={disabled}
      />
    </div>
  )
}

export default EnvironmentsName
