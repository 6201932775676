import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import {
  Button,
  DataGrid,
  LoadingSpinner,
  SortState,
  Toaster,
  Typography
} from '@matillion/component-library'

import {
  useGetBranches,
  useGetEnvironments,
  useGetPermissions,
  useNetworkErrorToast
} from 'api/hooks'
import { queryClient } from 'api/queryClient'
import { GETBranchesResponseParams } from 'api/types'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'
import { QueryKey } from 'constants/endpoint'
import { DeleteBranchModal } from 'modules/Projects/BranchesListing/DeleteBranchModal'
import { PermissionsType } from 'types'

import { ColumnKeys, SortedBranchDataTypes } from './'
import ActionCell from './ActionCell'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import { branchesSort } from './branchesListing.util'

import classes from './BranchesListing.module.scss'
import { useDesignerUrl } from 'hooks/useDesignerUrl'
import { AppRoutes } from 'constants/route'

const BranchesListing: FunctionComponent = () => {
  const [sortState, setSortState] = useState<
    Partial<SortState<GETBranchesResponseParams>>
  >({ name: 'ASC' })
  const { projectId } = useParams()
  const makeErrorToast = useNetworkErrorToast()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [lastSelectedBranchId, setLastSelectedBranchId] = useState('')
  const [lastSelectedBranchName, setLastSelectedBranchName] = useState('')
  const designerFrontendUrl = useDesignerUrl()

  const {
    data: branchesData = [],
    error: branchesError,
    isError: isBranchesError,
    isLoading: isBranchesLoading
  } = useGetBranches(projectId!)

  const {
    data: environmentsData = [],
    isError: isEnvironmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.CreateBranches)
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.ViewBranches)

  const { clearToasts } = Toaster.useToaster()

  const incompleteLoadedData = !branchesData.length || !environmentsData.length

  if (sortState) {
    const branchesSortFunction = branchesSort(sortState)
    branchesData.sort(branchesSortFunction)
  }

  const clickAddBranchHandler: VoidFunction = () => {
    clearToasts()
    navigate(AppRoutes.getBranchAdd(projectId!))
  }

  const clickEditBranchHandler = (item: SortedBranchDataTypes) => {
    clearToasts()
    navigate(AppRoutes.getBranchEdit(projectId!, item.id))
  }

  const clickDeleteBranchHandler = (item: SortedBranchDataTypes) => {
    clearToasts()
    setIsDeleteModalOpen(true)
    setLastSelectedBranchId(item.id)
    setLastSelectedBranchName(item.name)
  }

  const resetModals = () => {
    setIsDeleteModalOpen(false)
  }

  useEffect(() => {
    if (isBranchesError) {
      makeErrorToast({ message: branchesError.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchesError, isBranchesError])

  useEffect(() => {
    const branchesSortFunction = branchesSort({ name: 'ASC' })
    branchesData.sort(branchesSortFunction)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateBranches
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.EditBranches
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteBranches
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContent = () => {
    return incompleteLoadedData ? (
      <div
        className={classNames(classes.BranchesListing__EmptyInfo)}
        data-testid="empty-info-message"
      >
        {isBranchesError || isEnvironmentsError ? (
          <Typography format="bcm">{t('branchesListing.error')}</Typography>
        ) : (
          <>{!createPermission?.result ? <NoCreatePermission /> : <NoData />}</>
        )}
      </div>
    ) : (
      <DataGrid
        className={classNames(classes.BranchesListing__Grid)}
        rowClassName={classNames(classes.BranchesListing__GridRow)}
        data-testid="branches-data-grid"
        aria-label="Branches Data Grid"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.branchName,
            title: t('branchesListing.column.col1'),
            as: Typography,
            className: classNames(classes.BranchesListing__GridCell),
            mapValues: (row) => {
              const environment = environmentsData.find(
                (item) => item.id === row.defaultEnvironmentId
              )

              const urlPath = `${designerFrontendUrl}/project/${encodeURIComponent(
                projectId!
              )}/branch/${encodeURIComponent(row.name)}`

              const url = new URL(urlPath)
              if (environment) {
                url.searchParams.append('environmentId', environment.id)
                url.searchParams.append('agentId', environment.agentId)
              }

              return {
                children: (
                  <a
                    href={url.href}
                    data-testid="open-branch"
                    className={classNames(
                      classes['BranchesListing--content-link'],
                      classes['BranchesListing--content-large']
                    )}
                  >
                    {row.name}
                  </a>
                )
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.defaultEnvironment,
            title: t('branchesListing.column.col2'),
            as: Typography,
            className: classNames(classes.BranchesListing__GridCell),
            mapValues: (row) => ({
              children: row.defaultEnvironmentName
            }),
            sortable: true
          },
          {
            key: ColumnKeys.action,
            title: t('branchesListing.column.col3'),
            as: ActionCell,
            className: classNames(
              classes.BranchesListing__GridCell,
              classes['BranchesListing__GridCell--action']
            ),
            mapValues: (row: GETBranchesResponseParams) => ({
              onEditClick: clickEditBranchHandler,
              onDeleteClick:
                row.name === 'main' ? undefined : clickDeleteBranchHandler,
              item: row
            }),
            sortable: false
          }
        ]}
        rows={branchesData}
        onSort={setSortState}
      />
    )
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="branches-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            withBottomSpace={false}
          >
            {t('branchesListing.title')}
          </HeaderTitle>
          {branchesData.length > 0 &&
            createPermission?.result && ( // We cannot create new branches if there are no branches to branch off of
              <Button
                text={t('branchesListing.buttonText')}
                onClick={clickAddBranchHandler}
                data-testid="add-branch-button"
                alt="positive"
              />
            )}
        </HeaderWithCTA>
      </Header>
      {isBranchesLoading ||
      isEnvironmentsLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && incompleteLoadedData) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission?.result ? <NoReadPermission /> : getContent()}</>
      )}
      {isDeleteModalOpen && (
        <DeleteBranchModal
          resetModals={resetModals}
          branchId={lastSelectedBranchId}
          branchName={lastSelectedBranchName}
        />
      )}
    </>
  )
}

export default BranchesListing
