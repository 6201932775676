import { Icon } from '@matillion/component-library'

import { SourceName } from 'types'

export enum ColumnKeys {
  name = 'name',
  description = 'description',
  warehouse = 'warehouse',
  action = 'action'
}

export type ProjectTypes = Exclude<ColumnKeys, 'action'>

export type ProjectDataTypes = Record<ProjectTypes, string>

export type SortTypes = { [key in ColumnKeys]: ColumnKeys }

export interface ActionCellTypes {
  handleViewDetails: () => void
  clickDeleteProjectHandler: () => void
  isError?: boolean
}

export interface SortedProjectsTypes extends ProjectDataTypes {
  id: string
  logoImage: keyof typeof Icon
  projectURL: string
  logoName: SourceName
  schedulesURL: string
}
