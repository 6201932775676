import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Typography } from '@matillion/component-library'

import classes from 'modules/Projects/EnvironmentsListing/EnvironmentsListing.module.scss'

const NoReadPermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames(classes.EnvironmentsListing__EmptyInfo)}
      data-testid="empty-info-message"
    >
      {/* // TO-DO: Replace this with template of the 'No Resource' page when improving the UI  */}
      <Typography format="bcm">
        {t('environmentsListing.noReadPermission.content')}
      </Typography>
    </div>
  )
}

export default NoReadPermission
