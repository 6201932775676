import { Loader, Toaster } from '@matillion/component-library'
import {
  useGetEnvironmentAgents,
  useGetEnvironments,
  useGetProject
} from 'api/hooks'
import { GETEnvironmentsResponseParams } from 'api/types'
import Form from 'components/Form'
import ProjectExplorerModal from 'components/Modal/ProjectExplorerModal'
import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CREDENTIALS,
  PROJECT_DETAILS_ENVIRONMENTS
} from 'constants/route'
import { FormikHelpers, FormikProps } from 'formik'
import { useFormNavigation } from 'hooks'
import { isMatch, pick } from 'lodash'

import { EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'
import EditEnvironmentRoutes from 'modules/Projects/EditEnvironment/EditEnvironmentRoutes'
import { useEditEnvironmentFormSubmit } from 'modules/Projects/EditEnvironment/hooks/useEditEnvironmentFormSubmit'
import { useValidationSchema } from 'modules/Projects/EditEnvironment/hooks/useValidationSchema'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { steps } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.steps'
import { initialEditEnvironmentValues } from 'modules/Projects/EditEnvironment/EditEnvironmentForm.util'

const EditEnvironmentForm = () => {
  const { envId, projectId } = useParams()
  const { makeToast } = Toaster.useToaster()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [selectedEnvironment, setSelectedEnvironment] =
    useState<GETEnvironmentsResponseParams>()
  const { isLastStep, progress, stepIndex, currentStep } =
    useFormNavigation(steps)
  const onSubmit = useEditEnvironmentFormSubmit()
  const validationSchema = useValidationSchema()
  const formikRef = useRef<FormikProps<EditEnvironmentFormikValueTypes>>(null)
  const [showModal, setShowModal] = useState(false)
  const [modalSubmitting, setModalSubmitting] = useState(false)

  const {
    data: environmentsData = [],
    isError: environmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)

  const {
    data: listOfAgents = [],
    isError: isAgentsError,
    isLoading: isAgentsLoading
  } = useGetEnvironmentAgents()

  const {
    data: projectData,
    isLoading: isProjectLoading,
    isError: isProjectError
  } = useGetProject(projectId!)
  const secretLocationId = projectData?.secretLocationIds[0]

  useEffect(() => {
    if (environmentsData.length) {
      setSelectedEnvironment(
        environmentsData.filter((x) => x.id === envId!)?.[0]
      )
    }
  }, [envId, environmentsData])

  if (
    isEnvironmentsLoading ||
    isProjectLoading ||
    !selectedEnvironment ||
    !secretLocationId ||
    isAgentsLoading
  ) {
    return <Loader />
  }

  if (environmentsError || isAgentsError || isProjectError) {
    makeToast({
      title: t('editEnvironment.errors.loading'),
      message: '',
      type: 'error'
    })
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
    return null
  }

  const agentName =
    listOfAgents.find((x) => x.agentId === selectedEnvironment.agentId)?.name ??
    selectedEnvironment.agentName

  const initialValues = initialEditEnvironmentValues(
    selectedEnvironment,
    agentName,
    secretLocationId
  )

  const handleSubmit = async (
    values: EditEnvironmentFormikValueTypes,
    formikHelpers: FormikHelpers<EditEnvironmentFormikValueTypes>
  ) => {
    const selectedValues: Array<
      Partial<keyof EditEnvironmentFormikValueTypes>
    > = [
      'environmentName',
      'etlAgent',
      'matillionHostedAgentId',
      'account',
      'username',
      'secretName',
      'secretKey',
      'secretValue',
      'secretReferenceId',
      'port',
      'ssl'
    ]
    const comparableValues = pick(values, selectedValues)
    const comparableInitialValues = pick(initialValues, selectedValues)

    const skipSecretValueCreation =
      isMatch(comparableValues, comparableInitialValues) ||
      isMatch(comparableInitialValues, comparableValues)

    if (!skipSecretValueCreation && currentStep === PROJECTS_CREDENTIALS) {
      await formikHelpers.setSubmitting(false)
      setShowModal(true)
    } else {
      await onSubmit({
        values: values,
        setValues: formikHelpers.setValues,
        skipSecretValueCreation
      })
    }
  }

  const continueToNextPage = () => {
    void (async () => {
      if (formikRef.current) {
        setModalSubmitting(true)
        await onSubmit({
          values: formikRef.current?.values,
          setValues: formikRef.current.setValues
        }).finally(() => {
          setModalSubmitting(false)
          setShowModal(false)
        })
      }
    })()
  }

  return (
    <>
      <Form<EditEnvironmentFormikValueTypes>
        formikValues={{
          onSubmit: handleSubmit,
          initialValues,
          validateOnMount: true,
          enableReinitialize: true,
          validationSchema: validationSchema,
          initialTouched: false,
          innerRef: formikRef
        }}
        stepInfo={{
          stepIndex,
          isLastStep,
          progress
        }}
        translationPrefix="editEnvironment"
        persistingStorageId={ENVIRONMENT_STORAGE_ITEM}
        persistenceExclusions={['secretValue']}
      >
        <EditEnvironmentRoutes />
      </Form>
      {showModal && (
        <ProjectExplorerModal
          onCancel={() => setShowModal(false)}
          heading={t('editEnvironment.modal.credentialsModal.title')}
          content={t('editEnvironment.modal.credentialsModal.content')}
          onValidate={continueToNextPage}
          waiting={modalSubmitting}
          alt="primary"
        />
      )}
    </>
  )
}
export default EditEnvironmentForm
