import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import classes from 'components/Form/Form.module.scss'
import { Header, HeaderTitle } from 'components/Header'
import {
  EnvironmentAgent,
  EnvironmentName
} from 'modules/FormsContent/CreateEnvironment/Fields'
import { useFormikContext } from 'formik'
import { AgentsSecretsHost, WarehouseLabels } from 'types'
import { CommonFormI } from 'types/FormTypes'
import { useNavigate, useParams } from 'react-router-dom'
import { AppRoutes, PROJECT_DETAILS_ENVIRONMENTS } from 'constants/route'
import * as Sources from 'modules/FormsContent/Sources'
import { capitalize } from 'lodash'

const EditEnvironmentAgentAndCreds: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CommonFormI>()
  const { projectConfiguration, type } = values
  const { projectId } = useParams()
  const navigate = useNavigate()

  const isMatillionHosted =
    projectConfiguration === AgentsSecretsHost.MatillionHosted

  const onCancel = () => {
    navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_ENVIRONMENTS)
    )
  }

  const warehouseCredentials = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(type)) {
        result.push(
          <Component key={label} showCancel onCancel={onCancel} excludeHeader />
        )
      }
      return result
    },
    []
  )

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel
        modalContent={t('editEnvironment.modal.content')}
      >
        <HeaderTitle data-testid="edit-environment-title">
          {t('formContent.editEnvironmentCredentials.title', {
            projectType: WarehouseLabels[type as keyof typeof WarehouseLabels]
          })}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <EnvironmentName disabled />
        {!isMatillionHosted && <EnvironmentAgent />}
        {warehouseCredentials}
      </div>
    </>
  )
}

export default EditEnvironmentAgentAndCreds
