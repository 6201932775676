import { FunctionComponent, useEffect, useState } from 'react'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'
import {
  Button,
  DataGrid,
  LoadingSpinner,
  Toaster,
  Typography
} from '@matillion/component-library'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import classes from './AccessListing.module.scss'
import { useGetProjectMembers, useNetworkErrorToast } from 'api/hooks'
import { ColumnKeys } from './'
import { useUser } from '@matillion/hub-client'
import { ProjectRoleBadge } from './ProjectRoleBadge/ProjectRoleBadge'
import AddUser from './AddUser/AddUser'
import ActionCell from './ActionCell'
import { NoResourcesAvailable } from 'components/NoResourcesAvailable'
import RemoveUser from './RemoveUser'
import { GETProjectMembersResponseParams } from 'api/types'
import EditUser from './EditUser'
import { useParams } from 'react-router-dom'

const AccessListing: FunctionComponent = () => {
  const { projectId } = useParams()
  const {
    data: projectMembers = [],
    error,
    isError,
    isLoading,
    refetch
  } = useGetProjectMembers(projectId!)
  const { t } = useTranslation()
  const { user: currentUser } = useUser()
  const currentUserRole =
    (projectMembers.find((member) => member.id === currentUser.sub) ?? {})
      ?.relation?.relation ?? ''
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [lastSelectedUser, setLastSelectedUser] =
    useState<GETProjectMembersResponseParams>({
      id: '',
      icon: '',
      email: '',
      roles: [],
      formattedUsername: '',
      relation: {
        resource: {
          type: 'project',
          id: ''
        },
        relation: ''
      }
    })
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts } = Toaster.useToaster()
  const isMembersEmpty = !projectMembers.length

  const sortedMembers = [...projectMembers].sort((a, b) =>
    a.formattedUsername.localeCompare(b.formattedUsername)
  )

  const clickAddUserHandler = () => {
    clearToasts()
    setIsAddModalOpen(true)
  }

  const clickRemoveUserHandler = (item: GETProjectMembersResponseParams) => {
    clearToasts()
    setIsRemoveModalOpen(true)
    setLastSelectedUser(item)
  }

  const clickEditUserHandler = (item: GETProjectMembersResponseParams) => {
    clearToasts()
    setIsEditModalOpen(true)
    setLastSelectedUser(item)
  }

  const handleSubmit: VoidFunction = () => {
    resetModals()
    refetch().catch((e) =>
      makeErrorToast({ message: t('accessListing.updateError') })
    )
  }

  const resetModals = () => {
    setIsAddModalOpen(false)
    setIsRemoveModalOpen(false)
    setIsEditModalOpen(false)
  }

  useEffect(() => {
    if (isError) {
      makeErrorToast({ message: error.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isError])

  const getEmptyContent = () => {
    return (
      <NoResourcesAvailable
        title={t('accessListing.createFirstUser.title')}
        supportText={t('accessListing.createFirstUser.supportText')}
        buttonText={t('accessListing.createFirstUser.buttonText')}
        onButtonClick={clickAddUserHandler}
        data-testid="add-first-user"
      />
    )
  }

  const getInnerContent = () => {
    if (isError) {
      return (
        <div className={classNames(classes.AccessListing__ErrorInfo)}>
          <Typography format="bcm" data-testid="error-text">
            {t('accessListing.error')}
          </Typography>
        </div>
      )
    } else if (isMembersEmpty && !isError && !isLoading) {
      return getEmptyContent()
    } else {
      return (
        <DataGrid
          className={classes.AccessListing__Grid}
          rowClassName={classes.AccessListing__GridRow}
          data-testid="access-data-grid"
          aria-label="Access Data Grid"
          columns={[
            {
              key: ColumnKeys.userName,
              title: t('accessListing.column.col1'),
              as: Typography,
              className: classes.AccessListing__GridCell,
              mapValues: (row) => ({
                children: (
                  <Typography as="span" format="bcl">
                    {row.formattedUsername}
                  </Typography>
                )
              }),
              sortable: false
            },
            {
              key: ColumnKeys.projectRole,
              title: t('accessListing.column.col2'),
              as: Typography,
              className: classes.AccessListing__GridCell,
              mapValues: (row) => ({
                children: <ProjectRoleBadge role={row.relation.relation} />
              }),
              sortable: false
            },
            {
              key: ColumnKeys.actions,
              title: t('accessListing.column.col3'),
              as: ActionCell,
              className: classNames(
                classes.AccessListing__GridCell,
                classes['AccessListing__GridCell--action']
              ),
              mapValues: (row) => ({
                member: row,
                currentUserId: currentUser.sub,
                currentUserRole: currentUserRole,
                onRemoveClick: clickRemoveUserHandler,
                onEditClick: clickEditUserHandler
              }),
              sortable: false
            }
          ]}
          rows={sortedMembers}
        />
      )
    }
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="access-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            withBottomSpace={false}
          >
            {t('accessListing.title')}
          </HeaderTitle>
          {(currentUserRole === 'admin' || currentUserRole === 'creator') && (
            <Button
              text={t('accessListing.buttonText')}
              onClick={clickAddUserHandler}
              data-testid="add-user-button"
              alt="positive"
            />
          )}
        </HeaderWithCTA>
      </Header>
      {isLoading ? <LoadingSpinner /> : getInnerContent()}
      {isAddModalOpen && (
        <AddUser
          projectMembers={projectMembers}
          onSubmit={handleSubmit}
          onCancelModal={resetModals}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveUser
          onSubmit={handleSubmit}
          onCancelModal={resetModals}
          user={lastSelectedUser}
        />
      )}
      {isEditModalOpen && (
        <EditUser
          onSubmit={handleSubmit}
          onCancelModal={resetModals}
          user={lastSelectedUser}
        />
      )}
    </>
  )
}

export default AccessListing
