import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { Toaster } from '@matillion/component-library'

import { useGetBranches, usePostBranch } from 'api/hooks'

import { formSchema, FormValues, initialValues } from './CreateBranch.util'

import { createBranchMutationData } from 'api/mutation'
import Form from 'components/Form'
import { Header, HeaderTitle } from 'components/Header'
import { AppRoutes } from 'constants/route'
import classNames from 'classnames'
import formClasses from 'components/Form/Form.module.scss'
import {
  BranchFrom,
  Environment,
  Name
} from 'modules/Projects/CreateBranch/Fields'
import { useQueryClient } from 'react-query'
import { QueryKey } from 'constants/endpoint'
import { CREATE_BRANCH } from 'constants/persistance'

const CreateBranch: FunctionComponent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { projectId = '' } = useParams()
  const { mutateAsync } = usePostBranch(projectId)
  const { data: branchesData = [] } = useGetBranches(projectId)
  const { makeToast, clearToasts } = Toaster.useToaster()

  const handleSubmitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    clearToasts()

    mutateAsync({ values: createBranchMutationData(values) })
      .then((response) => {
        makeToast({
          title: t('addBranch.responseMessage.success', {
            branchName: response.name
          }),
          message: '',
          type: 'success'
        })
        queryClient.invalidateQueries(QueryKey.BRANCHES_LIST)
        redirectToBranchListing()
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {})
      .finally(() => {
        setSubmitting(false)
      })
  }

  const redirectToBranchListing = () => {
    navigate(AppRoutes.getBranchListing(projectId))
  }

  return (
    <Form
      formikValues={{
        initialValues: initialValues,
        validationSchema: formSchema,
        onSubmit: handleSubmitForm,
        validateOnMount: true,
        initialTouched: false
      }}
      translationPrefix="addBranch"
      persistingStorageId={CREATE_BRANCH}
    >
      <>
        <Header onCancel={redirectToBranchListing} showCancel>
          <HeaderTitle data-testid="create-branch-title">
            {t('addBranch.heading')}
          </HeaderTitle>
        </Header>
        <div
          className={classNames(
            formClasses.Form__InnerWrap,
            formClasses['Form__InnerWrap--space-top']
          )}
        >
          <Name availableBranches={branchesData} />
          <BranchFrom availableBranches={branchesData} />
          <Environment />
        </div>
      </>
    </Form>
  )
}

export default CreateBranch
