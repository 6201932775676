import { FormikHelpers } from 'formik'
import { useEffect } from 'react'

import { FormValues } from 'modules/Projects/CloudProviderCredentials'

export const useResetFieldOnProviderChange = (
  field: string,
  { setFieldTouched, setFieldValue }: Partial<FormikHelpers<FormValues>>
) => {
  useEffect(() => {
    return () => {
      if (setFieldTouched) {
        setFieldTouched(field, false)
      }
      if (setFieldValue) {
        setFieldValue(field, '')
      }
    }
  }, [field, setFieldTouched, setFieldValue])
}
