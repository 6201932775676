import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type WorkingTreeBranchResponse } from 'api/hooks/useGetWorkingTreeBranch/types'
import {
  MockedDifferentGetWorkingTreeBranchResponses,
  MockedGetWorkingTreeBranchResponses
} from 'api/hooks/useGetWorkingTreeBranch/useGetWorkingTreeBranch.responses'

type StubbedResponse = Record<string, WorkingTreeBranchResponse>

export const stubGetWorkingTreeBranch = (
  baseUrl = '',
  projectId = PROJECT_ID,
  responses: StubbedResponse = MockedGetWorkingTreeBranchResponses
) => {
  return Object.keys(responses).map((branchName) => {
    const url = `${baseUrl}/projects/${projectId}/branches/${branchName}/workingtree`
    return rest.get(url, async (_req, res, ctx) => {
      return res(ctx.json<WorkingTreeBranchResponse>(responses[branchName]))
    })
  })
}

export const useGetWorkingTreeBranchHandlers: RequestHandler[] = [
  ...stubGetWorkingTreeBranch(
    '',
    PROJECT_ID,
    MockedGetWorkingTreeBranchResponses
  ),
  rest.get(
    `/projects/${PROJECT_ID}/branches/500/workingtree`,
    async (_req, res, ctx) => {
      return res(ctx.status(500))
    }
  )
]

/**
 * Exposes a set of handlers whose responses contain different
 * commit IDs for the same branch names as above. Can be used for
 * testing the scenario where the local WTS branch is behind the
 * remote Git repository.
 */
export const useDifferentGetWorkingTreeBranchHandlers: RequestHandler[] = [
  ...stubGetWorkingTreeBranch(
    '',
    PROJECT_ID,
    MockedDifferentGetWorkingTreeBranchResponses
  )
]
