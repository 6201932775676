import { useCallback, useState } from 'react'
import { AxiosError } from 'axios'
import { FormikHelpers, FormikProps } from 'formik'
import { useFormNavigation } from 'hooks'
import { t } from 'i18next'
import { To, useNavigate } from 'react-router-dom'
import { Toaster } from '@matillion/component-library'

import { usePostSecretLocation } from 'api/createProjectForm/hooks'
import {
  createGetWarehouseComputeResourceMutationData,
  createPostSecretLocationMutationData
} from 'api/createProjectForm/mutation'
import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  TError,
  useGetMha,
  useGetProjects,
  useMutateDatabricksComputeResources,
  useMutateWarehouseDefaults,
  usePostMha,
  usePostProject,
  usePostSecretReference,
  usePostSecretValueV2
} from 'api/hooks'
import {
  createProjectMutationData,
  createSecretReferenceMutationData,
  createSecretValueV2MutationData,
  createWarehouseDefaultsMutation
} from 'api/mutation'
import { ErrorResponse, POSTProjectResponse } from 'api/types'
import Form from 'components/Form'
import { CREATE_PROJECT_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECTS_HOST_TYPE
} from 'constants/route'
import { StatusCodes } from 'constants/statusCodes'
import { useCreateProjectsContext } from 'context'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm/types'
import {
  getInitialValues,
  useSteps
} from 'modules/Projects/CreateProject/CreateProjectForm/CreateProjectForm.util'
import CreateProjectRoutes from 'modules/Projects/CreateProject/CreateProjectRoutes'
import { useValidationSchema } from 'modules/Projects/CreateProject/hooks/useValidationSchema'
import { isMHARunning } from 'modules/utils'
import {
  AgentsSecretsHost,
  HostType,
  SecretReferenceTypes,
  Warehouse
} from 'types'

import classes from 'components/Form/Form.module.scss'

const CreateProjectForm = () => {
  const browserSessionData = window.sessionStorage.getItem(
    CREATE_PROJECT_STORAGE_ITEM
  )
  const initialValues = getInitialValues(
    browserSessionData && JSON.parse(browserSessionData)
  )

  const navigate = useNavigate()
  const { refetch } = useGetProjects()
  const { refetch: mhaRefetch } = useGetMha({ enabled: false })
  const { mutateAsync: mutateProject } = usePostProject()
  const { mutateAsync: mutateAsyncSecretLocation } = usePostSecretLocation()
  const { mutateAsync: mutateAsyncSecretReference } = usePostSecretReference()
  const { mutateAsync: mutateAsyncSecretValueV2 } = usePostSecretValueV2()
  const { mutateAsync: mutateAsyncMHA } = usePostMha()
  const { makeToast, clearToasts } = Toaster.useToaster()

  const [hostType, setHostType] = useState<HostType>(initialValues.hostType)
  const validationSchema = useValidationSchema(hostType)
  const { isLastStep, nextStep, progress, stepIndex } = useFormNavigation(
    useSteps({ hostType })
  )
  const { setProjectType, setSecretLocationId, setSecretReferenceId } =
    useCreateProjectsContext()

  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)

  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)

  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()

  const handleOnSubmit = (id: string) => {
    refetch()
    return navigate(AppRoutes.getProjectDetails(id), {
      replace: true
    })
  }

  const publishProject = async (
    values: CreateProjectFormikValueTypes,
    onPublishStatus: (
      success: boolean,
      projectResponse?: POSTProjectResponse | TError,
      projectName?: string
    ) => void,
    onFinish: () => void
  ) => {
    return mutateProject({ values: createProjectMutationData(values) })
      .then((response) => {
        onPublishStatus(
          true,
          {
            projectId: response.projectId,
            branchId: response.branchId,
            environmentId: response.environmentId
          },
          values.projectName
        )
      })
      .catch((e: TError) => {
        onPublishStatus(false, e)
      })
      .finally(onFinish)
  }

  const submitForm = async (
    newValues: CreateProjectFormikValueTypes,
    { resetForm, setSubmitting }: FormikHelpers<CreateProjectFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)
    if (isLastStep) {
      await publishProject(
        newValues,
        (success, projectResponse, name) => {
          if (success && projectResponse) {
            const { projectId: id } = projectResponse as POSTProjectResponse

            makeToast({
              title: t('createProject.responseMessage.success', {
                projectName: name
              }),
              message: '',
              type: 'success'
            })

            window.sessionStorage.removeItem(CREATE_PROJECT_STORAGE_ITEM)
            return handleOnSubmit(id)
          }
          makeToast({
            title:
              (projectResponse as TError).response?.data?.detail ??
              t('createProject.responseMessage.error'),
            message: '',
            type: 'error'
          })
        },
        () => setSubmitting(false)
      )
    } else {
      try {
        switch (nextStep) {
          case PROJECTS_CREATE_ENVIRONMENT: {
            if (
              newValues.projectConfiguration ===
              AgentsSecretsHost.MatillionHosted
            ) {
              await mutateAsyncMHA()
              const { data, error, isError } = await mhaRefetch()

              if (isError && error?.response?.status !== 404) {
                throw error
              }

              if (!isMHARunning(data?.status)) {
                return navigate(PROJECTS_CREATE_AGENT, { replace: true })
              }

              newValues = {
                ...newValues,
                matillionHostedAgentId: data!.agentId
              }
            }
            break
          }
          case PROJECTS_CREDENTIALS: {
            const response = await mutateAsyncSecretLocation({
              values: createPostSecretLocationMutationData(newValues)
            })
            setSecretLocationId(response.secretLocationId)
            newValues = {
              ...newValues,
              secretLocationId: response.secretLocationId
            }

            break
          }
          case PROJECTS_DEFAULTS: {
            let response

            if (
              newValues.projectConfiguration ===
              AgentsSecretsHost.MatillionHosted
            ) {
              response = await mutateAsyncSecretValueV2({
                values: createSecretValueV2MutationData({
                  ...newValues,
                  name: newValues.environmentName,
                  secretValue: {
                    password: newValues.secretValue
                  }
                })
              })
            } else {
              response = await mutateAsyncSecretReference({
                values: createSecretReferenceMutationData(
                  newValues,
                  SecretReferenceTypes.DWH_PASSWORD
                )
              })
            }
            setSecretReferenceId(response.secretId)
            newValues = {
              ...newValues,
              secretReferenceId: response.secretId,
              // Manually resetting the first field of the Defaults page because Formik seems to re initialise the secret reference id
              // Which doesn't make the listener work on each of the fields
              defaultRole: initialValues.defaultRole,
              defaultDatabase: initialValues.defaultDatabase,
              compute: initialValues.compute
            }

            // Ensure that there is a connection to warehouse defaults role in order to continue
            // Otherwise it throws an error which is caught below and does not proceed to the next page
            switch (newValues.type) {
              case Warehouse.Snowflake:
                await mutateAsyncWarehouseRoleLookup({
                  values: createWarehouseDefaultsMutation(newValues)
                })
                break
              case Warehouse.Redshift:
                await mutateAsyncWarehouseDatabaseLookup({
                  values: createWarehouseDefaultsMutation(newValues)
                })
                break
              case Warehouse.Databricks:
                await mutateAsyncWarehouseComputeResourceLookup({
                  values:
                    createGetWarehouseComputeResourceMutationData(newValues)
                })
                break
            }
            break
          }
        }
        resetForm({ values: newValues })
        navigate(nextStep as To, { replace: true })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>

        const errorDetail = (err?.response?.data as ErrorResponse)?.detail
        const errorResponseStatus = err?.response?.status

        makeToast({
          title: t('error.unexpected.title'),
          message:
            errorResponseStatus === StatusCodes.BAD_REQUEST &&
            errorDetail !== undefined
              ? errorDetail
              : t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setProjectType(newValues.type)
        setSubmitting(false)
      }
    }
  }

  return (
    <Form<CreateProjectFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues,
        validationSchema: validationSchema,
        initialTouched: false,
        innerRef: useCallback(
          (el: FormikProps<CreateProjectFormikValueTypes> | null) => {
            const host = el?.values.hostType
            if (host) {
              setHostType(host)
            }
          },
          []
        )
      }}
      translationPrefix="createProject"
      stepInfo={{
        isLastStep,
        stepIndex,
        nextStep,
        progress
      }}
      persistingStorageId={CREATE_PROJECT_STORAGE_ITEM}
      persistenceExclusions={['secretValue']}
      removeSubmitButtonOnRoutes={[PROJECTS_CREATE_AGENT]}
      buttonsClassName={
        location.pathname.includes(
          `${AppRoutes.getProjectsAdd()}/${PROJECTS_HOST_TYPE as string}`
        )
          ? classes['Form__Buttons--expanded-container']
          : undefined
      }
    >
      <CreateProjectRoutes />
    </Form>
  )
}

export default CreateProjectForm
