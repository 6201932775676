import { camelCase } from 'lodash'
import { useFlags as ldUseFlags } from '@matillion/hub-client'
import config from 'config'

const useLocalFlags = (): Record<string, boolean | string[]> => {
  const { defaultFlags } = config.launchDarkly
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  return Object.entries(defaultFlags).reduce((obj, [key, defaultValue]) => {
    const normalisedKey = camelCase(key)
    const override = window.localStorage.getItem(`flag.${normalisedKey}`)
    return {
      ...obj,
      [normalisedKey]: override ? JSON.parse(override) : defaultValue
    }
  }, {})
}

export const useFlags =
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_LAUNCH_DARKLY === 'true'
    ? ldUseFlags
    : useLocalFlags
