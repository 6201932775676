import {
  PROJECTS_CREATE_AGENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROVISIONING_TRIAL_WAREHOUSE,
  WAREHOUSE
} from 'constants/route'

import { AgentsSecretsHost, Warehouse } from 'types'
import { mixed, object } from 'yup'
import { ProvisioningTypes } from 'modules/FormsContent/WarehouseProvisioningSelection/types'
import {
  OnboardingFormikValueTypes,
  OnboardingFormNavigationSteps
} from 'modules/Onboarding/OnboardingForm'

export type OnboardingFormNavigationStepsType = Record<
  OnboardingFormNavigationSteps,
  {
    previousStep?: OnboardingFormNavigationSteps
    currentStep: OnboardingFormNavigationSteps
    nextStep?: OnboardingFormNavigationSteps
    excludedFromProgress?: boolean
  }
>

export const steps = (isTrial = false): OnboardingFormNavigationStepsType => ({
  [WAREHOUSE]: {
    previousStep: undefined,
    currentStep: WAREHOUSE,
    nextStep: isTrial ? PROVISIONING_TRIAL_WAREHOUSE : PROJECTS_CREATE_AGENT
  },
  [PROJECTS_CREATE_AGENT]: {
    previousStep: WAREHOUSE,
    currentStep: PROJECTS_CREATE_AGENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROVISIONING_TRIAL_WAREHOUSE]: {
    previousStep: WAREHOUSE,
    currentStep: PROVISIONING_TRIAL_WAREHOUSE,
    nextStep: undefined
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_AGENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
})

export const initialValues = (isTrial = true): OnboardingFormikValueTypes => ({
  projectName: 'My first project',
  type: Warehouse.Snowflake,
  projectConfiguration: AgentsSecretsHost.MatillionHosted,
  environmentName: 'default',
  matillionHostedAgentId: '',
  account: '',
  username: '',
  secretValue: '',
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  catalog: {
    id: '',
    name: ''
  },
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  secretLocationId: '',
  secretReferenceId: '',
  warehouseProvisioning: isTrial ? 'trial' : 'customer'
})

export const warehouseProvisioningValidationSchema = {
  [WAREHOUSE]: object({
    warehouseProvisioning: mixed<ProvisioningTypes>()
      .oneOf(['trial', 'customer'])
      .required('fields.projectName.warehouseProvisioning.required')
  }),
  [PROJECTS_CREATE_AGENT]: object({}),
  [PROVISIONING_TRIAL_WAREHOUSE]: object({})
}
