import { QueryClient, type QueryClientConfig } from '@tanstack/react-query'
import useProblemDetails from '../hooks/useProblemDetails/useProblemDetails'
import { useMemo } from 'react'

export const useGitQueryClient = (overrides?: QueryClientConfig) => {
  const { isKnownProblem } = useProblemDetails()

  return useMemo(() => {
    return new QueryClient({
      ...overrides,
      defaultOptions: {
        ...overrides?.defaultOptions,
        queries: {
          ...overrides?.defaultOptions?.queries,
          staleTime: 1000 * 60 * 5, // <-- 5 minutes
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          refetchOnMount: true,
          retry: (failureCount: number, error: unknown) => {
            if (process.env.REACT_APP_ENVIRONMENT === 'test') {
              return false
            }

            return !isKnownProblem(error) && failureCount < 3
          }
        }
      }
    })
  }, [overrides, isKnownProblem])
}

export default useGitQueryClient
