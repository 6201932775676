import { Badge } from '@matillion/component-library'
import { FunctionComponent } from 'react'
import { roles } from 'modules/Projects/AccessListing/AccessListing.util'

export const ProjectRoleBadge: FunctionComponent<{
  role: string
}> = ({ role }) => {
  switch (role) {
    case 'admin':
    case 'creator':
      return (
        <Badge data-testid="project-role-admin" colour="green">
          {roles.admin}
        </Badge>
      )
    case 'user':
      return (
        <Badge data-testid="project-role-user" colour="blue">
          {roles.user}
        </Badge>
      )
    case 'read_only':
      return (
        <Badge data-testid="project-role-read-only" colour="grey">
          {roles.read_only}
        </Badge>
      )
    default:
      return null
  }
}
