import { FunctionComponent, useMemo } from 'react'
import { Field as FormikField, FieldProps, useFormikContext } from 'formik'
import {
  AutoComplete,
  AutoCompleteItem,
  Field
} from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

import { resolveErrorText } from 'modules/Projects/CreateBranch/CreateBranch.util'
import classes from 'modules/Projects/CreateBranch/CreateBranch.module.scss'
import { useGetEnvironments } from 'api/hooks'
import { useParams } from 'react-router-dom'

const Environment: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId = '' } = useParams()
  const { data: environmentsData = [], isError } = useGetEnvironments(projectId)

  const env = environmentsData
    .map((x) => ({ id: x.id, name: x.name }))
    .sort((a, b) => a.name.localeCompare(b.name))

  const { getFieldMeta } = useFormikContext()
  const fieldMeta = getFieldMeta('defaultEnvironment')

  const getErrorMessage = useMemo(() => {
    if (isError) {
      return t('addBranch.fields.defaultEnvironment.error.loadingError')
    }

    if (!environmentsData.length) {
      return t('addBranch.fields.defaultEnvironment.error.noItemsFound')
    }

    return resolveErrorText(t, fieldMeta)
  }, [environmentsData, fieldMeta, t, isError])
  return (
    <FormikField name="defaultEnvironment">
      {({ field, form: { setFieldValue } }: FieldProps<string>) => (
        <div data-testid="create-branch-default-environment">
          <Field
            {...field}
            data-testid="create-branch-default-environment-input"
            className={classes['CreateBranch__Field--spaced']}
            title={t('addBranch.fields.defaultEnvironment.title')}
            name="defaultEnvironment"
            placeholder={t(
              'addBranch.fields.defaultEnvironment.placeholderText'
            )}
            inputComponent={AutoComplete}
            availableItems={env}
            errorText={getErrorMessage}
            value={field.value || null}
            onChange={async (e: { target: AutoCompleteItem }) =>
              setFieldValue('defaultEnvironment', e.target.value?.id)
            }
          />
        </div>
      )}
    </FormikField>
  )
}

export default Environment
