import { useTranslation } from 'react-i18next'

import { ReactComponent as AWSLogo } from 'assets/AWSLogo.svg'
import { ReactComponent as AzureLogo } from 'assets/MicrosoftAzureLogo.svg'
import { ReactComponent as GCPLogo } from 'assets/GCPLogo.svg'
import { AvailableProvidersProps } from 'modules/FormsContent/CloudProviderCredentials/Fields/types'
import { CloudProviders } from 'types/CloudProviders'

export const useAvailableProviders = (): Record<
  CloudProviders,
  AvailableProvidersProps
> => {
  const { t } = useTranslation()

  return {
    [CloudProviders.AWS]: {
      providerLogo: AWSLogo,
      providerName: t('cloudProviders.aws'),
      enabled: true
    },
    [CloudProviders.AZURE]: {
      providerLogo: AzureLogo,
      providerName: t('cloudProviders.azure'),
      enabled: true
    },
    [CloudProviders.GCP]: {
      providerLogo: GCPLogo,
      providerName: t('cloudProviders.gcp'),
      enabled: false
    }
  }
}
