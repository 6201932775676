import { updateSchedule } from 'api/clients'
import { useClient } from 'api/hooks/useClient'
import { PUTScheduleRequest } from 'api/types'
import { AxiosError } from 'axios'
import { QueryKey } from 'constants/endpoint'
import { useMutation, UseMutationOptions } from 'react-query'

type TError = AxiosError<unknown>
interface TVariables {
  values: PUTScheduleRequest
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const usePutSchedule = (
  projectId: string,
  scheduleId: string,
  options?: Options
) => {
  const client = useClient()

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.EDIT_SCHEDULE, projectId, scheduleId],
    async (data: { values: PUTScheduleRequest }) => {
      const { values } = data
      return updateSchedule(scheduleId, values, client)
    },
    options
  )
}
