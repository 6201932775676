import {
  GETEnvironmentsResponseParams,
  GETSecretReferencesResponseParams
} from 'api/types'
import { object, string } from 'yup'

export interface FormValues {
  cloudCredential: GETSecretReferencesResponseParams | null
  environment: GETEnvironmentsResponseParams | null
}

export const initialValues: FormValues = {
  cloudCredential: null,
  environment: null
}

export const formSchema = object({
  cloudCredential: object({
    name: string().required(
      'associateCredentials.fields.cloudCredentialName.error.required'
    )
  }).required('associateCredentials.fields.cloudCredentialName.error.required'),
  environment: object({
    id: string().required(
      'associateCredentials.fields.environment.error.required'
    ),
    name: string().required(
      'associateCredentials.fields.environment.error.required'
    )
  })
    .required('associateCredentials.fields.environment.error.unmatched')
    .nullable()
})
