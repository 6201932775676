import { Fragment, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { ENVIRONMENT_STORAGE_ITEM } from 'constants/persistance'
import {
  PROJECTS_ADD,
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS,
  PROJECT_DETAILS_ENVIRONMENTS
} from 'constants/route'
import { initialValues } from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/CreateEnvironmentForm.util'
import CreateEnvironment from 'modules/FormsContent/CreateEnvironment'
import * as Sources from 'modules/FormsContent/Sources'
import { EditEnvironmentFormikValueTypes } from 'modules/Projects/EditEnvironment/types'
import { capitalize } from 'lodash'

const ConfigureRoutes = () => {
  const navigate = useNavigate()
  const { setValues } = useFormikContext<EditEnvironmentFormikValueTypes>()
  const { projectId } = useParams()
  const { t } = useTranslation()

  const handleOnCancel = () => {
    window.sessionStorage.removeItem(ENVIRONMENT_STORAGE_ITEM)
    navigate(`/${projectId!}/${PROJECT_DETAILS_ENVIRONMENTS}`)
  }
  const persistedFormValues = sessionStorage.getItem(ENVIRONMENT_STORAGE_ITEM)
  const projectType = persistedFormValues
    ? JSON.parse(persistedFormValues).type
    : ''
  const warehouseRoutes = Object.entries(Sources).reduce<JSX.Element[]>(
    (result, [label, Component]) => {
      if (label === capitalize(projectType)) {
        result.push(
          <Fragment key={`${label}-route`}>
            <Route
              path={`${PROJECTS_CREDENTIALS}/*`}
              element={
                <Component
                  showCancel
                  onCancel={handleOnCancel}
                  onPrevious={() =>
                    navigate(PROJECTS_CREATE_ENVIRONMENT, { replace: true })
                  }
                  showPrevious
                  modalContent={t('createEnvironment.modal.content')}
                />
              }
            />
            <Route
              path={`${PROJECTS_DEFAULTS}/*`}
              element={
                <Component
                  showCancel
                  onCancel={handleOnCancel}
                  onPrevious={() =>
                    navigate(PROJECTS_CREDENTIALS, { replace: true })
                  }
                  showPrevious
                  modalContent={t('createEnvironment.modal.content')}
                />
              }
            />
          </Fragment>
        )
      }
      return result
    },
    []
  )

  // If session storage gets deleted then redirect to the start of the form
  useEffect(() => {
    if (
      !persistedFormValues &&
      window.location.pathname !==
        `/${projectId!}/${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_ADD}/${PROJECTS_CREATE_ENVIRONMENT}`
    ) {
      navigate(PROJECTS_CREATE_ENVIRONMENT, { replace: true })
      setValues(initialValues)
    }
  }, [navigate, persistedFormValues, projectId, setValues])

  return (
    <Routes>
      <Route
        path={`${PROJECTS_CREATE_ENVIRONMENT}/*`}
        element={
          <CreateEnvironment
            showCancel
            onCancel={handleOnCancel}
            modalContent={t('createEnvironment.modal.content')}
          />
        }
      />
      {warehouseRoutes}
      <Route
        path="*"
        element={<Navigate replace to={PROJECTS_CREATE_ENVIRONMENT} />}
      />
    </Routes>
  )
}

export default ConfigureRoutes
