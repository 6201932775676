import { type BranchInformation } from 'api/hooks/useGetBranch/types'
import { MockedGetWorkingTreeBranchResponses } from 'api/hooks/useGetWorkingTreeBranch/useGetWorkingTreeBranch.responses'
import { type LegacyDescribeMergeResponse } from 'api/hooks/useLegacyDescribeMerge/types'
import { MockedLegacyMergeDescription } from 'api/hooks/useLegacyDescribeMerge/useLegacyDescribeMerge.responses'
import { MockedGetBranchInformation } from 'api/hooks/useGetBranch/useGetBranch.responses'
import { useDescribeMergeResponses } from 'api/hooks/useDescribeMerge/useDescribeMerge.responses'
import { type WtsDescribeMergeResponse } from 'api/hooks/useDescribeMerge/types'
import { type WorkingTreeBranchResponse } from 'api/hooks/useGetWorkingTreeBranch/types'
import { type CommitResponse } from 'api/hooks/useCommit/types'
import { MockCommitResponses } from 'api/hooks/useCommit/useCommit.responses'
import { type PushChangesResponse } from 'api/hooks/usePushChanges/types'
import { usePushChangesResponses } from 'api/hooks/usePushChanges/usePushChanges.responses'
import { type InitOAuthResponse } from 'api/hooks/useInitOAuth/types'
import { useInitOAuthResponses } from 'api/hooks/useInitOAuth/useInitOAuth.responses'
import { type MergeResponse } from 'api/hooks/useLegacyMerge/types'
import { useLegacyMergeResponses } from 'api/hooks/useLegacyMerge/useLegacyMerge.responses'
import { type GetRepositoriesResponse } from 'api/hooks/useGetRepositories/types'
import { useGetRepositoriesResponses } from 'api/hooks/useGetRepositories/useGetRepositories.responses'
import {
  type ApiPromise,
  type Config,
  defaultConfig,
  type InterceptorConfig,
  type InterceptorDetails,
  type InterceptorResponse
} from './types'
import { type CyHttpMessages } from 'cypress/types/net-stubbing'

class ApiInterceptor {
  private createInterceptor<Response>({
    name,
    method,
    endpoint,
    config,
    defaultResponse,
    responseCode
  }: InterceptorDetails<Response>): InterceptorResponse {
    const {
      projectId,
      branch,
      provider,
      alias,
      statusCode,
      delay,
      manualResolution,
      commitRange,
      headers
    } = this.parseConfig(config, name, responseCode)

    const uri = endpoint({ project: projectId, branch, provider, commitRange })

    const reply = (req: CyHttpMessages.IncomingHttpRequest) => {
      req.reply({
        body: config?.response ?? defaultResponse,
        statusCode: statusCode ?? responseCode,
        headers,
        delay
      })
    }

    if (manualResolution) {
      let capturedPromise: ApiPromise

      const capturePromise = new Promise((resolve) => {
        capturedPromise = resolve
      })

      cy.intercept(method, uri, async (req) => {
        return capturePromise.then(() => {
          reply(req)
        })
      }).as(alias)

      return {
        resolve: capturedPromise,
        alias
      }
    }

    cy.intercept(method, uri, (req) => {
      reply(req)
    }).as(alias)

    return {
      alias
    }
  }

  legacyDescribeMerge(
    config?: InterceptorConfig<LegacyDescribeMergeResponse>
  ): InterceptorResponse {
    return this.createInterceptor<LegacyDescribeMergeResponse>({
      config,
      method: 'GET',
      name: 'legacyDescribeMerge',
      responseCode: 200,
      defaultResponse:
        MockedLegacyMergeDescription[
          (config?.commitRange ?? defaultConfig.commitRange).split('...')[1]
        ],
      endpoint: ({ project, commitRange }) =>
        `/etl-designer/api/v1/projects/${project}/merges/${commitRange}`
    })
  }

  legacyMerge(config?: InterceptorConfig<MergeResponse>): InterceptorResponse {
    return this.createInterceptor<MergeResponse>({
      config,
      method: 'POST',
      name: 'legacyPostMerge',
      responseCode: 200,
      defaultResponse: useLegacyMergeResponses,
      endpoint: ({ project }) =>
        `/etl-designer/api/v1/projects/${project}/merges`
    })
  }

  getBranch(
    config?: InterceptorConfig<BranchInformation>
  ): InterceptorResponse {
    return this.createInterceptor<BranchInformation>({
      config,
      method: 'GET',
      name: 'getBranch',
      responseCode: 200,
      defaultResponse:
        MockedGetBranchInformation[config?.branch ?? defaultConfig.branch],
      endpoint: ({ branch, project }) =>
        `/etl-designer/api/v1/branches/${branch}?projectId=${project}`
    })
  }

  deleteBranch(config?: InterceptorConfig<unknown>): InterceptorResponse {
    return this.createInterceptor({
      config,
      method: 'DELETE',
      name: 'deleteBranch',
      responseCode: 201,
      defaultResponse: {},
      endpoint: ({ project, branch }) =>
        `/working-tree-service/v1/projects/${project}/branches/${branch}`
    })
  }

  getWorkingTreeBranch(
    config?: InterceptorConfig<WorkingTreeBranchResponse>
  ): InterceptorResponse {
    return this.createInterceptor<WorkingTreeBranchResponse>({
      config,
      method: 'GET',
      responseCode: 200,
      name: 'getWorkingTreeBranch',
      defaultResponse:
        MockedGetWorkingTreeBranchResponses[
          config?.branch ?? defaultConfig.branch
        ],
      endpoint: ({ project, branch }) =>
        `/etl-designer/api/v1/projects/${project}/branches/${branch}/workingtree`
    })
  }

  describeMerge(
    config?: InterceptorConfig<WtsDescribeMergeResponse>
  ): InterceptorResponse {
    return this.createInterceptor<WtsDescribeMergeResponse>({
      config,
      method: 'GET',
      name: 'getWorkingTreeMerge',
      responseCode: 200,
      defaultResponse:
        useDescribeMergeResponses[config?.branch ?? defaultConfig.branch],
      endpoint: ({ project, branch }) =>
        `/working-tree-service/v1/projects/${project}/branches/${branch}/merges`
    })
  }

  commit(config?: InterceptorConfig<CommitResponse>): InterceptorResponse {
    return this.createInterceptor<CommitResponse>({
      config,
      method: 'POST',
      responseCode: 200,
      name: 'commit',
      defaultResponse:
        MockCommitResponses[config?.branch ?? defaultConfig.branch],
      endpoint: ({ branch, project }) =>
        `/etl-designer/api/v1/branches/${branch}/commits?projectId=${project}`
    })
  }

  merge(config?: InterceptorConfig<unknown>): InterceptorResponse {
    return this.createInterceptor({
      config,
      method: 'POST',
      responseCode: 200,
      name: 'postWorkingTreeMerge',
      defaultResponse:
        MockedLegacyMergeDescription[config?.branch ?? defaultConfig.branch],
      endpoint: ({ project, branch }) =>
        `/working-tree-service/v1/projects/${project}/branches/${branch}/merges`
    })
  }

  pushChanges(
    config?: InterceptorConfig<PushChangesResponse>
  ): InterceptorResponse {
    return this.createInterceptor<PushChangesResponse>({
      config,
      method: 'POST',
      responseCode: 200,
      name: 'pushChanges',
      defaultResponse:
        usePushChangesResponses[config?.branch ?? defaultConfig.branch],
      endpoint: ({ project, branch }) =>
        `/working-tree-service/v1/projects/${project}/branches/${branch}/push-to-remote`
    })
  }

  initialiseOAuth(
    config?: InterceptorConfig<InitOAuthResponse>
  ): InterceptorResponse {
    return this.createInterceptor<InitOAuthResponse>({
      config,
      method: 'POST',
      name: 'initOAuth',
      responseCode: 200,
      defaultResponse: useInitOAuthResponses.github,
      endpoint: ({ provider }) => `/git-service/v1/providers/${provider}/oauth`
    })
  }

  captureOAuthToken(config?: InterceptorConfig<unknown>): InterceptorResponse {
    return this.createInterceptor({
      config,
      method: 'POST',
      responseCode: 201,
      defaultResponse: {},
      name: 'captureOAuthToken',
      endpoint: ({ provider }) =>
        `/git-service/v1/providers/${provider}/oauth/tokens`
    })
  }

  getExternalGitRepositories(
    config?: InterceptorConfig<GetRepositoriesResponse>
  ): InterceptorResponse {
    return this.createInterceptor<GetRepositoriesResponse>({
      config,
      method: 'GET',
      responseCode: 200,
      name: 'getExternalGitRepositories',
      defaultResponse: useGetRepositoriesResponses.github,
      endpoint: ({ provider }) =>
        `/git-service/v1/providers/${provider}/repositories`
    })
  }

  private parseConfig<T>(
    config?: InterceptorConfig<T>,
    name?: string,
    responseCode?: number
  ): Config<T> {
    const branch = config?.branch ?? defaultConfig.branch
    const projectId = config?.projectId ?? defaultConfig.projectId
    const response = config?.response ?? defaultConfig.response
    const statusCode =
      config?.statusCode ?? responseCode ?? defaultConfig.statusCode
    const alias = config?.alias ?? name ?? defaultConfig.alias
    const provider = config?.provider ?? defaultConfig.provider
    const delay = config?.delay ?? defaultConfig.delay
    const manualResolution =
      config?.manualResolution ?? defaultConfig.manualResolution
    const commitRange = config?.commitRange ?? defaultConfig.commitRange
    const headers = config?.headers ?? defaultConfig.headers

    return {
      branch,
      projectId,
      response,
      statusCode,
      alias,
      provider,
      delay,
      commitRange,
      headers,
      manualResolution
    }
  }
}

const apiInterceptor = new ApiInterceptor()

export default apiInterceptor
