import { getDatabricksComputeResources } from 'api/createProjectForm/client'
import { useClient } from 'api/hooks/useClient'
import { AxiosError } from 'axios'
import { QueryKey } from 'constants/endpoint'
import { useMutation, UseMutationOptions } from 'react-query'
import { GETDatabricksComputeResourceRequest } from 'api/createProjectForm/types/DatabricksComputeResourcesRequest'
import { GETDatabricksComputeResourcesResponse } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'

type TData = GETDatabricksComputeResourcesResponse
type TError = AxiosError<unknown>

interface TVariables {
  values: GETDatabricksComputeResourceRequest
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

interface MutateDatabricksComputeResourcesProps {
  options?: Options
  showErrorDetailMessage?: boolean
}

export const useMutateDatabricksComputeResources = ({
  options,
  showErrorDetailMessage = false
}: MutateDatabricksComputeResourcesProps = {}) => {
  const client = useClient({ showErrorDetailMessage })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.DATABRICKS_COMPUTE_RESOURCES],
    async (data: TVariables) => {
      const { values } = data
      return getDatabricksComputeResources(values, client)
    },
    options
  )
}
