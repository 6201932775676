import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Field } from '@matillion/component-library'
import classes from 'components/Form/Form.module.scss'

export const Name: FunctionComponent = () => {
  const { t } = useTranslation()
  const { getFieldProps } = useFormikContext()
  const fieldName = 'environmentName'
  const field = getFieldProps(fieldName)

  return (
    <div data-testid="associate-cloud-credentials-environment-name">
      <Field
        {...field}
        className={classes.Form__SpacingStyles}
        title={t('associateCredentials.fields.environment.title')}
        name={fieldName}
        data-testid="associate-cloud-credentials-environment-name-input"
        disabled
      />
    </div>
  )
}
