import { Toaster } from '@matillion/component-library'
import { useGetMha } from 'api/hooks'
import { ErrorResponse } from 'api/types'
import { AxiosError } from 'axios'
import { StatusCodes } from 'constants/statusCodes'
import { useFormikContext } from 'formik'
import { usePolling } from 'hooks/polling/usePolling'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { EnvironmentDetailsI } from 'types/FormTypes'
import { isMHAFailed, isMHARunning } from 'modules/utils'

interface UseMhaProvisioningProps {
  onError: () => void
  onCompleted?: () => void
  enabled?: boolean
}

export const useMhaProvisioningWithPolling = ({
  onError,
  onCompleted,
  enabled = false
}: UseMhaProvisioningProps) => {
  const { makeToast, clearToasts } = Toaster.useToaster()

  const { refetch, data: mhaData } = useGetMha({ enabled: false })
  const { setFieldValue } = useFormikContext<EnvironmentDetailsI>()
  const [agentComplete, setAgentComplete] = useState(false)

  const checkMhaReadyAndAssignAgentIdToForm = useCallback(
    async (suspendInterval: () => void = () => null) => {
      if (!enabled) return
      try {
        clearToasts()
        const { data, error, isError } = await refetch()
        if (isError && error?.response?.status !== StatusCodes.NOT_FOUND) {
          throw error
        }
        const status = data?.status
        if (isMHARunning(status)) {
          suspendInterval()
          setFieldValue('matillionHostedAgentId', data?.agentId)
          setAgentComplete(true)
        } else if (isMHAFailed(status)) {
          suspendInterval()

          onError()
          makeToast({
            title: t('formContent.agent.responseMessage.error'),
            message: '',
            type: 'error'
          })
        }
      } catch (error: unknown) {
        clearToasts()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>
        const errorDetail = (err?.response?.data as ErrorResponse)?.detail
        suspendInterval()

        onError()
        makeToast({
          title: t('error.unexpected.title'),
          message: errorDetail ?? t('error.unexpected.message'),
          type: 'error'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCompleted, onError, refetch, setFieldValue, enabled]
  )

  usePolling(
    checkMhaReadyAndAssignAgentIdToForm,
    agentComplete,
    onCompleted,
    enabled
  )

  return { agentComplete, mhaData }
}
