import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Radio, Typography } from '@matillion/component-library'

import { HostType } from 'types/HostType'
import { ProjectDetailsI } from 'types/FormTypes'

import classes from './HostTypeCard.module.scss'

export interface HostTypeCardProps {
  title: string
  supportText: string
  desiredHostType: HostType
  typeOfSetUp: string
  bullets?: string[]
}

const HostTypeCard: FunctionComponent<HostTypeCardProps> = ({
  title,
  supportText,
  desiredHostType,
  typeOfSetUp,
  bullets
}) => {
  const { t } = useTranslation()
  const { setFieldValue, values } = useFormikContext<ProjectDetailsI>()
  const hostType = values.hostType

  const onSelect = () => {
    setFieldValue('hostType', desiredHostType)
  }
  const isSelected = hostType === desiredHostType

  return (
    <button
      name="host-type-card-btn"
      aria-label={t('hostTypeSelection.ariaLabels.button')}
      type="button"
      onClick={onSelect}
      className={classNames(
        classes.HostTypeCard__Container,
        isSelected && classes['HostTypeCard__Container--selected']
      )}
      data-testid={`${desiredHostType}-card`}
    >
      <div className={classes.HostTypeCard__TopBanner}>
        <div
          className={classes.HostTypeCard__SetUp}
          data-testid={`${desiredHostType}-setUp`}
        >
          {typeOfSetUp}
        </div>
        <Radio
          name="host-type-radio"
          className={classes.HostTypeCard__RadioBtn}
          checked={isSelected}
          onChange={onSelect}
          data-testid={`${desiredHostType}-radio`}
        />
      </div>
      <div className={classes.HostTypeCard__Content}>
        <div className={classes.HostTypeCard__TitleContainer}>
          <Typography
            as="h3"
            format="tm"
            data-testid={`${desiredHostType}-title`}
          >
            {title}
          </Typography>
        </div>
        <div>
          <Typography
            format="bcm"
            data-testid={`${desiredHostType}-support-text`}
          >
            {supportText}
          </Typography>
          {!!bullets?.length && (
            <ul>
              {bullets.map((bullet, index) => (
                <li key={`${bullet}-${index}`}>
                  <Typography
                    format="bcm"
                    data-testid={`${desiredHostType}-bullet-${index}`}
                  >
                    {bullet}
                  </Typography>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </button>
  )
}

export default HostTypeCard
