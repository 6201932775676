import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import { useResetFieldOnProviderChange } from 'modules/FormsContent/CloudProviderCredentials/Fields/hooks'
import classes from 'modules/FormsContent/Form.module.scss'
import { FormValues } from 'modules/Projects/CloudProviderCredentials'

const SecretKey: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  useResetFieldOnProviderChange('secretKey', {
    setFieldTouched,
    setFieldValue
  })

  return (
    <div data-testid="cloud-provider-credentials-aws-secret-key">
      <Field
        type="password"
        title={t('cloudProviderCredentials.fields.aws.secretKey.title')}
        name="secretKey"
        data-testid="cloud-provider-credentials-aws-secret-key-input"
        value={values.secretKey}
        placeholder={t(
          'cloudProviderCredentials.fields.aws.secretKey.placeholderText'
        )}
        supportText={t(
          'cloudProviderCredentials.fields.aws.secretKey.supportText'
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        errorText={
          errors?.secretKey && touched.secretKey ? t(errors.secretKey) : null
        }
        hasError={!!errors?.secretKey && touched.secretKey}
        required
      />
    </div>
  )
}

export default SecretKey
