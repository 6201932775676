import { useCallback, useEffect } from 'react'
import {
  GIT_AUTH_EVENT_ID,
  type GitAuthMessagePayload,
  type GitAuthMessageHandlerProps,
  type GitAuthMessageHandlerResponse
} from './types'
import useCaptureOAuthToken from 'api/hooks/useCaptureOAuthToken'
import { Toaster } from '@matillion/component-library'
import { useTranslation } from 'react-i18next'
import useGetProviderDisplayName from '../useGetProviderDisplayName'

const useAuthMessageListener = ({
  onAuthSuccess,
  onAuthError,
  provider
}: GitAuthMessageHandlerProps): GitAuthMessageHandlerResponse => {
  const { makeToast } = Toaster.useToaster()
  const { providerName } = useGetProviderDisplayName(provider)

  const { t } = useTranslation('translation', {
    keyPrefix: 'auth.listener'
  })

  const { mutateAsync: captureAuthToken, isLoading } = useCaptureOAuthToken({
    provider
  })

  const handleSuccess = useCallback(async () => {
    makeToast({
      type: 'success',
      title: t('success.title'),
      message: t('success.message', { provider: providerName })
    })

    await onAuthSuccess()
  }, [makeToast, onAuthSuccess, providerName, t])

  const handleError = useCallback(async () => {
    makeToast({
      type: 'error',
      title: t('error.title'),
      message: t('error.message')
    })

    await onAuthError()
  }, [makeToast, onAuthError, t])

  const handleMessage = useCallback(
    (event: MessageEvent<GitAuthMessagePayload>) => {
      if (event.data.type === GIT_AUTH_EVENT_ID) {
        const { code, state } = event.data

        captureAuthToken({ state, code }).then(handleSuccess).catch(handleError)
      }
    },
    [captureAuthToken, handleError, handleSuccess]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [handleMessage])

  return {
    loading: isLoading
  }
}

export default useAuthMessageListener
