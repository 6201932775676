import { type RequestHandler, rest } from 'msw'

import { PROJECT_ID } from '__test-utils__/ids'

import { type WtsDescribeMergeResponse } from 'api/hooks/useDescribeMerge/types'
import { useDescribeMergeResponses } from 'api/hooks/useDescribeMerge/useDescribeMerge.responses'
import { APPLICATION_PROBLEM_JSON } from '__test-utils__/pact.config'
import { Problem } from 'hooks/useProblemDetails/Problem'

export const stubDescribePullChanges = (
  baseUrl = '',
  projectId = PROJECT_ID
) => {
  return Object.keys(useDescribeMergeResponses).map(() => {
    const url = `${baseUrl}/working-tree-service/v1/projects/${projectId}/branches/:branchName/merges`

    return rest.get(url, async (req, res, ctx) => {
      const branchNameParam = req.params.branchName as string

      if (branchNameParam === 'branchNotFound') {
        return res(
          ctx.status(404),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_BRANCH_NOT_FOUND,
            title: 'Not found',
            status: 404
          })
        )
      } else if (branchNameParam === 'uncommittedChangesDescribeConflict') {
        return res(
          ctx.status(409),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_UNCOMMITTED_CHANGES_PREVENT_MERGE,
            title: 'Conflict',
            status: 409
          })
        )
      } else if (branchNameParam === 'remoteBranchNotFound') {
        return res(
          ctx.status(404),
          ctx.set('content-type', APPLICATION_PROBLEM_JSON),
          ctx.json({
            type: Problem.WTS_REMOTE_BRANCH_NOT_FOUND,
            title: 'Not Found',
            status: 404
          })
        )
      } else if (branchNameParam === '500') {
        return res(ctx.status(500))
      } else {
        return res(
          ctx.json<WtsDescribeMergeResponse>(
            useDescribeMergeResponses[branchNameParam]
          )
        )
      }
    })
  })
}

export const useDescribeMergeHandlers: RequestHandler[] = [
  ...stubDescribePullChanges(),
  ...stubDescribePullChanges('', '500')
]
