import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postMha } from 'api/clients'
import { useClient } from 'api/hooks'
import { QueryKey } from 'constants/endpoint'

type Options = UseMutationOptions<void, AxiosError>

export const usePostMha = (options?: Options) => {
  const client = useClient()

  return useMutation(
    [QueryKey.CREATE_MHA],
    async () => postMha(client),
    options
  )
}
