import useGitContext from 'provider/GitProvider/useGitContext'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { queryKeys } from 'api/query-keys'
import { type PushChangesResponse } from './types'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'

const useGetPushChangesQueryKey = () => {
  const { project, branch } = useGitContext()
  return [queryKeys.push, project, branch]
}

const usePushChanges = () => {
  const client = useWorkingTreeClient()
  const { project, branch } = useGitContext()

  const push = useCallback(async () => {
    const url = `/projects/${project}/branches/${branch}/push-to-remote`
    const { data } = await client.post<PushChangesResponse>(url)
    return data
  }, [branch, client, project])

  return useMutation(useGetPushChangesQueryKey(), push)
}

export default usePushChanges
