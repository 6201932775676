import { FormikErrors } from 'formik'

interface ObjectProps {
  id?: string
}

/**
 * Displays a string or object Formik error message depending on whether the state has been touched.
 * Accepts a string for error, or alternatively an object that contains a string for the id as the message itself.
 *
 * @param {string | Object} error - The error message
 * @param {Object} isTouched - The state of whether the error should show depending if it has or hasn't been touched
 * @returns the error message (string) or null
 */

export const renderFormikError = (
  error: string | FormikErrors<ObjectProps> | undefined,
  isTouched: boolean
) => {
  if (error && isTouched) {
    if (typeof error === 'string') {
      return error
    }

    if (error.id) {
      return error.id
    }
  }

  return null
}
