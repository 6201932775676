import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@matillion/component-library'
import { ActionCellTypes } from '.'
import EllipsesActionItem, {
  ThemeType
} from 'components/EllipsesActionItem/EllipsesActionItem'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  member,
  currentUserId,
  currentUserRole,
  onRemoveClick,
  onEditClick
}) => {
  const { t } = useTranslation()

  const handleRemoveClick = () => {
    onRemoveClick(member)
  }

  const handleEditClick = () => {
    onEditClick(member)
  }

  return member.id !== currentUserId &&
    (currentUserRole === 'admin' || currentUserRole === 'creator') ? (
    <EllipsesActionItem
      actions={[
        {
          title: t('accessListing.actions.edit'),
          action: handleEditClick,
          icon: <Icon.Pencil />,
          dataTestId: 'ellipses-action-edit-user'
        },
        {
          title: t('accessListing.actions.remove'),
          action: handleRemoveClick,
          icon: <Icon.Trash />,
          type: 'danger' as ThemeType,
          dataTestId: 'ellipses-action-remove-user'
        }
      ]}
    />
  ) : null
}

export default ActionCell
