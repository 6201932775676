import { noop } from 'lodash'
import { createContext, useContext } from 'react'

import { GenericSchemaType } from 'types'

interface CloudProviderCredentialsContextType {
  validationSchema?: GenericSchemaType
  setValidationSchema: (schema: GenericSchemaType) => void
}

export const CloudProviderCredentialsContext =
  createContext<CloudProviderCredentialsContextType>({
    validationSchema: undefined,
    setValidationSchema: noop
  })

export const useCloudProviderCredentialsContext = (
  context = CloudProviderCredentialsContext
) => useContext(context)
