import { FunctionComponent, ReactNode, useRef } from 'react'
import classNames from 'classnames'
import {
  Tooltip,
  Typography,
  TypographyProps
} from '@matillion/component-library'

import classes from './TypographyWithTooltipProps.module.scss'

interface TypographyWithToolTipProps extends TypographyProps {
  tooltip: string | ReactNode
  tooltipClassName?: string
  children: ReactNode
}

export const TypographyWithToolTip: FunctionComponent<
  TypographyWithToolTipProps
> = ({ tooltip, children, tooltipClassName, ...props }) => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <div ref={ref}>
      <Tooltip
        content={tooltip}
        parentElement={ref?.current ?? undefined}
        className={classNames(
          classes.TypographyWithToolTipProps__Tooltip,
          tooltipClassName
        )}
      >
        <div>
          <Typography {...props}>{children}</Typography>
        </div>
      </Tooltip>
    </div>
  )
}
