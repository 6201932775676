import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { AutoComplete } from '@matillion/component-library'

import BranchIcon from 'icons/BranchIcon.svg'

import BranchDescription from 'components/BranchDescription'
import useGitContext from 'provider/GitProvider/useGitContext'
import { type AutoCompleteHandler, type BranchSelectorProps } from './types'

const BranchSelector = ({
  branch,
  loading,
  branches,
  onSelectBranch,
  excludeSourceBranch
}: BranchSelectorProps) => {
  const { t } = useTranslation()
  const { branch: branchId } = useGitContext()

  const dropdownItems = branches
    .map((response) => ({
      id: response.name,
      name: response.name
    }))
    .filter((item) => item.name !== branchId || !excludeSourceBranch)

  const handleSelected = useCallback<AutoCompleteHandler>(
    ({ target: { value } }) => {
      onSelectBranch(value?.name)
    },
    [onSelectBranch]
  )

  return (
    <div data-testid="branch-selector">
      <AutoComplete
        iconBefore={{
          icon: <img src={BranchIcon} alt={'Branch Icon'} />
        }}
        value={branch}
        loading={loading}
        onChange={handleSelected}
        availableItems={dropdownItems}
        placeholder={t('merge.targetBranch.dropdown.label')}
      />
      {branch && <BranchDescription branchName={branch} />}
    </div>
  )
}

export default BranchSelector
