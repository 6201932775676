import cronstrue from 'cronstrue'
import cronValidator from 'cron-expression-validator'
import { ScheduleDisplayMode } from './ScheduleDisplayMode'

export interface GETSchedulesResponseParams {
  schedules: Array<{
    projectId: string
    environment: string
    job: string
    agentId: string
    schedule: {
      cronExpression: string
      timezone: string
      startTime: string
      scheduleEnabled: boolean
      name: string
      lastRun?: string
      nextRun?: string
      schedulerId: string
      displayMode: ScheduleDisplayMode
    }
  }>
}

export interface GETSchedulesResponse {
  id: string
  projectId: string
  environment: string
  job: string
  agentId: string
  cronExpression: string
  timezone: string
  startTime: string
  scheduleEnabled: boolean
  name: string
  lastRun: string
  nextRun: string
  schedule: string
  displayMode: ScheduleDisplayMode
}

export const mutateGetSchedulesResponse = (
  scheduleResponseParam: GETSchedulesResponseParams
): GETSchedulesResponse[] => {
  return scheduleResponseParam.schedules.map((s) => ({
    id: s.schedule.schedulerId,
    projectId: s.projectId,
    environment: s.environment,
    job: s.job,
    cronExpression: s.schedule.cronExpression,
    agentId: s.agentId,
    timezone: s.schedule.timezone,
    startTime: s.schedule.startTime,
    scheduleEnabled: s.schedule.scheduleEnabled,
    name: s.schedule.name,
    lastRun: s.schedule?.lastRun ?? '-',
    nextRun: s.schedule?.nextRun ?? '-',
    displayMode: s.schedule.displayMode,
    schedule: cronValidator.isValidCronExpression(s.schedule.cronExpression)
      ? cronstrue.toString(s.schedule.cronExpression, {
          use24HourTimeFormat: true
        })
      : 'Invalid cron expression'
  }))
}
