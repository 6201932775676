import { FunctionComponent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext, getIn } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import { renderFormikError } from 'modules/utils'
import { useCreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'
import classes from 'components/Form/Form.module.scss'
import { useDynamicValidation } from 'hooks/dynamicValidation/useDynamicValidation'

export interface FormInputStringProps {
  title: string
  formikFormName: string
  placeholderText?: string
  supportText?: string
  required?: boolean
  inputType?: 'text' | 'password'
}

export const FormInputString: FunctionComponent<FormInputStringProps> = ({
  title,
  formikFormName,
  placeholderText,
  supportText,
  required = true,
  inputType = 'text'
}) => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext()
  const { t } = useTranslation()
  const { validationSchema, setValidationSchema } = useCreateOAuthContext()
  useDynamicValidation(
    formikFormName,
    title,
    setValidationSchema,
    validationSchema,
    required
  )
  const formikError = renderFormikError(
    getIn(errors, formikFormName),
    Boolean(getIn(touched, formikFormName))
  )
  const getErrorMessage = useMemo(() => {
    if (formikError) {
      return t(formikError)
    }

    return ''
  }, [formikError, t])

  return (
    <div data-testid={`create-oauth-${formikFormName}`}>
      <Field
        title={title}
        type={inputType}
        name={formikFormName}
        data-testid={`create-oauth-${formikFormName}-input`}
        value={getIn(values, formikFormName) || ''}
        placeholder={placeholderText}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={supportText}
        errorText={getErrorMessage}
        hasError={
          Boolean(getIn(errors, formikFormName)) &&
          Boolean(getIn(touched, formikFormName))
        }
        required={required}
      />
    </div>
  )
}
