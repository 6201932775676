import {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

interface UsernameProps {
  disableOnBlur?: boolean
}

const Username: FunctionComponent<UsernameProps> = ({
  disableOnBlur = false
}) => {
  const { errors, handleBlur, setFieldValue, touched, values, validateField } =
    useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const [currentValue, setCurrentValue] = useState(values.username)

  useEffect(() => {
    setCurrentValue(values.username)
  }, [values.username])

  return (
    <div data-testid={`${values.type.toLowerCase()}-credentials-username`}>
      <Field
        title={t('fields.username.title')}
        name="username"
        data-testid={`${values.type.toLowerCase()}-credentials-username-input`}
        value={currentValue}
        placeholder={t('fields.username.placeholderText')}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setCurrentValue(e.target.value)
        }}
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          handleBlur(e)
          setFieldValue('username', e.target.value.trim())
          setCurrentValue(e.target.value.trim())
          validateField('username')
        }}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={t('fields.username.supportText')}
        errorText={
          errors?.username && touched.username ? t(errors.username) : null
        }
        hasError={Boolean(errors?.username) && Boolean(touched.username)}
        required
      />
    </div>
  )
}

export default Username
