import { updateEnvironment } from 'api/clients'
import { useClient } from 'api/hooks/useClient'
import { PUTEnvironmentRequestBody } from 'api/types'
import { AxiosError } from 'axios'
import { QueryKey } from 'constants/endpoint'
import { useMutation, UseMutationOptions } from 'react-query'

type TError = AxiosError<unknown>
interface TVariables {
  values: PUTEnvironmentRequestBody
  environmentId: string
}

type Options = UseMutationOptions<unknown, AxiosError, TVariables>

export const usePutEnvironment = (
  projectId: string,

  options?: Options
) => {
  const client = useClient()

  return useMutation<unknown, TError, TVariables>(
    [QueryKey.EDIT_ENVIRONMENT, projectId],
    async (data: TVariables) => {
      const { values, environmentId } = data
      return updateEnvironment({ projectId, environmentId }, values, client)
    },
    options
  )
}
