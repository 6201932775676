import { FunctionComponent } from 'react'
import { Button, Typography } from '@matillion/component-library'

interface NameCellProps {
  name: string
  onNameClick: VoidFunction
}

const NameCell: FunctionComponent<NameCellProps> = ({ name, onNameClick }) => (
  <Button
    alt="text"
    onClick={onNameClick}
    data-testid="secret-definition-name-cell-button"
  >
    <Typography format="bcl" as="span">
      {name}
    </Typography>
  </Button>
)

export default NameCell
