import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Header, HeaderTitle } from 'components/Header'
import classes from 'components/Form/Form.module.scss'
import {
  Description,
  ProjectName,
  Type
} from 'modules/FormsContent/AddNewProject/Fields'

interface AddNewProjectProps {
  onCancel?: () => void
  onPrevious?: () => void
  showCancel?: boolean
  showPrevious?: boolean
  modalContent?: string
}

const AddNewProject: FunctionComponent<AddNewProjectProps> = ({
  onCancel,
  onPrevious,
  showCancel = false,
  showPrevious = false,
  modalContent
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        onPrevious={onPrevious}
        showPrevious={showPrevious}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="add-new-project-title">
          {t('formContent.project.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <ProjectName />
        <Description />
        <Type />
      </div>
    </>
  )
}

export default AddNewProject
