import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  LoadingSpinner,
  SortState,
  Toaster,
  Typography
} from '@matillion/component-library'

import { useGetPermissions, useGetSecretReferences } from 'api/hooks'
import { queryClient } from 'api/queryClient'
import { GETSecretReferencesResponseParams } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'
import { sortByColumn } from 'modules/Secrets/utils'
import { PermissionsType, SecretReferenceTypes } from 'types'

import CloudProviderCredentialsDataGrid from './components/CloudProviderCredentialsDataGrid'
import ListingHeader from './components/ListingHeader'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'

export const CloudProviderCredentialsListing: FunctionComponent = () => {
  const { makeToast } = Toaster.useToaster()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [sortState, setSortState] = useState<
    Partial<SortState<GETSecretReferencesResponseParams>>
  >({ name: 'ASC' })

  const {
    data: cpcData = [],
    isError: isCPCError,
    isLoading: isCPCLoading
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS
  )

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.CreateSecrets)
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.ViewSecrets)

  const sortedCPCs = useMemo(
    () => sortByColumn(cpcData, sortState),
    [cpcData, sortState]
  )

  const redirectCallback = () =>
    navigate(AppRoutes.getCreateCloudProviderCredential(projectId!))

  const handleDelete = (name: string, isSuccess = true) => {
    if (isSuccess) {
      makeToast({
        title: t(
          'secrets.deleteCloudProviderCredential.responseMessage.success',
          {
            credentialName: name
          }
        ),
        message: '',
        type: 'success'
      })
    } else {
      makeToast({
        title: t(
          'secrets.deleteCloudProviderCredential.responseMessage.error',
          {
            credentialName: name
          }
        ),
        message: '',
        type: 'error'
      })
    }
  }

  const getContent = () => {
    return isCPCError ? (
      <Typography data-testid="cloud-provider-credentials-fail-support-text">
        {t('secrets.cloudProviderCredentialsListing.error')}
      </Typography>
    ) : (
      <CloudProviderCredentialsDataGrid
        credentials={sortedCPCs!}
        onSort={setSortState}
        onDeleteHandler={handleDelete}
      />
    )
  }

  useEffect(() => {
    sortByColumn(cpcData, sortState)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateSecrets
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteSecrets
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !cpcData.length &&
    !isCPCError &&
    !isCPCLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission?.result) {
      return <NoCreatePermission />
    } else {
      return <NoData onAdd={redirectCallback} />
    }
  }

  return (
    <>
      <ListingHeader
        onAdd={redirectCallback}
        permission={createPermission?.result}
      />
      {isCPCLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !cpcData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission?.result ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default CloudProviderCredentialsListing
