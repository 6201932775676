import { POSTSecretLocationRequest } from 'api/createProjectForm/types'
import { AgentsSecretsHost } from 'types'

interface FormValuesTypes {
  projectName: string
  projectConfiguration: AgentsSecretsHost
  etlAgent: {
    id: string
    name: string
  }
  matillionHostedAgentId: string
}

export const createPostSecretLocationMutationData = (
  formValues: FormValuesTypes
): POSTSecretLocationRequest => {
  if (formValues.projectConfiguration === AgentsSecretsHost.MatillionHosted) {
    return {
      projectName: formValues.projectName.trim(),
      agentId: formValues.matillionHostedAgentId,
      type: 'MATILLION_CUSTOMER_VAULT'
    }
  }

  return {
    projectName: formValues.projectName.trim(),
    agentId: formValues.etlAgent.id
  }
}
