import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { AutoComplete, Field } from '@matillion/component-library'

import { useGetEnvironmentAgents } from 'api/hooks'
import { renderFormikError } from 'modules/utils'
import { OAuthFormI } from 'types/FormTypes'
import classes from 'components/Form/Form.module.scss'

export const EnvironmentAgent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<OAuthFormI>()
  const { t } = useTranslation()

  const {
    data: listOfAgents = [],
    isError,
    isLoading
  } = useGetEnvironmentAgents()

  const formikError = renderFormikError(
    errors.environmentAgent,
    Boolean(touched.environmentAgent)
  )

  const agentsError = useMemo(() => {
    if (isError) {
      return t('secrets.createOAuth.fields.agent.error.loadingError')
    } else if (formikError) {
      return t(formikError)
    } else if (!listOfAgents.length) {
      return t('secrets.createOAuth.fields.agent.error.noItemsFound')
    }
    return ''
  }, [formikError, t, isError, listOfAgents])

  // TO-DO (SEPE-1269): Update translation reference when 'ETL agent' is replaced with 'Default agent'
  return (
    <div data-testid="create-oauth-environment-agent">
      <Field
        inputComponent={AutoComplete}
        availableItems={listOfAgents.map((item) => ({
          id: item.agentId,
          name: item.name
        }))}
        loading={isLoading}
        title={t('secrets.createOAuth.fields.agent.title')}
        name="environmentAgent"
        data-testid="create-oauth-environment-agent-input"
        value={values.environmentAgent}
        placeholder={
          isError
            ? t('secrets.createOAuth.fields.agent.error.placeholderText')
            : t('secrets.createOAuth.fields.agent.placeholderText')
        }
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('secrets.createOAuth.fields.agent.supportText')}
        errorText={isLoading ? undefined : agentsError}
        hasError={
          Boolean(errors?.environmentAgent?.id) &&
          Boolean(touched.environmentAgent) &&
          !isLoading
        }
        required
        disabled={isError || !listOfAgents.length}
      />
    </div>
  )
}
