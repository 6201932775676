export interface OAuthSchema {
  provider: string
  resourceId: string
  authTypes: OAuthType[]
}

export interface OAuthType {
  type: string
  resourceId: string
  scopes?: string[]
  applications?: OAuthApplication[]
  parameters?: OAuthParameter[]
  additionalParameters?: OAuthParameter[]
}

export interface OAuthApplication {
  resourceId: string
  id: string
  scopes: string[]
}

export interface OAuthParameter {
  resourceId: string
  id: string
  type: OAuthParameterType
  saveToSecret?: boolean
  sendToAuthServer?: boolean
  nullable?: boolean
  options?: OAuthParameterOption[]
  password?: boolean
}

export interface OAuthParameterOption {
  id: string
  resourceId: string
}
export type GETOAuthSchemaResponse = OAuthSchema[]

export enum OAuthParameterType {
  BadType = 'bad-type',
  Textbox = 'string',
  Dropdown = 'select-from-options'
}

// combine AdditionalParameters object into Parameters object
export const mutateGetOAuthSchemaResponse = (
  response: GETOAuthSchemaResponse
): GETOAuthSchemaResponse => {
  return response.map((schema) => {
    return {
      ...schema,
      authTypes: schema.authTypes.map((authType) => {
        const authT = {
          ...authType,
          parameters: [
            ...(authType.parameters ? authType.parameters : []),
            ...(authType.additionalParameters
              ? authType.additionalParameters
              : [])
          ]
        }

        delete authT.additionalParameters
        return authT
      })
    }
  })
}
