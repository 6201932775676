import React, { FunctionComponent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Loader, Toaster, Typography } from '@matillion/component-library'

import { useGetMha, useGetProject } from 'api/hooks'
import { Header, HeaderTitle } from 'components/Header'
import ExternalActionModal from 'components/Modal/ProjectExplorerModal'
import {
  AuthenticationType,
  EnvironmentAgent,
  Parameters
} from 'modules/FormsContent/CreateOAuth/Fields'
import { AgentsSecretsHost } from 'types'
import { OAuthFormI } from 'types/FormTypes'

import { Name, Provider } from './Fields'

import formClasses from 'components/Form/Form.module.scss'
import createOAuthClasses from 'modules/FormsContent/CreateOAuth/CreateOAuth.module.scss'
import { OAuthParameter } from 'api/types'
import { useCreateOAuthContext } from 'modules/Projects/CreateOAuth/CreateOAuthContext'

interface CreateOAuthProps {
  onFormCancel?: () => void
  onAwaitingCancel: () => void
  showFormCancel?: boolean
  showAwaitingModal?: boolean
}

const CreateOAuth: FunctionComponent<CreateOAuthProps> = ({
  onFormCancel,
  onAwaitingCancel,
  showFormCancel = true,
  showAwaitingModal = false
}) => {
  const { projectId } = useParams()
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<OAuthFormI>()
  const { selectedOAuth, setSelectedOAuth } = useCreateOAuthContext()
  const {
    data: projectData = {
      agentAndSecretHostLocation: AgentsSecretsHost.CustomerHosted
    },
    isLoading: isProjectLoading
  } = useGetProject(projectId!)
  const {
    data: mhaData = { agentId: '' },
    isError: isMhaError,
    isLoading: isMhaLoading
  } = useGetMha({
    enabled:
      projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
  })
  const { makeToast } = Toaster.useToaster()
  const [oAuthParameter, setOAuthParameter] = useState<OAuthParameter[]>()
  const modalContent = (
    <>
      {t('secrets.createOAuth.externalActionModal.content')}
      <Typography
        weight="bold"
        as="span"
        className={classNames(createOAuthClasses.ModalContent)}
      >
        {t('secrets.createOAuth.externalActionModal.contentBold')}
      </Typography>
    </>
  )

  useEffect(() => {
    if (
      !isProjectLoading &&
      projectData.agentAndSecretHostLocation ===
        AgentsSecretsHost.MatillionHosted
    ) {
      if (isMhaError) {
        makeToast({
          title: t('secrets.createOAuth.fields.agent.error.getMhaError'),
          message: '',
          type: 'error'
        })
      } else {
        setFieldValue('environmentAgent', {
          id: mhaData.agentId,
          name: mhaData.agentId
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMhaError, mhaData.agentId, projectData.agentAndSecretHostLocation])

  if (isProjectLoading) {
    return <Loader />
  }

  if (
    projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted &&
    isMhaLoading
  ) {
    return <Loader />
  }

  return (
    <>
      <Header
        onCancel={onFormCancel}
        showCancel={showFormCancel}
        modalContent={t('secrets.createOAuth.confirmCancelModal.content')}
      >
        <HeaderTitle data-testid="create-oauth-title">
          {t('secrets.createOAuth.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top']
        )}
      >
        <Name />
        {projectData.agentAndSecretHostLocation ===
          AgentsSecretsHost.CustomerHosted && <EnvironmentAgent />}
        <Provider
          setOAuthParameter={setOAuthParameter}
          setSelectedOAuth={setSelectedOAuth}
          oAuthParameter={oAuthParameter}
        />
        <AuthenticationType
          setOAuthParameter={setOAuthParameter}
          oAuthParameter={oAuthParameter}
          selectedOAuth={selectedOAuth}
        />
        <Parameters oAuthParameter={oAuthParameter} />
      </div>
      {showAwaitingModal && (
        <ExternalActionModal
          onCancel={onAwaitingCancel}
          onValidate={onAwaitingCancel}
          hideCancel
          heading={t('secrets.createOAuth.externalActionModal.heading')}
          content={modalContent}
          validate={t('secrets.createOAuth.externalActionModal.cancel')}
          disableBackdropClick
        />
      )}
    </>
  )
}

export default CreateOAuth
