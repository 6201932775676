import { getWarehouseDefaults } from 'api/createProjectForm/client'
import {
  GETWarehouseDefaultsRequest,
  GETWarehouseDefaultsResponse,
  WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { useClient } from 'api/hooks'
import { AxiosError } from 'axios'
import { QueryKey } from 'constants/endpoint'
import { useFormikContext } from 'formik'
import { useQuery, UseQueryOptions } from 'react-query'
import { AgentsSecretsHost } from 'types'
import {
  CommonFormI,
  EnvironmentDetailsI,
  RedshiftCredentialsI,
  RedshiftDefaultsI,
  SnowflakeCredentialsI,
  SnowflakeDefaultsI
} from 'types/FormTypes'

type TData = GETWarehouseDefaultsResponse
type TError = AxiosError<unknown>
type Options = Omit<
  UseQueryOptions<TData, TError, TData>,
  'queryKey' | 'queryFn'
>

export const useGetWarehouseDefaults = (
  lookupType: WarehouseLookupTypes,
  options?: Options
) => {
  const { values } = useFormikContext<
    CommonFormI &
      EnvironmentDetailsI &
      SnowflakeCredentialsI &
      SnowflakeDefaultsI &
      RedshiftCredentialsI &
      RedshiftDefaultsI
  >()
  const client = useClient()

  const optionsEnabled =
    options?.enabled === undefined ? true : options?.enabled

  const params: GETWarehouseDefaultsRequest = {
    agentId:
      values.projectConfiguration === AgentsSecretsHost.MatillionHosted
        ? values.matillionHostedAgentId
        : values.etlAgent?.id,
    username: values.username.trim(),
    account: values.account?.trim(),
    port: values.port,
    type: values.type,
    ssl: values.ssl,
    secretReferenceId: values.secretReferenceId,
    ...(lookupType !== WarehouseLookupTypes.ROLE && {
      role: values.defaultRole?.id || ''
    }),
    ...(lookupType === WarehouseLookupTypes.SCHEMA && {
      database: values.defaultDatabase?.id || ''
    })
  }

  return useQuery<TData, TError>(
    [
      QueryKey.WAREHOUSE_DEFAULTS,
      lookupType,
      // A new Secret ReferenceId is created every time however it does not impact the return values.
      // This helps eliminate unnecessary caching
      { ...params, secretReferenceId: '' }
    ],
    async () => {
      return getWarehouseDefaults(params, lookupType, client)
    },
    { ...options, enabled: optionsEnabled }
  )
}
