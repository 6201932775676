import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { Toaster } from '@matillion/component-library'

import { useGetBranch, useGetEnvironments, usePatchBranch } from 'api/hooks'
import { editBranchMutationData } from 'api/mutation'

import { formSchema, FormValues, initialValues } from './EditBranchForm.util'
import { Environment, Name } from './Fields'
import Form from 'components/Form'
import { EDIT_BRANCH } from 'constants/persistance'
import { Header, HeaderTitle } from 'components/Header'
import { GETBranchResponse } from 'api/types'
import classNames from 'classnames'
import formClasses from 'components/Form/Form.module.scss'
import { QueryKey } from 'constants/endpoint'
import { useQueryClient } from 'react-query'
import { AppRoutes } from 'constants/route'

const EditBranch: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId, branchId } = useParams()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { mutateAsync } = usePatchBranch(projectId!, branchId!)
  const { data: environmentsData = [] } = useGetEnvironments(projectId!)
  const { makeToast, clearToasts } = Toaster.useToaster()
  const undefinedBranch: GETBranchResponse = {
    id: '',
    name: '',
    defaultEnvironmentName: '',
    defaultEnvironmentId: ''
  }

  const { data: defaultBranch = undefinedBranch } = useGetBranch(
    projectId!,
    branchId!
  )

  const { defaultEnvironmentName, name: branchName } = defaultBranch
  let defaultValues = initialValues

  const defaultEnvironment = environmentsData.find(
    (env) => env.name === defaultEnvironmentName
  )

  if (defaultEnvironment) {
    defaultValues = {
      branchName: branchName,
      defaultEnvironment: {
        id: defaultEnvironment.id,
        name: defaultEnvironment.name
      }
    }
  }

  const handleSubmitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    clearToasts()
    mutateAsync({ values: editBranchMutationData(values) })
      .then((_response) => {
        makeToast({
          title: t('editBranch.responseMessage.success', {
            branchName
          }),

          message: '',
          type: 'success'
        })
        queryClient.invalidateQueries(QueryKey.BRANCHES_LIST)
        queryClient.removeQueries([
          QueryKey.PROJECT_DETAIL,
          projectId,
          branchId
        ])
        onCancel()
      })
      .catch((_error) => {
        makeToast({
          title: t('editBranch.responseMessage.error'),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  const onCancel = () => {
    navigate(AppRoutes.getBranchListing(projectId!))
  }

  return (
    <Form<FormValues>
      formikValues={{
        initialValues: defaultValues,
        initialTouched: false,
        validationSchema: formSchema,
        onSubmit: handleSubmitForm,
        validateOnMount: true,
        enableReinitialize: true
      }}
      translationPrefix="editBranch"
      persistingStorageId={EDIT_BRANCH}
    >
      <>
        <Header onCancel={onCancel} showCancel>
          <HeaderTitle data-testid="edit-branch-title">
            {t('editBranch.heading')}
          </HeaderTitle>
        </Header>
        <div
          className={classNames(
            formClasses.Form__InnerWrap,
            formClasses['Form__InnerWrap--space-top']
          )}
        >
          <Name />
          <Environment availableEnvironments={environmentsData} />
        </div>
      </>
    </Form>
  )
}

export default EditBranch
