import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Agent, Environment, Pipeline, ScheduleName } from './Fields'
import { Header, HeaderTitle } from 'components/Header'
import classes from 'modules/FormsContent/Form.module.scss'
import scheduleClasses from './CreateSchedule.module.scss'
import {
  CronExpression,
  StartTime,
  Timezone
} from 'modules/Projects/CreateSchedule/FormContent/Fields/AdvancedSchedule'
import { Icon, Typography } from '@matillion/component-library'
import { useGetProject } from 'api/hooks'
import { useParams } from 'react-router-dom'
import { AgentsSecretsHost } from 'types'

interface CreateScheduleContentProps {
  onCancel?: () => void
  showCancel?: boolean
  modalContent?: string
}

const CreateScheduleContent: FunctionComponent<CreateScheduleContentProps> = ({
  onCancel,
  modalContent,
  showCancel = false
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data } = useGetProject(projectId!)

  return (
    <>
      <Header
        onCancel={onCancel}
        showCancel={showCancel}
        modalContent={modalContent}
      >
        <HeaderTitle data-testid="create-schedule-title">
          {t('createSchedule.title')}
        </HeaderTitle>
        <Typography as="p" data-testid="create-schedule-support-text">
          {t('createSchedule.supportText')}
        </Typography>
      </Header>
      <div className={classNames(classes.Form)}>
        <ScheduleName />
        <Environment />
        <Pipeline />
        {data?.agentAndSecretHostLocation !==
          AgentsSecretsHost.MatillionHosted && <Agent />}
        <Timezone />
        <StartTime />
        <CronExpression />
        <div className={classNames(classes.Form__Field)}>
          <div
            data-testid="create-schedule-helper-text"
            className={classNames(scheduleClasses.HelpText)}
          >
            <Icon.Info className={scheduleClasses.HelpText__Icon} />
            <Typography format="bcs">{t('createSchedule.helpText')}</Typography>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateScheduleContent
