import { FunctionComponent } from 'react'
import classNames from 'classnames'
import {
  Name,
  Description,
  Agent,
  SecretName,
  SecretKey,
  SecretValue
} from './Fields'
import { Header, HeaderTitle } from 'components/Header'
import formClasses from 'components/Form/Form.module.scss'
import { useTranslation } from 'react-i18next'

interface SecretDefinitionFormContentProps {
  isMHA?: boolean
  locationId: string
  onCancel?: () => void
  showCancel?: boolean
}

const SecretDefinitionFormContent: FunctionComponent<
  SecretDefinitionFormContentProps
> = ({ isMHA = false, locationId, onCancel, showCancel }) => {
  const { t } = useTranslation()

  return (
    <>
      <Header onCancel={onCancel} showCancel={showCancel}>
        <HeaderTitle data-testid="create-secret-definition">
          {t('secrets.createSecretDefinition.title')}
        </HeaderTitle>
      </Header>
      <div
        className={classNames(
          formClasses.Form__InnerWrap,
          formClasses['Form__InnerWrap--space-top']
        )}
      >
        <Name />
        <Description />
        {isMHA ? (
          <SecretValue />
        ) : (
          <>
            <Agent />
            <SecretName locationId={locationId} />
            <SecretKey locationId={locationId} />
          </>
        )}
      </div>
    </>
  )
}

export default SecretDefinitionFormContent
