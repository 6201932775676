import { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon, Typography } from '@matillion/component-library'

import classes from './ReturnLink.module.scss'

interface ReturnLinkProps {
  text: string
  onClick?: () => void
}

export const ReturnLink: FunctionComponent<ReturnLinkProps> = ({
  text,
  onClick
}) => {
  const navigate = useNavigate()
  const clickEvent = onClick ?? (() => navigate(-1))

  return (
    <button
      type="button"
      data-testid="previous-page"
      className={classes.ReturnLink}
      onClick={clickEvent}
    >
      <Icon.ArrowLeft />
      <Typography format="bcs" className={classes.ReturnLink__Text}>
        {text}
      </Typography>
    </button>
  )
}
