import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import Form from 'components/Form'
import { ONBOARDING_STORAGE_ITEM } from 'constants/persistance'
import {
  AppRoutes,
  PROJECTS_CREATE_AGENT,
  PROVISIONING_TRIAL_WAREHOUSE,
  WAREHOUSE
} from 'constants/route'
import OnboardingRoutes from 'modules/Onboarding/OnboardingRoutes'

import { useOnboardingSubmit, useValidationSchema } from './hooks'
import { initialValues, OnboardingFormikValueTypes } from './'

import classes from 'modules/FormsContent/WarehouseProvisioningSelection/WarehouseProvisioningSelection.module.scss'

const OnboardingForm = () => {
  const location = useLocation()
  const { submitForm, isLastStep, nextStep, progress, stepIndex, steps } =
    useOnboardingSubmit()

  const validationSchema = useValidationSchema(steps)

  return (
    <Form<OnboardingFormikValueTypes>
      formikValues={{
        onSubmit: submitForm,
        initialValues: initialValues(),
        validationSchema,
        initialTouched: false
      }}
      translationPrefix="createProject"
      stepInfo={{ isLastStep, stepIndex, nextStep, progress }}
      persistingStorageId={ONBOARDING_STORAGE_ITEM}
      persistenceExclusions={['secretValue']}
      removeSubmitButtonOnRoutes={[
        PROJECTS_CREATE_AGENT,
        PROVISIONING_TRIAL_WAREHOUSE
      ]}
      buttonsClassName={classNames({
        [classes.WarehouseProvisioningSelection__ButtonContainer]:
          location.pathname.includes(
            `${AppRoutes.getOnboardingAdd()}/${WAREHOUSE}`
          )
      })}
    >
      <OnboardingRoutes />
    </Form>
  )
}

export default OnboardingForm
