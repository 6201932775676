import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { isValidCronExpression } from 'cron-expression-validator'
import cronstrue from 'cronstrue'
import classNames from 'classnames'

import { Field, Typography } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { renderFormikError } from 'modules/utils'
import { FormValues } from 'modules/Projects/CreateSchedule/types'

const CronExpression: FunctionComponent = () => {
  const { t } = useTranslation()
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<FormValues>()

  const cronError = renderFormikError(
    errors.cronExpression,
    Boolean(touched.cronExpression)
  )

  return (
    <div
      data-testid="create-schedule-cron-expression"
      className={classes.Form__Field}
    >
      <Field
        title={t('createSchedule.fields.cronExpression.title')}
        data-testid="create-schedule-cron-expression-input"
        name="cronExpression"
        supportText={t('createSchedule.fields.cronExpression.supportText')}
        value={values.cronExpression}
        placeholder={t('createSchedule.fields.cronExpression.placeholderText')}
        className={classNames(classes.Form__Field)}
        onChange={handleChange}
        onBlur={handleBlur}
        errorText={cronError ? t(cronError) : null}
        hasError={
          Boolean(errors?.cronExpression) && Boolean(touched.cronExpression)
        }
        required
      />
      <div
        className={classes.Form__SupportingText}
        data-testid="cron-text-output"
      >
        {isValidCronExpression(values.cronExpression) && (
          <Typography weight="bold">
            {'"'}
            {cronstrue.toString(values.cronExpression, {
              use24HourTimeFormat: true
            })}
            {'"'}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default CronExpression
