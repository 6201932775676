import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import {
  POSTSecretLocationResponse,
  POSTSecretLocationRequest
} from 'api/createProjectForm/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from 'api/hooks'
import { postSecretLocation } from 'api/createProjectForm/client'

type TData = POSTSecretLocationResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTSecretLocationRequest
}

type Options = UseMutationOptions<
  POSTSecretLocationResponse,
  AxiosError,
  TVariables
>

export const usePostSecretLocation = (options?: Options) => {
  const client = useClient()

  return useMutation<TData, TError, TVariables>(
    [QueryKey.SECRET_LOCATION],
    async (data: { values: POSTSecretLocationRequest }) => {
      const { values } = data
      return postSecretLocation(values, client)
    },
    options
  )
}
