import { AxiosError } from 'axios'
import { To, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FormikHelpers } from 'formik'
import { Toaster } from '@matillion/component-library'

import { WarehouseLookupTypes } from 'api/createProjectForm/types'
import {
  useMutateDatabricksComputeResources,
  useMutateWarehouseDefaults,
  usePostSecretValueV2
} from 'api/hooks'
import { ErrorResponse } from 'api/types'
import {
  createSecretValueV2MutationData,
  createWarehouseDefaultsMutation
} from 'api/mutation'

import { useFormNavigation } from 'hooks'

import {
  PROJECTS_CREATE_AGENT,
  PROJECTS_DEFAULTS,
  PROVISIONING_TRIAL_WAREHOUSE
} from 'constants/route'
import { useOnboardingContext } from 'context'
import { useMarketo } from 'marketo/hooks'
import { OnboardingFormikValueTypes } from 'modules/Onboarding/OnboardingForm'
import {
  useFinishOnboarding,
  useOnboardingSteps
} from 'modules/Onboarding/OnboardingForm/hooks'
import { Warehouse } from 'types'

export const useOnboardingSubmit = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [callToMarketo] = useMarketo()
  const { makeToast, clearToasts } = Toaster.useToaster()
  const { mutateAsync: mutateAsyncSecretValueV2 } = usePostSecretValueV2()
  const { mutateAsync: mutateAsyncWarehouseRoleLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)
  const { mutateAsync: mutateAsyncWarehouseComputeResourceLookup } =
    useMutateDatabricksComputeResources()
  const { mutateAsync: mutateAsyncWarehouseDatabaseLookup } =
    useMutateWarehouseDefaults(WarehouseLookupTypes.DATABASE)

  const { setProjectType, setSecretReferenceId, isTrialWarehouse } =
    useOnboardingContext()
  const { mutateProject: finishOnboarding } = useFinishOnboarding()

  const steps = useOnboardingSteps(isTrialWarehouse)

  const formNavigation = useFormNavigation(steps)
  const { isLastStep, nextStep } = formNavigation

  const submitForm = async (
    newValues: OnboardingFormikValueTypes,
    { resetForm, setSubmitting }: FormikHelpers<OnboardingFormikValueTypes>
  ) => {
    clearToasts()
    setSubmitting(true)

    if (isLastStep) {
      await finishOnboarding(newValues)
    } else {
      try {
        if (nextStep === PROJECTS_DEFAULTS) {
          const response = await mutateAsyncSecretValueV2({
            values: createSecretValueV2MutationData({
              ...newValues,
              secretValue: {
                password: newValues.secretValue
              },
              name: newValues.environmentName
            })
          })

          setSecretReferenceId(response.secretId)
          newValues = {
            ...newValues,
            secretReferenceId: response.secretId
          }

          // Ensure that there is a connection to warehouse defaults role in order to continue
          // Otherwise it throws an error which is caught below and does not proceed to the next page
          switch (newValues.type) {
            case Warehouse.Snowflake:
              await mutateAsyncWarehouseRoleLookup({
                values: createWarehouseDefaultsMutation({
                  ...newValues,
                  etlAgent: {
                    id: '',
                    name: ''
                  }
                })
              })
              break
            case Warehouse.Redshift:
              await mutateAsyncWarehouseDatabaseLookup({
                values: createWarehouseDefaultsMutation({
                  ...newValues,
                  etlAgent: {
                    id: '',
                    name: ''
                  }
                })
              })
              break
            case Warehouse.Databricks:
              await mutateAsyncWarehouseComputeResourceLookup({
                values: createWarehouseDefaultsMutation({
                  ...newValues,
                  etlAgent: {
                    id: '',
                    name: ''
                  }
                })
              })
              break
          }
        } else if (nextStep === PROVISIONING_TRIAL_WAREHOUSE) {
          callToMarketo('clickLink', '/onboarding/matillion-trial-warehouse')
        } else if (nextStep === PROJECTS_CREATE_AGENT) {
          callToMarketo('clickLink', '/onboarding/customer-own-warehouse')
        }

        navigate(nextStep as To, { replace: true })
      } catch (error: unknown) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const err = error as AxiosError<any>

        const errorDetail = (err?.response?.data as ErrorResponse)?.detail

        makeToast({
          title: t('error.unexpected.title'),
          message: errorDetail ?? t('error.unexpected.message'),
          type: 'error'
        })
      } finally {
        setProjectType(newValues.type)
        // Resetting form with the new user-inputted field defaults to reset validation within the wizard
        resetForm({ values: newValues })
      }
    }
  }

  return {
    submitForm,
    steps,
    ...formNavigation
  }
}
