import { FunctionComponent } from 'react'
import { CreateEnvironmentProvider } from 'context'
import CreateEnvironmentForm from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm'

const CreateEnvironment: FunctionComponent = () => (
  <CreateEnvironmentProvider>
    <CreateEnvironmentForm />
  </CreateEnvironmentProvider>
)

export default CreateEnvironment
