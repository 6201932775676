import {
  GETSecretKeyRequest,
  GETSecretKeyResponse,
  GETSecretNameRequest,
  GETSecretNameResponse,
  GETWarehouseDefaultsRequest,
  GETWarehouseDefaultsResponse,
  POSTSecretLocationRequest,
  POSTSecretLocationResponse,
  WarehouseLookupTypes
} from 'api/createProjectForm/types'
import { POSTProjectRequest, POSTProjectResponse } from 'api/types'
import { AxiosInstance } from 'axios'

import { mockAxios } from '__mocks__/axiosMock'
import { GETDatabricksComputeResourceRequest } from 'api/createProjectForm/types/DatabricksComputeResourcesRequest'
import { GETDatabricksComputeResourcesResponse } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'

export const getSecretName = async (
  params: GETSecretNameRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretNameResponse> => {
  const { agentId, secretLocationId } = params

  const path = `/v1/gateway/srs/agents/${encodeURIComponent(
    agentId
  )}/location/${encodeURIComponent(secretLocationId)}/secrets`

  const { data } = await client.get(path)

  return data
}

export const getSecretKey = async (
  params: GETSecretKeyRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETSecretKeyResponse> => {
  const { agentId, secretLocationId, secretName } = params

  const path = `/v1/gateway/srs/agents/${encodeURIComponent(
    agentId
  )}/location/${encodeURIComponent(
    secretLocationId
  )}/keys?secretName=${secretName}`

  const { data } = await client.get(path)

  return data
}

export const postSecretLocation = async (
  values: POSTSecretLocationRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTSecretLocationResponse> => {
  const path = '/v1/gateway/srs/secret-location'
  const { data } = await client.post(path, values)

  return data
}

export const getWarehouseDefaults = async (
  params: GETWarehouseDefaultsRequest,
  lookupType: WarehouseLookupTypes,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETWarehouseDefaultsResponse> => {
  const path = `/v1/gateway/warehouse-defaults/lookup/${lookupType}`
  const { data } = await client.get(path, { params })
  return data
}

export const getDatabricksComputeResources = async (
  params: GETDatabricksComputeResourceRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<GETDatabricksComputeResourcesResponse> => {
  const path = '/v1/gateway/warehouse-defaults/databricks/lookup/compute'
  const { data } = await client.get(path, { params })

  return data
}

export const postProject = async (
  values: POSTProjectRequest,
  /* istanbul ignore next */
  client: AxiosInstance = mockAxios
): Promise<POSTProjectResponse> => {
  const path = '/v1/projects/initialise'
  const { data } = await client.post(path, values)
  return data
}
