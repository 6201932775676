import { object, string } from 'yup'
import { OptionalObjectSchema } from 'yup/lib/object'
import { useParams } from 'react-router-dom'

import { useGetSecretReferences } from 'api/hooks'
import { SecretReferenceTypes } from 'types'
import { useMemo } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useValidationSchema = (): OptionalObjectSchema<any> => {
  const { projectId } = useParams()
  const { data: availableOAuths } = useGetSecretReferences(projectId!, [
    SecretReferenceTypes.OAUTH_USER_TOKENS,
    SecretReferenceTypes.OAUTH_CLIENT_CRED
  ])

  const validationSchema = useMemo(() => {
    return object({
      name: string()
        .required('secrets.createOAuth.fields.name.error.required')
        .test(
          'oauthName',
          'secrets.createOAuth.fields.name.error.notUnique',
          (value) => {
            if (!availableOAuths?.length) {
              return true
            }
            return (
              availableOAuths.findIndex((oauth) => oauth.name === value) === -1
            )
          }
        )
        .test(
          'oauthName',
          'secrets.createOAuth.fields.name.error.noSpaces',
          (value) => {
            if (value?.includes(' ')) {
              return false
            }
            return true
          }
        ),
      provider: object({
        id: string().required(
          'secrets.createOAuth.fields.provider.error.required'
        ),
        name: string().required(
          'secrets.createOAuth.fields.provider.error.required'
        )
      })
        .required('secrets.createOAuth.fields.provider.error.unmatched')
        .nullable(),
      environmentAgent: object({
        id: string().required(
          'secrets.createOAuth.fields.agent.error.required'
        ),
        name: string().required(
          'secrets.createOAuth.fields.agent.error.required'
        )
      })
        .required('secrets.createOAuth.fields.agent.error.unmatched')
        .nullable(),
      authType: object({
        id: string().required(
          'secrets.createOAuth.fields.authType.error.required'
        ),
        name: string().required(
          'secrets.createOAuth.fields.authType.error.required'
        )
      })
        .required('secrets.createOAuth.fields.authType.error.unmatched')
        .nullable()
    })
  }, [availableOAuths])

  return validationSchema
}
