import { OptionalObjectSchema } from 'yup/lib/object'
import { object } from 'yup'

import { useOnboardingContext } from 'context'
import { useFormNavigation } from 'hooks'
import { warehouseValuesSchema } from 'modules/Projects/CreateProject/CreateProjectForm'
import {
  OnboardingFormNavigationSteps,
  OnboardingFormNavigationStepsType,
  warehouseProvisioningValidationSchema
} from 'modules/Onboarding/OnboardingForm'

export const useValidationSchema = (
  steps: OnboardingFormNavigationStepsType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): OptionalObjectSchema<any> => {
  const { currentStep } =
    useFormNavigation<OnboardingFormNavigationSteps>(steps)
  const { projectType } = useOnboardingContext()

  const warehouseSchema = warehouseValuesSchema(projectType)

  const valuesSchema = {
    ...warehouseProvisioningValidationSchema,
    ...warehouseSchema
  }

  return currentStep ? valuesSchema[currentStep] : object({})
}
