import { noop } from 'lodash'
import React, { createContext, SetStateAction, useContext } from 'react'

import { OAuthSchema } from 'api/types'
import { GenericSchemaType } from 'types'

interface CreateOAuthContextType {
  validationSchema?: GenericSchemaType
  setValidationSchema: (schema: GenericSchemaType) => void
  selectedOAuth?: OAuthSchema
  setSelectedOAuth: React.Dispatch<SetStateAction<OAuthSchema | undefined>>
}

export const CreateOAuthContext = createContext<CreateOAuthContextType>({
  validationSchema: undefined,
  setValidationSchema: noop,
  selectedOAuth: undefined,
  setSelectedOAuth: noop
})

export const useCreateOAuthContext = (context = CreateOAuthContext) =>
  useContext(context)
