import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Toaster } from '@matillion/component-library'

import {
  useGetMha,
  useGetProject,
  useGetSchedules,
  usePostSchedule
} from 'api/hooks'
import { createScheduleMutationData } from 'api/mutation'
import { AppRoutes, PROJECT_DETAILS_SCHEDULES } from 'constants/route'
import { AgentsSecretsHost } from 'types'
import { isMHARunning } from 'modules/utils'
import Form from 'components/Form'
import { CREATE_SCHEDULE } from 'constants/persistance'
import { initialValues } from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent.util'
import { validationSchema } from 'modules/Projects/CreateSchedule/FormContent'
import CreateScheduleContent from 'modules/Projects/CreateSchedule/FormContent/CreateScheduleContent'
import { FormValues } from 'modules/Projects/CreateSchedule/types'

const CreateScheduleForm: FunctionComponent = () => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { refetch } = useGetSchedules(projectId!)
  const {
    data: projectData = {
      agentAndSecretHostLocation: AgentsSecretsHost.CustomerHosted
    }
  } = useGetProject(projectId!)
  const [scheduleInitialValues, setScheduleInitialValues] =
    useState(initialValues)
  const {
    data: mhaData = { status: undefined, agentId: undefined },
    isError: mhaError,
    isLoading: mhaLoading
  } = useGetMha()

  const { mutateAsync: mutateAsyncSchedule } = usePostSchedule(projectId!)
  const { makeToast, clearToasts } = Toaster.useToaster()

  useEffect(() => {
    if (
      projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
    ) {
      const status = mhaData?.status
      if (isMHARunning(status) && mhaData.agentId) {
        const matillionHostedAgentId = mhaData.agentId
        setScheduleInitialValues({
          ...initialValues,
          agent: {
            id: matillionHostedAgentId,
            name: 'Matillion Hosted Agent'
          }
        })
      }

      if (!isMHARunning(status) && !mhaLoading) {
        makeToast({
          title: t('createSchedule.error.mhaNotReady'),
          message: '',
          type: 'error'
        })
      }
    }
  }, [
    mhaData,
    projectData.agentAndSecretHostLocation,
    mhaLoading,
    makeToast,
    t
  ])
  useEffect(() => {
    if (
      projectData.agentAndSecretHostLocation ===
      AgentsSecretsHost.MatillionHosted
    ) {
      if (mhaError) {
        makeToast({
          title: t('createSchedule.error.getMhaError'),
          message: '',
          type: 'error'
        })
      }
    }
  }, [projectData.agentAndSecretHostLocation, mhaError, makeToast, t])

  const handleOnSubmit = () => {
    refetch()
    return navigate(
      AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES),
      {
        replace: true
      }
    )
  }

  const publishSchedule = async (
    values: FormValues,
    onPublishStatus: (success: boolean, scheduleName?: string) => void,
    onFinish: () => void
  ) =>
    mutateAsyncSchedule({
      values: createScheduleMutationData(values)
    })
      .then((_res) => {
        onPublishStatus(true, values.name)
      })
      .catch((_res) => {
        onPublishStatus(false)
      })
      .finally(onFinish)

  const submitForm = async (newValues: FormValues) => {
    clearToasts()

    await publishSchedule(
      newValues,
      (success, scheduleName) => {
        if (success) {
          makeToast({
            title: t('createSchedule.responseMessage.success', {
              scheduleName
            }),
            message: '',
            type: 'success'
          })

          return handleOnSubmit()
        }

        makeToast({
          title: t('createSchedule.responseMessage.error'),
          message: '',
          type: 'error'
        })
      },
      () => null
    )
  }

  const handleOnCancel = () => {
    navigate(AppRoutes.getProjectDetails(projectId!, PROJECT_DETAILS_SCHEDULES))
  }

  return (
    <Form<FormValues>
      formikValues={{
        initialValues: scheduleInitialValues,
        initialTouched: false,
        validationSchema: validationSchema,
        onSubmit: submitForm,
        validateOnMount: true,
        enableReinitialize: true
      }}
      translationPrefix="createSchedule"
      persistingStorageId={CREATE_SCHEDULE}
    >
      <CreateScheduleContent
        showCancel
        onCancel={handleOnCancel}
        modalContent={t('createSchedule.modal.content')}
      />
    </Form>
  )
}

export default CreateScheduleForm
