import { OptionalObjectSchema } from 'yup/lib/object'
import { object } from 'yup'
import { AnyObject } from 'yup/lib/types'

import { useGetProjects } from 'api/hooks'
import { useCreateProjectsContext } from 'context'
import { useFormNavigation } from 'hooks'
import {
  CreateFormNavigationSteps,
  getGenericValuesSchemaMap,
  useSteps,
  warehouseValuesSchema
} from 'modules/Projects/CreateProject/CreateProjectForm'
import { HostType } from 'types'

export const useValidationSchema = (
  hostType: HostType
): OptionalObjectSchema<AnyObject> => {
  const { projectType } = useCreateProjectsContext()
  const { data: projects } = useGetProjects()
  const steps = useSteps({ hostType })
  const { currentStep } = useFormNavigation<CreateFormNavigationSteps>(steps)

  const warehouseSchema = warehouseValuesSchema(projectType)

  const valuesSchema = {
    ...getGenericValuesSchemaMap(projects?.map((x) => x.name) ?? []),
    ...warehouseSchema
  }
  return currentStep ? valuesSchema[currentStep] : object({})
}
