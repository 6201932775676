import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import {
  Button,
  DataGrid,
  LoadingSpinner,
  SortState,
  Toaster,
  Typography
} from '@matillion/component-library'

import {
  useGetEnvironments,
  useGetPermissions,
  useNetworkErrorToast
} from 'api/hooks'
import { queryClient } from 'api/queryClient'
import { GETEnvironmentsResponseParams } from 'api/types'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'
import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'
import { PermissionsType } from 'types'

import { ColumnKeys, SortedEnvironmentsTypes, SortTypes } from '.'
import ActionCell from './ActionCell'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import { environmentsSort } from './environmentsListing.util'

import classes from './EnvironmentsListing.module.scss'

const EnvironmentsListing: FunctionComponent = () => {
  const [sortState, setSortState] = useState<Partial<SortState<SortTypes>>>({
    name: 'ASC'
  })
  const { projectId } = useParams()
  const navigate = useNavigate()
  const makeErrorToast = useNetworkErrorToast()
  const { clearToasts } = Toaster.useToaster()
  const { t } = useTranslation()

  const {
    data: environmentsData = [],
    error: environmentsError,
    isError: isEnvironmentsError,
    isLoading: isEnvironmentsLoading
  } = useGetEnvironments(projectId!)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.CreateEnvironments)
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.ViewEnvironments)

  if (sortState) {
    const environmentsSortFunction = environmentsSort(sortState)
    environmentsData.sort(environmentsSortFunction)
  }

  const goToEditEnvironment = (envId: string) => {
    clearToasts()
    navigate(AppRoutes.getEnvironmentEdit(projectId!, envId))
  }

  const goToAssociateCredentials = (envId: string) => {
    clearToasts()
    navigate(AppRoutes.getAssociateCredentialsToEnvironment(projectId!, envId))
  }
  useEffect(() => {
    if (isEnvironmentsError) {
      makeErrorToast({ message: environmentsError.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentsError, isEnvironmentsError])

  useEffect(() => {
    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateEnvironments
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.EditEnvironments
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getContent = () => {
    return !environmentsData.length ? (
      <div
        className={classNames(classes.EnvironmentsListing__EmptyInfo)}
        data-testid="empty-info-message"
      >
        {isEnvironmentsError ? (
          <Typography format="bcm">{t('environmentsListing.error')}</Typography>
        ) : (
          <>{!createPermission?.result ? <NoCreatePermission /> : <NoData />}</>
        )}
      </div>
    ) : (
      <DataGrid
        className={classNames(classes.EnvironmentsListing__Grid)}
        rowClassName={classNames(classes.EnvironmentsListing__GridRow)}
        data-testid="environments-data-grid"
        aria-label="Environments Data Grid"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.environmentName,
            title: t('environmentsListing.column.col1'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => ({
              children: row.name
            }),
            sortable: true
          },
          {
            key: ColumnKeys.defaultAgent,
            title: t('environmentsListing.column.col2'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => ({
              children: row.agentName
            }),
            sortable: true
          },
          {
            key: ColumnKeys.cdwAccountName,
            title: t('environmentsListing.column.col3'),
            as: Typography,
            className: classNames(classes.EnvironmentsListing__GridCell),
            mapValues: (row) => {
              // TO-DO: May need to revisit this in the future if the BE returns the 'account name' separately and not only within the full 'warehouse connection' URL
              const accountName = row.warehouseConnection.url
                .replace(/^http(s?):\/\//, '')
                .split('.')
                .shift()
              return {
                children: accountName
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.action,
            title: t('environmentsListing.column.col4'),
            as: ActionCell,
            className: classNames(classes.EnvironmentsListing__ActionCell),
            mapValues: (row: GETEnvironmentsResponseParams) => ({
              onEditClick: () => goToEditEnvironment(row.id),
              onAssociateCredsClick: () => goToAssociateCredentials(row.id),
              item: row
            }),
            sortable: false
          }
        ]}
        rows={environmentsData as SortedEnvironmentsTypes[]}
        onSort={setSortState}
      />
    )
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="environments-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            withBottomSpace={false}
          >
            {t('environmentsListing.title')}
          </HeaderTitle>
          {createPermission?.result && (
            <Button
              text={t('environmentsListing.buttonText')}
              onClick={() => navigate(AppRoutes.getEnvironmentAdd(projectId!))}
              data-testid="add-environment-button"
              alt="positive"
            />
          )}
        </HeaderWithCTA>
      </Header>
      {isEnvironmentsLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !environmentsData.length) ? (
        <LoadingSpinner />
      ) : (
        <>{!viewPermission?.result ? <NoReadPermission /> : getContent()}</>
      )}
    </>
  )
}

export default EnvironmentsListing
