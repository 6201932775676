import { useFormikContext } from 'formik'
import { Warehouse as CommonWarehouse } from 'modules/Common/Fields'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

const Warehouse = () => {
  const { values } = useFormikContext<CreateProjectFormikValueTypes>()
  const isFieldEnabled = !!values.defaultRole?.id

  return (
    <CommonWarehouse
      listenerField="defaultRole"
      isFieldEnabled={isFieldEnabled}
    />
  )
}

export default Warehouse
