import { object, string } from 'yup'

import {
  PROJECTS_CREATE_ENVIRONMENT,
  PROJECTS_CREDENTIALS,
  PROJECTS_DEFAULTS
} from 'constants/route'

import {
  alphaNumericMildCharsWithLeadEndSpacesRegEx,
  dynamicValuesSchema as existingDynamicValuesSchema
} from 'modules/Projects/CreateProject/CreateProjectForm'
import { AgentsSecretsHost } from 'types'
import {
  CreateEnvironmentFormikValueTypes,
  CreateEnvironmentSteps
} from 'modules/Projects/CreateEnvironment/CreateEnvironmentForm/types'

export type CreateEnvironmentStepsType = Record<
  CreateEnvironmentSteps,
  {
    previousStep?: CreateEnvironmentSteps
    currentStep: CreateEnvironmentSteps
    nextStep?: CreateEnvironmentSteps
  }
>

export const steps: CreateEnvironmentStepsType = {
  [PROJECTS_CREATE_ENVIRONMENT]: {
    previousStep: undefined,
    currentStep: PROJECTS_CREATE_ENVIRONMENT,
    nextStep: PROJECTS_CREDENTIALS
  },
  [PROJECTS_CREDENTIALS]: {
    previousStep: PROJECTS_CREATE_ENVIRONMENT,
    currentStep: PROJECTS_CREDENTIALS,
    nextStep: PROJECTS_DEFAULTS
  },
  [PROJECTS_DEFAULTS]: {
    previousStep: PROJECTS_CREDENTIALS,
    currentStep: PROJECTS_DEFAULTS,
    nextStep: undefined
  }
}

export const alphaNumericMildCharsRegEx = /^([\w()-]\s?)+$/

export const initialValues: CreateEnvironmentFormikValueTypes = {
  account: '',
  defaultRole: {
    id: '',
    name: ''
  },
  defaultWarehouse: {
    id: '',
    name: ''
  },
  defaultDatabase: {
    id: '',
    name: ''
  },
  defaultSchema: {
    id: '',
    name: ''
  },
  environmentName: '',
  etlAgent: {
    id: '',
    name: ''
  },
  matillionHostedAgentId: '',
  secretName: {
    id: '',
    name: ''
  },
  secretKey: {
    id: '',
    name: ''
  },
  secretValue: '',
  secretLocationId: '',
  secretReferenceId: '',
  username: '',
  projectConfiguration: AgentsSecretsHost.CustomerHosted,
  port: null,
  ssl: false,

  type: '',
  compute: {
    id: '',
    name: '',
    clusterId: '',
    clusterName: '',
    clusterType: ''
  },
  catalog: {
    id: '',
    name: ''
  }
}

export const genericValuesSchema = {
  [PROJECTS_CREATE_ENVIRONMENT]: object({
    environmentName: string()
      .required('fields.environmentName.error.required')
      .matches(
        alphaNumericMildCharsWithLeadEndSpacesRegEx,
        'fields.environmentName.error.regEx'
      ),
    etlAgent: object({
      id: string().required('fields.etlAgent.error.required'),
      name: string().required('fields.etlAgent.error.required')
    })
      .when('projectConfiguration', {
        is: AgentsSecretsHost.MatillionHosted,
        then: (_existingSchema) => {
          return object({
            id: string().optional(),
            name: string().optional()
          })
        }
      })
      .required('fields.etlAgent.error.unmatched')
      .nullable()
  })
}

export const dynamicValuesSchema = existingDynamicValuesSchema
