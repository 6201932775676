import { BRANCH_NAME } from '__test-utils__/ids'

import { type LegacyDescribeMergeResponse } from 'api/hooks/useLegacyDescribeMerge/types'

export const MockedLegacyMergeDescription: Record<
  string,
  LegacyDescribeMergeResponse
> = {
  main: {
    sourceCommitId: '96835dd8bfa718bd6447ccc87af89ae1675daeca',
    targetCommit: {
      id: '579435ab17bf62fb92df6a92c2b964b58efdba53',
      message:
        'feat(ETLD-1020): Merged feature/ETLD-1020 into main. Approved by Tom.',
      datetime: '2023-01-23T09:06:04Z'
    },
    conflicts: []
  },
  a670a899f1a724eecb00d471225a9ab2731aa627: {
    sourceCommitId: '3cf22a06f0fa3dc6a1304b02485c1c8501e3aba7',
    targetCommit: {
      id: 'a670a899f1a724eecb00d471225a9ab2731aa627',
      message: 'feat(ETLD-1034): integrating the describe merge conflict API',
      datetime: '2022-11-29T09:06:04Z'
    },
    conflicts: [
      {
        fileName: 'job-one.ORCHESTRATION',
        type: {
          source: 'MODIFIED',
          target: 'DELETED'
        }
      },
      {
        fileName: 'job-two.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      }
    ]
  },
  b444ac06613fc8d63795be9ad0beaf55011936ac: {
    sourceCommitId: 'a94a8fe5ccb19ba61c4c0873d391e987982fbbd3',
    targetCommit: {
      id: 'b444ac06613fc8d63795be9ad0beaf55011936ac',
      message: 'feat(ETLD-1034): integrating the describe merge conflict API',
      datetime: '2022-11-29T09:06:04Z'
    },
    conflicts: []
  },
  BranchNoConflicts: {
    sourceCommitId: '6813c7a7aa17a05a67e2070fc123bcce8cfd5d4d',
    targetCommit: {
      id: 'a4e7abaebe3b75365759119ee03f3975204e1a5b',
      message: 'feat(ETLD-1034): a clean merge with no conflicts',
      datetime: '2021-05-14T13:45:09Z'
    },
    conflicts: []
  },
  BranchWithConflicts: {
    sourceCommitId: '09655cbd1dadd85f48f9abe20ee684959fea40e1',
    targetCommit: {
      id: '54ae3f25ba0fc53bac3cbeea137e3ace207841d4',
      message: 'feat(ETLD-1034): a merge with several file conflicts',
      datetime: '2022-12-03T15:24:54Z'
    },
    conflicts: [
      {
        fileName: 'simple-email.ORCHESTRATION',
        type: {
          source: 'MODIFIED',
          target: 'DELETED'
        }
      },
      {
        fileName: 'load-bitbucket-jobs.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      },
      {
        fileName:
          'a-job-that-has-a-reaaaallllyyyyyyyyy-looooooooooooonnnnngggggggggggggg-name.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      }
    ]
  },
  BranchManyConflicts: {
    sourceCommitId: '09655cbd1dadd85f48f9abe20ee684959fea40e1',
    targetCommit: {
      id: '54ae3f25ba0fc53bac3cbeea137e3ace207841d4',
      message: 'feat(ETLD-1034): a merge with several file conflicts',
      datetime: '2022-12-03T15:24:54Z'
    },
    conflicts: [
      {
        fileName: 'simple-email.ORCHESTRATION',
        type: {
          source: 'MODIFIED',
          target: 'DELETED'
        }
      },
      {
        fileName: 'load-bitbucket-jobs.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      },
      {
        fileName:
          'a-job-that-has-a-reaaaallllyyyyyyyyy-looooooooooooonnnnngggggggggggggg-name.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      },
      ...[...Array(7).keys()]
        .map((i: number) => i + 4)
        .map((id: number) => {
          const isOrchestration = id % 2 === 0
          const extension = isOrchestration ? 'ORCHESTRATION' : 'TRANSFORMATION'
          return {
            fileName: `job${id}.${extension}`,
            type: {
              source: 'ADDED',
              target: 'DELETED'
            }
          }
        })
    ]
  },
  [BRANCH_NAME]: {
    sourceCommitId: '6813c7a7aa17a05a67e2070fc123bcce8cfd5d4d',
    targetCommit: {
      id: 'a4e7abaebe3b75365759119ee03f3975204e1a5b',
      message: 'feat(ETLD-1034): a clean merge with no conflicts',
      datetime: '2021-05-14T13:45:09Z'
    },
    conflicts: []
  },
  'target-branch': {
    sourceCommitId: '09655cbd1dadd85f48f9abe20ee684959fea40e1',
    targetCommit: {
      id: '54ae3f25ba0fc53bac3cbeea137e3ace207841d4',
      message: 'feat(GIT-726): an example response for a storybook demo.',
      datetime: '2022-12-03T15:24:54Z'
    },
    conflicts: [
      {
        fileName: 'file-name-1.ORCHESTRATION',
        type: {
          source: 'MODIFIED',
          target: 'DELETED'
        }
      },
      {
        fileName: 'file-name-2.TRANSFORMATION',
        type: {
          source: 'ADDED',
          target: 'DELETED'
        }
      }
    ]
  }
}
