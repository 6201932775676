import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import axios, { AxiosRequestConfig } from 'axios'
import { useAuth, useUser } from '@matillion/hub-client'

import config from 'config'
import { API_BASE_URL, QUERY_SMART_TIMEOUT } from 'constants/endpoint'
import { useNetworkErrorToast } from 'api/hooks/useNetworkErrorToast'
import { ErrorResponse } from 'api/types'

interface TokenOptionsProps {
  audience?: string
  scope?: string
}

export interface UseClientProps {
  tokenOptions?: TokenOptionsProps
  axiosConfig?: AxiosRequestConfig
  showErrorDetailMessage?: boolean
}

export const useClient = (params?: UseClientProps) => {
  const { getToken } = useAuth()
  const { organisation, user } = useUser()
  const { t } = useTranslation()
  const makeErrorToast = useNetworkErrorToast()

  return useMemo(() => {
    const apiBaseUrl = API_BASE_URL.replace('{{region}}', organisation.region!)

    const axiosInstance = axios.create({
      baseURL: params?.axiosConfig?.baseURL || apiBaseUrl,
      timeout: QUERY_SMART_TIMEOUT,
      timeoutErrorMessage: t('error.query.timeout'),
      ...params?.axiosConfig
    })

    axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (params?.showErrorDetailMessage) {
          const errorDetail = (error.response?.data as ErrorResponse)?.detail
          makeErrorToast({
            title: t('error.unexpected.title'),
            message: errorDetail || t('error.unexpected.message')
          })
        }
        throw error
      }
    )

    axiosInstance.interceptors.request.use(
      async (interceptedConfig: AxiosRequestConfig) => {
        const options = {
          audience: params?.tokenOptions?.audience || config.apiBaseAudience,
          scope: params?.tokenOptions?.scope || config.apiBaseScope
        }

        const token = await getToken(options)

        interceptedConfig.headers = {
          ...interceptedConfig.headers,
          Authorization: `Bearer ${token}`,
          'Account-Id': organisation.id,
          Username: user.sub!
        }

        return interceptedConfig
      }
    )

    return axiosInstance
  }, [
    params,
    t,
    getToken,
    user,
    organisation.id,
    organisation.region,
    makeErrorToast
  ])
}
