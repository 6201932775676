import { FunctionComponent } from 'react'

import {
  AutoComplete,
  AutoCompleteItem,
  Field
} from '@matillion/component-library'
import { useTranslation } from 'react-i18next'

import { resolveErrorText } from 'modules/Projects/CreateBranch/CreateBranch.util'
import classes from 'modules/Projects/CreateBranch/CreateBranch.module.scss'
import { GETBranchesResponse, GETBranchResponseParams } from 'api/types'
import { useFormikContext } from 'formik'

const BranchFrom: FunctionComponent<{
  availableBranches: GETBranchesResponse
}> = ({ availableBranches }) => {
  const { t } = useTranslation()
  const fieldName = 'branchFrom' as const
  const { getFieldMeta, getFieldProps, setFieldValue } = useFormikContext()
  const meta = getFieldMeta(fieldName)
  const field = getFieldProps(fieldName)
  const branches = availableBranches
    .map((x: GETBranchResponseParams) => ({
      id: x.name,
      name: x.name
    }))
    .sort((a, b) => (b.name === 'main' ? 1 : a.name.localeCompare(b.name)))

  return (
    <div data-testid="create-branch-from">
      <Field
        {...field}
        data-testid="create-branch-from-input"
        className={classes['CreateBranch__Field--normal']}
        title={t('addBranch.fields.branchFrom.title')}
        name={fieldName}
        placeholder={t('addBranch.fields.branchFrom.placeholderText')}
        inputComponent={AutoComplete}
        availableItems={branches}
        errorText={resolveErrorText(t, meta)}
        value={field.value || null}
        onChange={async (e: { target: AutoCompleteItem }) =>
          setFieldValue('branchFrom', e.target.value?.id)
        }
      />
    </div>
  )
}

export default BranchFrom
