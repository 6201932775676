import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Radio, Typography } from '@matillion/component-library'

import { ProvisionType, Warehouse } from 'types'

import classes from './WarehouseProvisioningCard.module.scss'

export interface WarehouseProvisioningCardProps {
  icon: JSX.Element
  bannerText?: string
  title: string
  supportText?: string
  supportBullets?: string[]
  minutes: number | string
  poweredByIcon?: JSX.Element
  isSelected?: boolean
  onSelect: () => void
  warehouseType: Warehouse
  provisionType: ProvisionType
  showDetail?: boolean
  unavailable?: boolean
}

const WarehouseProvisioningCard: FunctionComponent<
  WarehouseProvisioningCardProps
> = ({
  icon,
  bannerText,
  title,
  supportText,
  supportBullets,
  minutes,
  poweredByIcon,
  isSelected = false,
  onSelect,
  warehouseType,
  provisionType,
  showDetail = false,
  unavailable = false
}) => {
  const { t } = useTranslation()
  const prefixDataTestId = `${provisionType.toString()}-${warehouseType.toLowerCase()}`

  return (
    <button
      name="warehouse-provisioning-card-btn"
      aria-label={t('warehouseProvisioning.ariaLabels.button')}
      type="button"
      onClick={onSelect}
      className={classNames(classes.WarehouseProvisioningCard__Container, {
        [classes['WarehouseProvisioningCard__Container--selected']]: isSelected,
        [classes['WarehouseProvisioningCard__Container--unavailable']]:
          unavailable
      })}
      data-testid={`${prefixDataTestId}-warehouse-provisioning-card`}
      disabled={unavailable}
    >
      <div className={classes.WarehouseProvisioningCard__TopBanner}>
        {unavailable ? (
          <div
            className={classNames(
              classes.WarehouseProvisioningCard__LhsBannerText,
              classes['WarehouseProvisioningCard__LhsBannerText--unavailable']
            )}
            data-testid={`${prefixDataTestId}-banner-text`}
          >
            <Typography format="bcs">
              {t('warehouseProvisioning.unavailableBannerText')}
            </Typography>
          </div>
        ) : (
          <>
            {bannerText && (
              <div
                className={classes.WarehouseProvisioningCard__LhsBannerText}
                data-testid={`${prefixDataTestId}-banner-text`}
              >
                <Typography format="bcs">{bannerText}</Typography>
              </div>
            )}
          </>
        )}
        <Radio
          name="warehouse-provisioning-radio"
          className={classes.WarehouseProvisioningCard__RadioBtn}
          checked={isSelected}
          onChange={onSelect}
          data-testid={`${prefixDataTestId}-warehouse-provisioning-radio`}
          disabled={unavailable}
        />
      </div>
      <div className={classes.WarehouseProvisioningCard__Content}>
        <div
          className={classes.WarehouseProvisioningCard__LogosContainer}
          data-testid={`${prefixDataTestId}-warehouse-provisioning-logos`}
        >
          {icon}
        </div>
        <div className={classes.WarehouseProvisioningCard__TitleContainer}>
          <Typography
            as="h3"
            format="bcl"
            data-testid={`${prefixDataTestId}-warehouse-provisioning-title`}
          >
            {title}
          </Typography>
        </div>
        {showDetail && (
          <>
            <div className={classes.WarehouseProvisioningCard__SupportText}>
              <Typography
                format="bcm"
                data-testid={`${prefixDataTestId}-warehouse-provisioning-support-text`}
              >
                {supportText}
              </Typography>
              {!!supportBullets?.length && Array.isArray(supportBullets) && (
                <ul>
                  {supportBullets.map((bullet, index) => (
                    <li key={bullet}>
                      <Typography
                        format="bcm"
                        data-testid={`${prefixDataTestId}-warehouse-provisioning-bullet-${
                          index + 1
                        }`}
                      >
                        {bullet}
                      </Typography>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {!!poweredByIcon && (
              <div
                className={classes.WarehouseProvisioningCard__PoweredBy}
                data-testid={`${prefixDataTestId}-warehouse-provisioning-powered-by`}
              >
                <Typography format="bcs">
                  {t('warehouseProvisioning.poweredBy')}
                </Typography>
                {poweredByIcon}
              </div>
            )}
          </>
        )}
      </div>
      <div className={classes.WarehouseProvisioningCard__Footer}>
        <div className={classes.WarehouseProvisioningCard__FooterFloat}>
          <div
            className={classes.WarehouseProvisioningCard__Minutes}
            data-testid={`${prefixDataTestId}-warehouse-provisioning-minutes`}
          >
            <Typography format="mc" weight="bold">
              {minutes}
            </Typography>
          </div>
        </div>
      </div>
    </button>
  )
}

export default WarehouseProvisioningCard
