import { object, string } from 'yup'

import { FormValues } from 'modules/Projects/CloudProviderCredentials'
import { CloudProviders } from 'types/CloudProviders'
import { AutoCompleteProps } from 'types/FormTypes'

export const providerValues: Partial<FormValues> = {
  accessKeyID: '',
  tenantID: '',
  clientID: '',
  secretKey: ''
}

export const initialValues = (
  provider: CloudProviders,
  agent?: AutoCompleteProps
): FormValues => ({
  name: '',
  provider,
  agent: agent || { id: '', name: '' },
  ...providerValues
})

export const commonSchema = {
  name: string().required(
    'cloudProviderCredentials.fields.common.credentialName.error.required'
  ),
  provider: string()
    .oneOf(Object.values(CloudProviders))
    .required('cloudProviderCredentials.fields.common.provider.error.required'),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .nullable()
    .required('commonForm.fields.agent.error.unmatched')
}

export const dynamicProvidersSchema = {
  [CloudProviders.AWS]: object({
    ...commonSchema,
    accessKeyID: string().required(
      'cloudProviderCredentials.fields.aws.accessKeyID.error.required'
    ),
    secretKey: string().required(
      'cloudProviderCredentials.fields.aws.secretKey.error.required'
    )
  }),
  [CloudProviders.AZURE]: object({
    ...commonSchema,
    tenantID: string().required(
      'cloudProviderCredentials.fields.azure.tenantID.error.required'
    ),
    clientID: string().required(
      'cloudProviderCredentials.fields.azure.clientID.error.required'
    ),
    secretKey: string().required(
      'cloudProviderCredentials.fields.azure.secretKey.error.required'
    )
  })
}
