import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'components/Form/Form.module.scss'
import { AgentsSecretsHost } from 'types'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'
import { useLocation, useParams } from 'react-router-dom'
import { AppRoutes } from 'constants/route'

const SecretValue = () => {
  const location = useLocation()
  const { projectId, envId } = useParams()
  const isEditEnvironment = location.pathname.includes(
    AppRoutes.getEnvironmentEdit(projectId!, envId!)
  )

  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<CreateProjectFormikValueTypes>()

  const { t } = useTranslation()
  const isMatillionHostedEditEnvironment =
    values.projectConfiguration === AgentsSecretsHost.MatillionHosted &&
    isEditEnvironment

  const supportText = isMatillionHostedEditEnvironment
    ? t('fields.secretValue.supportText.matillionHosted')
    : t('fields.secretValue.supportText.customerHosted')

  return (
    <div
      data-testid={`${values.type.toLowerCase()}-credentials-secret-value`}
      className={classNames(isEditEnvironment && classes.Form__FixedHeight)}
    >
      <Field
        type="password"
        title={t('fields.secretValue.title')}
        name="secretValue"
        data-testid={`${values.type.toLowerCase()}-credentials-secret-value-input`}
        value={values.secretValue}
        placeholder={t('fields.secretValue.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__SpacingStyles)}
        supportText={supportText}
        errorText={
          errors?.secretValue && touched.secretValue
            ? t(errors.secretValue)
            : null
        }
        hasError={Boolean(errors?.secretValue) && Boolean(touched.secretValue)}
        required={!isEditEnvironment}
      />
    </div>
  )
}

export default SecretValue
