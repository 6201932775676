import { FocusEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { Field } from '@matillion/component-library'

import classes from 'modules/Secrets/SecretDefinitionFormContent/SecretDefinitionFormContent.module.scss'
import {
  FormFields,
  resolveFormikError
} from 'modules/Secrets/CreateSecretDefinitionForm/CreateSecretDefinitionForm.utils'
import { useFormikFieldSpacesToHyphens } from 'hooks'

const Name: FunctionComponent = () => {
  const { t } = useTranslation()
  const { getFieldMeta, getFieldProps, setFieldValue, handleBlur } =
    useFormikContext()
  const meta = getFieldMeta(FormFields.name)
  const field = getFieldProps(FormFields.name)
  const spacesToHyphens = useFormikFieldSpacesToHyphens({
    fieldName: FormFields.name
  })

  return (
    <div data-testid="secret-definition-name">
      <Field
        {...field}
        className={classes['SecretDefinitionFormContent__Field--normal']}
        title={t('secrets.definitionForm.fields.name.title')}
        placeholder={t('secrets.definitionForm.fields.name.placeholder')}
        supportText={t('secrets.definitionForm.fields.name.supportText')}
        errorText={resolveFormikError(t, meta)}
        autoComplete="off"
        data-testid="secret-definition-name-input"
        onBlur={(e: FocusEvent<HTMLInputElement>) => {
          setFieldValue(FormFields.name, e.target.value.trim())
          handleBlur(e)
        }}
        onChange={spacesToHyphens}
      />
    </div>
  )
}

export default Name
