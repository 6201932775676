import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { postSecretValueV2 } from 'api/clients'
import { useClient } from 'api/hooks'
import { POSTSecretValueResponse, POSTSecretValueV2Request } from 'api/types'
import { QueryKey } from 'constants/endpoint'

type TData = POSTSecretValueResponse
type TError = AxiosError<unknown>
interface TVariables {
  values: POSTSecretValueV2Request
}

type Options = UseMutationOptions<
  POSTSecretValueResponse,
  AxiosError,
  TVariables
>

/**
 * Creates the secret value for users with or without existing projects.
 *
 * @param {string|undefined} projectId - The ID of a project you wish to
 *          associate the secret value to. Leave null when project is
 *          not known.
 * @param {Object|undefined} options - useQuery options
 * @version 2
 */
export const usePostSecretValueV2 = (projectId?: string, options?: Options) => {
  const client = useClient({ showErrorDetailMessage: true })

  return useMutation<TData, TError, TVariables>(
    [QueryKey.SECRET_VALUE],
    async (data: { values: POSTSecretValueV2Request }) => {
      const { values } = data
      return postSecretValueV2(
        values,
        {
          projectId
        },
        client
      )
    },
    options
  )
}
