import { object, string, InferType } from 'yup'
import { FieldMetaProps } from 'formik'
import { TFunction } from 'react-i18next'

export const resolveErrorText = (t: TFunction, meta: FieldMetaProps<unknown>) =>
  meta.touched && meta.error ? t(meta.error) : undefined

export const formSchema = object({
  role: string().required('accessListing.editUser.fields.role.error.required')
})

export type FormValues = InferType<typeof formSchema>

export const initialValues: FormValues = {
  role: ''
}
