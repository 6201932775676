import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from '@matillion/component-library'

import { ActionCellTypes } from '.'

import EllipsesActionItem, {
  ThemeType
} from 'components/EllipsesActionItem/EllipsesActionItem'
import { useGetPermissions } from 'api/hooks'
import { useParams } from 'react-router-dom'
import { PermissionsType } from 'types'

const ActionCell: FunctionComponent<ActionCellTypes> = ({
  onEditClick,
  onDeleteClick,
  item
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()
  const { data: editPermission } = useGetPermissions(
    projectId!,
    PermissionsType.EditBranches
  )
  const { data: deletePermission } = useGetPermissions(
    projectId!,
    PermissionsType.DeleteBranches
  )

  const handleEditClick = () => {
    onEditClick(item)
  }

  const handleDeleteClick = () => {
    if (onDeleteClick) {
      onDeleteClick(item)
    }
  }

  return editPermission?.result || deletePermission?.result ? (
    <EllipsesActionItem
      actions={[
        ...(editPermission?.result
          ? [
              {
                title: t('branchesListing.actions.edit'),
                action: handleEditClick,
                icon: <Icon.Pencil />,
                dataTestId: 'ellipses-action-edit-branch'
              }
            ]
          : []),
        ...(onDeleteClick !== undefined && deletePermission?.result
          ? [
              {
                title: t('branchesListing.actions.delete'),
                action: handleDeleteClick,
                icon: <Icon.Trash />,
                type: 'danger' as ThemeType,
                dataTestId: 'ellipses-action-delete-branch'
              }
            ]
          : [])
      ]}
    />
  ) : null
}

export default ActionCell
