export enum PermissionsType {
  CreateBranches = 'create_branches',
  EditBranches = 'edit_branches',
  DeleteBranches = 'delete_branches',
  ViewBranches = 'view_branches',

  CreateEnvironments = 'create_environments',
  EditEnvironments = 'edit_environments',
  DeleteEnvironments = 'delete_environments',
  ViewEnvironments = 'view_environments',

  CreateSecrets = 'create_secrets',
  DeleteSecrets = 'delete_secrets',
  EditSecrets = 'edit_secrets',
  ViewSecrets = 'view_secrets',

  CreateOAuths = 'create_oauths',
  EditOAuths = 'edit_oauths',
  DeleteOAuths = 'delete_oauths',
  ViewOAuths = 'view_oauths',

  CreateSchedules = 'create_schedules',
  EditSchedules = 'edit_schedules',
  DeleteSchedules = 'delete_schedules',
  ViewSchedules = 'view_schedules',

  AdminPermission = 'admin_permission'
}
