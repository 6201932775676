import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Toaster } from '@matillion/component-library'
import { ModalContent, ModalHeading } from 'components/Modal'
import { FormValues, formSchema, initialValues } from './AddUser.util'
import { AddUserProps } from './types'
import User from './Fields/User'
import Role from './Fields/Role'
import Form from 'components/Form'
import { useGetAccountMembers } from 'api/hooks'
import { FormikHelpers } from 'formik'
import { useParams } from 'react-router-dom'
import { usePostRoles } from 'api/hooks/usePostRoles'

const AddUser: FunctionComponent<AddUserProps> = ({
  projectMembers,
  onCancelModal,
  onSubmit
}) => {
  const {
    data: accountMembers = [],
    isError,
    isLoading
  } = useGetAccountMembers()
  const { t } = useTranslation()
  const { projectId = '' } = useParams()
  const { mutateAsync } = usePostRoles(projectId)
  const { makeToast, clearToasts } = Toaster.useToaster()

  const handleSubmitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    clearToasts()

    mutateAsync({
      values: {
        roles: [{ userId: values.user, role: values.role }]
      }
    })
      .then((_response) => {
        const selectedUser = accountMembers.find(
          (user) => user.id === values.user
        )
        const selectedUserName =
          `${selectedUser?.firstName?.trim() ?? ''} ${
            selectedUser?.lastName?.trim() ?? ''
          }`.trim() || selectedUser?.email

        makeToast({
          title: t('accessListing.addUser.responseMessage.success', {
            user: selectedUserName
          }),
          message: '',
          type: 'success'
        })
      })
      .catch((_error) => {
        makeToast({
          title: t('accessListing.addUser.responseMessage.error'),
          message: '',
          type: 'error'
        })
      })
      .finally(() => {
        setSubmitting(false)
        onSubmit()
      })
  }

  return (
    <Modal
      size="mid"
      data-testid="add-user-modal"
      onCancel={onCancelModal}
      ariaLabelledBy="modal-title"
    >
      <ModalHeading data-testid="add-user-title">
        {t('accessListing.addUser.heading')}
      </ModalHeading>
      <ModalContent>
        <Form
          formikValues={{
            initialValues: initialValues,
            initialTouched: false,
            onSubmit: handleSubmitForm,
            validationSchema: formSchema,
            validateOnMount: true,
            enableReinitialize: true
          }}
          onCancel={onCancelModal}
          translationPrefix="accessListing.addUser"
        >
          <>
            <User
              accountMembers={accountMembers}
              projectMembers={projectMembers}
              isError={isError}
              isLoading={isLoading}
            />
            <Role />
          </>
        </Form>
      </ModalContent>
    </Modal>
  )
}

export default AddUser
