import { UseQueryOptions, useQuery } from 'react-query'
import { AxiosError } from 'axios'
import { useClient } from 'api/hooks'
import config from 'config'
import { getProjectMembers } from 'api/clients'
import { GETProjectMembersResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { getProjectMembersMutation } from 'api/mutation/getProjectMembers'

type TData = GETProjectMembersResponse
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetProjectMembers = (projectId: string, options?: TOptions) => {
  const client = useClient({
    tokenOptions: {
      audience: config.hub.apiAudience
    },
    axiosConfig: {
      baseURL: config.hub.apiUrl
    }
  })

  return useQuery<TData, TError>(
    [QueryKey.PROJECT_MEMBERS_LIST, projectId],
    async () => {
      return getProjectMembersMutation(
        await getProjectMembers({ projectId }, client)
      )
    },
    options
  )
}
