import { ReactElement, useState } from 'react'
import classNames from 'classnames'
import { Button, Icon, Popover } from '@matillion/component-library'

import classes from './MorePopover.module.scss'

type Positions = 'top' | 'bottom' | 'left' | 'right'

interface MorePopoverProps {
  children: ReactElement
  open?: boolean
  setOpen?: (open: boolean) => void
  className?: string
  position?: Positions[]
}

export const MorePopover = ({
  children,
  open,
  setOpen,
  position = ['top', 'left'],
  className
}: MorePopoverProps) => {
  const [showContextMenu, setShowContextMenu] = useState(false)
  const isPopoverOpen = open ?? showContextMenu
  const setIsPopoverOpen = setOpen ?? setShowContextMenu

  return (
    <Popover
      className={classNames(classes.MorePopover, className)}
      isOpen={isPopoverOpen}
      anchor={
        <Button
          alt="text"
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          data-testid="more-popover-button"
          className={classNames(
            classes.MorePopover__MoreButton,
            showContextMenu
              ? classes['MorePopover__ContextMenuEllipsis--show']
              : classes['MorePopover__ContextMenuEllipsis--hide']
          )}
          aria-label="Popover button"
        >
          <Icon.Ellipsis
            className={classes.MorePopover__ContextMenuEllipsis}
            width={13}
            height={4}
          />
        </Button>
      }
      onClickOutside={() => setIsPopoverOpen(false)}
      position={position}
      align="end"
    >
      {children}
    </Popover>
  )
}
