import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import { type ProblemDetails } from 'types/ProblemDetails'
import { type Method } from 'cypress/types/net-stubbing'
import { BRANCH_NAME, PROJECT_ID } from './ApiInterceptor.stubs'

export interface Config<T> {
  /**
   * The HTTP response code that Cypress should
   * return for the intercepted API call.
   */
  statusCode: number

  /**
   * The DPC project ID to be used in the intercepted
   * endpoints URL or request body where relevant.
   */
  projectId: string

  /**
   * The DPC branch name to be used in the intercepted
   * endpoints URL or request body where relevant.
   */
  branch: string

  /**
   * The commit range to be used in the intercepted
   * endpoints URL or request body where relevant.
   *
   * A "commit range" is Git terminology for two specifiers
   * (branch name, commit id, or tag) separated by
   * 2 (..) or 3 (...) dots.
   */
  commitRange: string

  /**
   * The name of the external git provider to be used
   * in the intercepted endpoints URL or request body
   * where relevant.
   */
  provider: ExternalGitProvider

  /**
   * The response payload to return from the intercepted
   * API call. Will be typed based on the interceptor in
   * question, but can also be a {@link ProblemDetails}
   * response too for known error scenarios.
   */
  response: T | ProblemDetails

  /**
   * The alias string to tag the interceptor with.
   * Will default to a sensible name if omitted.
   */
  alias: string

  /**
   * The delay, in milliseconds, to tell Cypress to wait
   * before resolving the intercepted call.
   */
  delay: number

  /**
   * When specified as true, the intercepts promise will be
   * captured and returned from {@link InterceptorResponse}
   * so it can be manually resolved or rejected in the Cypress
   * test. This allows for full control over timing, should you
   * wish to interact with the DOM while an API call is still
   * in flight.
   */
  manualResolution: boolean

  /**
   * Headers to be returned in the response for the intercepted
   * API call.
   */
  headers: Record<string, string>
}

/**
 * Optional configuration for a Cypress interceptor.
 *
 * Omitting this configuration causes the interceptor
 * to fall-back to sensible happy-path defaults.
 */
export type InterceptorConfig<ResponseType> = Partial<Config<ResponseType>>

export type CypressThenable = (
  thenableOrResult?: (void | PromiseLike<void>) | undefined
) => void

/**
 * A promise returned by Cypress for an
 * intercepted API call.
 */
export type ApiPromise = CypressThenable | undefined

export interface InterceptorResponse {
  /**
   * A function that resolves the API call promise.
   * Will only be returned when manualResolution is set to
   * true in the {@link InterceptorConfig}.
   */
  resolve?: ApiPromise

  /**
   * The alias assigned to the Cypress interceptor.
   */
  alias: string
}

export interface InterceptorDetails<Response> {
  /**
   * A unique name for the API call. This is
   * used for the default Cypress alias, should one
   * not be provided in the config overrides.
   */
  name: string

  /**
   * The HTTP method for the API call.
   */
  method: Method

  /**
   * Optional configuration to override the
   * default options.
   */
  config?: InterceptorConfig<Response>

  /**
   * The default API response payload should
   * one not be provided in the config.
   */
  defaultResponse: Response

  /**
   * The expected response code for the
   * happy path of the API call. Can be
   * overridden in the config.
   */
  responseCode: number

  /**
   * A function that returns the endpoint in
   * which you want to intercept.
   *
   * @param args A function that provides values for path variables.
   * @return endpoint The constructed endpoint string with values substituted.
   */
  endpoint: (args: {
    project: string
    branch: string
    provider: ExternalGitProvider
    commitRange: string
  }) => string
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultConfig: Config<any> = {
  statusCode: 200,
  projectId: PROJECT_ID,
  branch: BRANCH_NAME,
  provider: 'github',
  response: { note: 'default response from api interceptor' },
  alias: 'defaultAlias',
  delay: 0,
  commitRange: 'source...target',
  manualResolution: false,
  headers: {}
}
