import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@matillion/component-library'

import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'

interface ListingHeaderProps {
  onAddSecret: VoidFunction
  permission?: boolean
}

const ListingHeader: FunctionComponent<ListingHeaderProps> = ({
  onAddSecret,
  permission = false
}) => {
  const { t } = useTranslation()

  return (
    <Header fullWidth centerAlign={false}>
      <HeaderWithCTA>
        <HeaderTitle
          data-testid="secret-definitions-title"
          centerAlign={false}
          headerElement="h2"
          format="tm"
          withBottomSpace={false}
        >
          {t('secrets.secretDefinitionListing.title')}
        </HeaderTitle>

        {permission && (
          <Button
            text={t('secrets.secretDefinitionListing.buttonText')}
            onClick={onAddSecret}
            data-testid="add-secret-definition-button"
            alt="positive"
          />
        )}
      </HeaderWithCTA>
    </Header>
  )
}

export default ListingHeader
