import { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import classNames from 'classnames'
import {
  Button,
  DataGrid,
  LoadingSpinner,
  SortState,
  Toaster,
  Typography
} from '@matillion/component-library'

import {
  useGetPermissions,
  useGetSchedules,
  useNetworkErrorToast
} from 'api/hooks'
import { queryClient } from 'api/queryClient'
import { Header, HeaderTitle, HeaderWithCTA } from 'components/Header'
import { QueryKey } from 'constants/endpoint'
import { AppRoutes } from 'constants/route'
import { PermissionsType } from 'types'

import { ColumnKeys, SortedScheduleDataTypes, SortTypes } from '.'
import ActionCell from './components/ActionCell'
import NoCreatePermission from './components/NoCreatePermission'
import NoData from './components/NoData'
import NoReadPermission from './components/NoReadPermission'
import { schedulesSort } from './schedulesListing.util'

import classes from './SchedulesListing.module.scss'

const SchedulesListing: FunctionComponent = () => {
  const [sortState, setSortState] = useState<Partial<SortState<SortTypes>>>({
    name: 'ASC'
  })
  const { projectId } = useParams()
  const navigate = useNavigate()
  const makeErrorToast = useNetworkErrorToast()
  const { t } = useTranslation()

  const {
    data: schedulesData = [],
    error: schedulesError,
    isError: isSchedulesError,
    isLoading: isSchedulesLoading
  } = useGetSchedules(projectId!)

  const { data: createPermission, isLoading: isCreatePermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.CreateSchedules)
  const { data: viewPermission, isLoading: isViewPermissionLoading } =
    useGetPermissions(projectId!, PermissionsType.ViewSchedules)

  const { clearToasts } = Toaster.useToaster()

  if (sortState) {
    const schedulesSortFunction = schedulesSort(sortState)
    schedulesData.sort(schedulesSortFunction)
  }

  const clickAddScheduleHandler: VoidFunction = () => {
    clearToasts()
    navigate(AppRoutes.getScheduleAdd(projectId!))
  }

  const dateFormat = (date: string) =>
    date !== '-' ? format(new Date(date), 'HH:mm b dd/MM/yyyy') : date

  const getDataGridContent = () => {
    return (
      <DataGrid
        className={classNames(classes.SchedulesListing__Grid)}
        rowClassName={classNames(classes.SchedulesListing__GridRow)}
        data-testid="schedules-data-grid"
        aria-label="Schedules Data Grid"
        defaultSort={{ name: 'ASC' }}
        columns={[
          {
            key: ColumnKeys.name,
            title: t('schedulesListing.column.col1'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => {
              return {
                children: row.name
              }
            },
            sortable: true
          },
          {
            key: ColumnKeys.jobName,
            title: t('schedulesListing.column.col2'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.job
            }),
            sortable: true
          },
          {
            key: ColumnKeys.schedule,
            title: t('schedulesListing.column.col3'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.schedule
            }),
            sortable: true
          },
          {
            key: ColumnKeys.lastRun,
            title: t('schedulesListing.column.col4'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: dateFormat(row.lastRun)
            }),
            sortable: true
          },
          {
            key: ColumnKeys.nextRun,
            title: t('schedulesListing.column.col5'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: dateFormat(row.nextRun)
            }),
            sortable: true
          },
          {
            key: ColumnKeys.environment,
            title: t('schedulesListing.column.col6'),
            as: Typography,
            className: classNames(classes.SchedulesListing__GridCell),
            mapValues: (row) => ({
              children: row.environment
            }),
            sortable: true
          },
          {
            key: ColumnKeys.action,
            title: t('schedulesListing.column.col7'),
            as: ActionCell,
            className: classNames(
              classes.SchedulesListing__GridCell,
              classes['SchedulesListing__GridCell--action']
            ),
            mapValues: (row) => ({
              projectId: projectId!,
              item: row
            }),
            sortable: false
          }
        ]}
        rows={schedulesData as SortedScheduleDataTypes[]}
        onSort={setSortState}
      />
    )
  }

  const getErrorContent = () => {
    return (
      <div className={classNames(classes.SchedulesListing__ErrorInfo)}>
        <Typography format="bcm">{t('schedulesListing.error')}</Typography>
      </div>
    )
  }

  const getInnerContent = () => {
    return isSchedulesError ? getErrorContent() : getDataGridContent()
  }

  useEffect(() => {
    if (isSchedulesError) {
      makeErrorToast({ message: schedulesError.message })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schedulesError, isSchedulesError])

  useEffect(() => {
    const schedulesSortFunction = schedulesSort({ name: 'ASC' })
    schedulesData.sort(schedulesSortFunction)

    return () => {
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.CreateSchedules
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.DeleteSchedules
      ])
      queryClient.removeQueries([
        QueryKey.USER_PERMISSIONS,
        PermissionsType.EditSchedules
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (
    !schedulesData.length &&
    !isSchedulesError &&
    !isSchedulesLoading &&
    !isViewPermissionLoading &&
    !isCreatePermissionLoading
  ) {
    if (!createPermission?.result) {
      return <NoCreatePermission />
    } else {
      return <NoData onAddHandler={clickAddScheduleHandler} />
    }
  }

  return (
    <>
      <Header fullWidth centerAlign={false}>
        <HeaderWithCTA>
          <HeaderTitle
            data-testid="schedules-title"
            centerAlign={false}
            headerElement="h2"
            format="tm"
            className={classes.SchedulesListing__Header}
          >
            {t('schedulesListing.title')}
          </HeaderTitle>
          {createPermission?.result && (
            <Button
              text={t('schedulesListing.buttonText')}
              onClick={clickAddScheduleHandler}
              data-testid="add-schedule-button"
              className={classNames(classes.SchedulesListing__AddButton)}
              data-tracker="pe_start-create-schedule-cta_click"
              alt="positive"
            />
          )}
        </HeaderWithCTA>
        <Typography as="p" data-testid="schedules-support-text">
          {t('schedulesListing.supportText')}
        </Typography>
      </Header>
      {isSchedulesLoading ||
      isViewPermissionLoading ||
      (isCreatePermissionLoading && !schedulesData.length) ? (
        <LoadingSpinner />
      ) : (
        <>
          {!viewPermission?.result ? <NoReadPermission /> : getInnerContent()}
        </>
      )}
    </>
  )
}

export default SchedulesListing
