import { POSTSetupOAuthV2Request } from 'api/types'
import { AutoCompleteProps } from 'types/FormTypes'

interface FormValuesTypesV2 {
  provider: AutoCompleteProps
  callbackUrl: string
  accountId: string
  providerParameters?: { [key: string]: string }
}

export interface Dynamics365FormValuesTypesV2 extends FormValuesTypesV2 {
  Edition: string
}

export const createOAuthMutationDataV2 = (
  formValues: FormValuesTypesV2
): POSTSetupOAuthV2Request => {
  let applications: string[] = []

  if (formValues.provider.id === 'google') {
    applications = ['spreadsheets', 'analytics', 'ads', 'bigquery']
  } else if (formValues.provider.id === 'facebook') {
    applications = ['query', 'ads']
  } else if (formValues.provider.id === 'dynamics365') {
    // this is a special provision for dynamics365 which we'll leave in, right now the Edition parameter dropdown matches
    // the set of applications. We need the Edition parameter to match the application being sent to UOS. Once we
    // implement the application dropdown this logic should be moved into the application dropdown component where we will
    // say "hide the application dropdown for dynamics 365 and set the applications selected equal to the Edition parameter"
    const edition = (formValues as Dynamics365FormValuesTypesV2).Edition
    applications = [edition]
  }

  return {
    applications,
    provider: formValues.provider.id,
    callbackUrl: formValues.callbackUrl,
    stateSuffix: formValues.accountId,
    providerParameters: formValues.providerParameters
      ? formValues.providerParameters
      : {}
  }
}
