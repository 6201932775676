import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import { useResetFieldOnProviderChange } from 'modules/FormsContent/CloudProviderCredentials/Fields/hooks'
import classes from 'modules/FormsContent/Form.module.scss'
import { FormValues } from 'modules/Projects/CloudProviderCredentials'

const ClientID: FunctionComponent = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    touched,
    values
  } = useFormikContext<FormValues>()
  const { t } = useTranslation()
  useResetFieldOnProviderChange('clientID', {
    setFieldTouched,
    setFieldValue
  })

  return (
    <div data-testid="cloud-provider-credentials-azure-client-id">
      <Field
        title={t('cloudProviderCredentials.fields.azure.clientID.title')}
        name="clientID"
        data-testid="cloud-provider-credentials-azure-client-id-input"
        value={values.clientID}
        placeholder={t(
          'cloudProviderCredentials.fields.azure.clientID.placeholderText'
        )}
        supportText={t(
          'cloudProviderCredentials.fields.azure.clientID.supportText'
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        errorText={
          errors?.clientID && touched.clientID ? t(errors.clientID) : null
        }
        hasError={!!errors?.clientID && touched.clientID}
        required
      />
    </div>
  )
}

export default ClientID
