import { v4 as uuidv4 } from 'uuid'

import { PATCHSecretValueRequest, POSTSecretValueV2Request } from 'api/types'
import { JSONValue, SecretReferenceTypes } from 'types'
import { CloudProviders } from 'types/CloudProviders'

interface FormValuesTypes {
  description?: string
  name: string
  matillionHostedAgentId: string
  secretValue: string
  secretLocationId: string
}

interface FormValuesV2Types {
  description?: string
  name: string
  matillionHostedAgentId: string
  secretValue: { [key: string]: JSONValue }
  metadata?: { [key: string]: string }
  secretLocationId: string
}

export const createPatchSecretValueMutationData = (
  formValues: FormValuesTypes
): PATCHSecretValueRequest => {
  return {
    secretValue: formValues.secretValue,
    agentId: formValues.matillionHostedAgentId
  }
}

export const createSecretValueV2MutationData = (
  formValues: FormValuesV2Types,
  appendSuffix = true,
  type = SecretReferenceTypes.DWH_PASSWORD
): POSTSecretValueV2Request => {
  const shortUuid = uuidv4().substring(0, 8)
  let envName = formValues.name.trim().replaceAll(' ', '-')
  if (appendSuffix) {
    envName = `${envName}-dwh-pwd-${shortUuid}`
  }

  return {
    name: envName,
    type: type,
    secretName: envName,
    secretKey: [
      SecretReferenceTypes.PASSWORD,
      SecretReferenceTypes.DWH_PASSWORD
    ].includes(type)
      ? 'password'
      : undefined,
    secretValue: formValues.secretValue,
    agentId: formValues.matillionHostedAgentId,
    locationId: formValues.secretLocationId,
    description: formValues.description,
    metadata: formValues.metadata
  }
}

export const createCloudProviderCredentialsV2MutationData = (
  formValues: FormValuesV2Types & { secretValue: { type: CloudProviders } },
  appendSuffix = true,
  type = SecretReferenceTypes.PASSWORD
): POSTSecretValueV2Request =>
  createSecretValueV2MutationData(formValues, appendSuffix, type)
