import cronValidator from 'cron-expression-validator'
import { date, object, string } from 'yup'
import {
  GETEnvironmentAgentsResponse,
  GETEnvironmentsResponse,
  GETSchedulesResponse,
  ScheduleDisplayMode
} from 'api/types'
import { EditScheduleFormValues } from 'modules/Projects/EditSchedule/types'

export const initialValues = (
  scheduleId: string,
  schedules?: GETSchedulesResponse[],
  environments?: GETEnvironmentsResponse,
  agents?: GETEnvironmentAgentsResponse,
  isMHA?: boolean
): EditScheduleFormValues => {
  const s = schedules?.find((x) => x.id === scheduleId)
  const e = environments?.find((x) => x.name === s?.environment)
  const agent = agents?.find((x) => x.agentId === s?.agentId)

  const schedule: EditScheduleFormValues = {
    scheduleId,
    name: s?.name ?? '',
    environment: {
      id: e?.id ?? '',
      name: s?.environment ?? ''
    },
    pipeline: {
      id: s?.job ?? '',
      name: s?.job ?? ''
    },
    agent: {
      id: s?.agentId ?? '',
      name: (isMHA ? s?.agentId : agent?.name) ?? ''
    },
    timezone: {
      id: s?.timezone ?? '',
      name: s?.timezone ?? ''
    },
    startTime: new Date(),
    cronExpression: s?.cronExpression ?? '',
    displayMode: ScheduleDisplayMode.ADVANCED
  }

  return schedule
}
export const validationSchema = object({
  name: string().required('editSchedule.fields.scheduleName.error.required'),
  environment: object({
    id: string().required('commonForm.fields.environment.error.required'),
    name: string().required('commonForm.fields.environment.error.required')
  })
    .required('commonForm.fields.environment.error.unmatched')
    .nullable(),
  pipeline: object({
    id: string().required('commonForm.fields.pipeline.error.required'),
    name: string().required('commonForm.fields.pipeline.error.required')
  })
    .required('commonForm.fields.pipeline.error.unmatched')
    .nullable(),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .required('commonForm.fields.agent.error.unmatched')
    .nullable(),
  timezone: object({
    id: string().required('editSchedule.fields.timezone.error.required'),
    name: string().required('editSchedule.fields.timezone.error.required')
  })
    .required('editSchedule.fields.timezone.error.unmatched')
    .nullable(),
  startTime: date().required('editSchedule.fields.startTime.error.required'),
  cronExpression: string()
    .test(
      'Cron Validation',
      'editSchedule.fields.cronExpression.error.unmatched',
      (value) => {
        const valid = cronValidator.isValidCronExpression(value!)
        return valid
      }
    )
    .required('editSchedule.fields.cronExpression.error.required')
})
