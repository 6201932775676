import { RoleGuard, useAuth } from '@matillion/hub-client'
import { FunctionComponent } from 'react'
import { Route, Routes } from 'react-router-dom'

import AppLayout from 'components/AppLayout'
import {
  GENERIC_PROJECT_DETAILS,
  PROJECTS_ADD,
  ROOT,
  ONBOARDING
} from 'constants/route'
import { Login } from 'modules/Auth'
import Onboarding from 'modules/Onboarding'
import CreateProject from 'modules/Projects/CreateProject'
import ProjectsDashboard from 'modules/Projects/ProjectDashboard'
import ProjectDetailsRoutes from 'modules/Projects/ProjectDetails'

import RouteWrapper from './RouteWrapper'

const App: FunctionComponent = () => {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn) {
    return <Login />
  }

  return (
    <RoleGuard role="saas-etl:user">
      <AppLayout>
        <Routes>
          <Route
            path={ROOT}
            element={
              <RouteWrapper>
                <ProjectsDashboard />
              </RouteWrapper>
            }
          />
          <Route
            path={`${ONBOARDING}/*`}
            element={
              <RouteWrapper enableTrialBanner={false}>
                <Onboarding />
              </RouteWrapper>
            }
          />
          <Route
            path={`${PROJECTS_ADD}/*`}
            element={
              <RouteWrapper>
                <CreateProject />
              </RouteWrapper>
            }
          />
          <Route
            path={`${ROOT}/${GENERIC_PROJECT_DETAILS}/*`}
            element={
              <RouteWrapper>
                <ProjectDetailsRoutes />
              </RouteWrapper>
            }
          />
        </Routes>
      </AppLayout>
    </RoleGuard>
  )
}

export default App
