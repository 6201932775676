import { FunctionComponent } from 'react'
import classNames from 'classnames'
import { Typography } from '@matillion/component-library'

import { GETProjectResponse } from 'api/types'
import { Header, HeaderTitle } from 'components/Header'

import PlaceholderFields from './PlaceholderFields'

import classes from 'modules/Projects/ProjectDetails/ProjectDetails.module.scss'

interface ProjectDetailsHeaderProps {
  project?: GETProjectResponse
}

const ProjectDetailsHeader: FunctionComponent<ProjectDetailsHeaderProps> = ({
  project: projectData
}) => {
  return (
    <Header
      fullWidth
      centerAlign={false}
      className={classNames(classes.ProjectDetail__Header)}
    >
      {!projectData ? (
        <PlaceholderFields />
      ) : (
        <>
          <HeaderTitle
            data-testid="project-detail-title"
            centerAlign={false}
            format="tl"
          >
            {projectData.name}
          </HeaderTitle>
          {projectData.description && (
            <Typography
              className={classNames(classes.ProjectDetail__Description)}
              data-testid="project-detail-support-text"
            >
              {projectData.description}
            </Typography>
          )}
        </>
      )}
    </Header>
  )
}

export default ProjectDetailsHeader
