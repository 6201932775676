import { Icon } from '@matillion/component-library'
import classNames from 'classnames'
import classes from './Icon.module.scss'

import { FunctionComponent } from 'react'

export const RedWarning: FunctionComponent = () => (
  <Icon.Warning
    className={classNames(classes.WarningIcon, classes.WarningIcon__Red)}
  />
)
