import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

const NoReadPermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('schedulesListing.noReadPermission.title')}
      supportText={t('schedulesListing.noReadPermission.content')}
      data-testid="no-read-permission-schedule"
    />
  )
}

export default NoReadPermission
