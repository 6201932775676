import { useEffect } from 'react'

export const usePolling = (
  handlePolling: (suspendInterval?: () => void) => Promise<void>,
  isComplete = false,
  onCompleteCallback?: () => void,
  enabled = true
) => {
  useEffect(() => {
    if (!enabled || isComplete) return
    handlePolling()

    const agentStatusTimer = setInterval(() => {
      handlePolling(() => clearInterval(agentStatusTimer))
    }, 3000)

    return () => {
      clearInterval(agentStatusTimer)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, isComplete]) // Causing a refetch of the polling when enabled changes

  useEffect(() => {
    if (!isComplete || !onCompleteCallback) return

    // Redirect user to next page after small delay
    const timeout = setTimeout(() => {
      onCompleteCallback()
    }, 1500)

    return () => clearTimeout(timeout)
  }, [isComplete, onCompleteCallback])
}
