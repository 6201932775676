import { useMemo, FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import { AutoComplete, Field } from '@matillion/component-library'

import { useGetSecretReferences } from 'api/hooks'
import { renderFormikError } from 'modules/utils'
import { SecretReferenceTypes } from 'types'
import { CloudProviders } from 'types/CloudProviders'
import { AssociateCredentialsWithEnvironmentFormI } from 'types/FormTypes'

import classes from 'components/Form/Form.module.scss'

export const AWSCredentials: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<AssociateCredentialsWithEnvironmentFormI>()
  const { t } = useTranslation()
  const { projectId } = useParams()
  const {
    data: listOfSecretReferences = [],
    isError,
    isLoading
  } = useGetSecretReferences(
    projectId!,
    SecretReferenceTypes.CLOUD_PLATFORM_CREDENTIALS,
    { retryOnMount: false }
  )

  const awsReferences = listOfSecretReferences
    .filter((secret) => secret.metadata?.provider === CloudProviders.AWS)
    .sort((a, b) => a.name.localeCompare(b.name))

  const formikError = renderFormikError(
    errors.awsCredentials,
    Boolean(touched.awsCredentials)
  )

  const providerError = useMemo(() => {
    if (isError) {
      return t('associateCredentials.fields.aws.error.loadingError')
    } else if (formikError) {
      return t(formikError)
    }
    return ''
  }, [formikError, t, isError])

  const noSelection = { id: '0', name: 'No Selection' }

  return (
    <div data-testid="associate-credentials-aws">
      <Field
        inputComponent={AutoComplete}
        availableItems={[
          noSelection,
          ...awsReferences.map((item) => ({
            id: item.id,
            name: item.name
          }))
        ]}
        loading={isLoading}
        title={t('associateCredentials.fields.aws.title')}
        name="awsCredentials"
        data-testid="associate-credentials-aws-input"
        value={values.awsCredentials}
        placeholder={t('associateCredentials.fields.aws.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('associateCredentials.fields.aws.supportText')}
        errorText={isLoading ? undefined : providerError}
        hasError={
          Boolean(errors?.awsCredentials?.id) &&
          Boolean(touched.awsCredentials) &&
          !isLoading
        }
        required
      />
    </div>
  )
}
