import { GETDatabricksComputeResourceResponseParams } from 'api/createProjectForm/types/DatabricksComputeResourcesResponse'
import { POSTProjectRequest } from 'api/types'
import { AgentsSecretsHost, JSONValue, Warehouse } from 'types'
import { AutoCompleteProps } from 'types/FormTypes'

export interface CreateProjectFormValuesTypes {
  projectName: string
  description?: string
  type: Warehouse | ''
  projectConfiguration: AgentsSecretsHost
  provider: string
  repository?: string
  providerParameters?: JSONValue
  environmentName: string
  etlAgent: AutoCompleteProps
  matillionHostedAgentId: string
  account: string
  port?: number | null
  ssl?: boolean | null
  username: string
  secretLocationId: string
  secretReferenceId: string
  defaultRole: AutoCompleteProps
  defaultDatabase: AutoCompleteProps
  defaultWarehouse: AutoCompleteProps
  defaultSchema: AutoCompleteProps
  compute: GETDatabricksComputeResourceResponseParams & AutoCompleteProps
  catalog: AutoCompleteProps
}

export const createProjectMutationData = (
  formValues: CreateProjectFormValuesTypes
): POSTProjectRequest => {
  return {
    project: {
      name: formValues.projectName.trim(),
      description: formValues.description?.trim() || '',
      warehouse: formValues.type,
      agentAndSecretHostLocation: formValues.projectConfiguration,
      gitProvider: formValues.provider,
      repositoryName: formValues.repository || null,
      providerParameters: formValues.providerParameters || null
    },
    environment: {
      name: `${formValues.projectName.trim()}-${formValues.environmentName.trim()}`,
      agent:
        formValues.projectConfiguration === AgentsSecretsHost.MatillionHosted
          ? formValues.matillionHostedAgentId
          : formValues.etlAgent.id,
      warehouseConnection: {
        type: formValues.type.toLowerCase(),
        url: formValues.account.trim(),
        username: formValues.username.trim(),
        secretId: formValues.secretReferenceId,
        defaultRole: formValues.defaultRole.id,
        defaultDatabase: formValues.defaultDatabase.id,
        defaultWarehouse: formValues.defaultWarehouse.id,
        defaultSchema: formValues.defaultSchema.id,
        clusterId: formValues.compute.clusterId,
        clusterName: formValues.compute.clusterName,
        clusterType: formValues.compute.clusterType,
        defaultCatalog: formValues.catalog?.id,
        port: formValues.port,
        ssl: formValues.ssl
      }
    },
    secretLocation: {
      id: formValues.secretLocationId
    }
  }
}
