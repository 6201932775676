import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { getBranch } from 'api/clients'
import { GETBranchResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type TData = GETBranchResponse
type TError = AxiosError<unknown>

export const useGetBranch = (
  projectId: string,
  branchId: string,
  options?: UseQueryOptions<TData, TError>
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.PROJECT_DETAIL, projectId, branchId],
    async () => {
      return getBranch(
        {
          projectId,
          branchId
        },
        client
      )
    },
    options
  )
}
