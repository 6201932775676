import { FunctionComponent } from 'react'

import { TypographyWithToolTip } from 'components/TypographyWithTooltip'
import classes from 'modules/Secrets/CloudProviderCredentialsListing/CloudProviderCredentialsListing.module.scss'

interface NameCellProps {
  name: string
}

const NameCell: FunctionComponent<NameCellProps> = ({ name }) => (
  <TypographyWithToolTip
    tooltip={name}
    className={classes['CloudProviderCredentialsListing__GridCell--ellipsis-1']}
  >
    {name}
  </TypographyWithToolTip>
)

export default NameCell
