import { useMemo, useState } from 'react'
import { object } from 'yup'

import { GenericSchemaType } from 'types'

import { commonSchema } from './CloudProviderCredentials.util'
import { CloudProviderCredentialsContext } from './CloudProviderCredentialsContext'
import CloudProviderCredentialsForm from './CloudProviderCredentialsForm'

const CloudProviderCredentialsFormProvider = () => {
  const originalValidationSchema = object({ ...commonSchema })
  const [validationSchema, setValidationSchema] = useState<GenericSchemaType>(
    originalValidationSchema
  )

  const values = useMemo(
    () => ({
      validationSchema,
      setValidationSchema
    }),
    [validationSchema]
  )

  return (
    <CloudProviderCredentialsContext.Provider value={values}>
      <CloudProviderCredentialsForm />
    </CloudProviderCredentialsContext.Provider>
  )
}
export default CloudProviderCredentialsFormProvider
