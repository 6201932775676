import { useQuery, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import { getWarehouseProvisioning } from 'api/clients'
import { GETWarehouseProvisioningResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type TData = GETWarehouseProvisioningResponse
type TError = AxiosError<unknown>

export const useGetWarehouseProvisioning = (
  options?: UseQueryOptions<TData, TError>
) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.WAREHOUSE_PROVISIONING],
    async () => {
      return getWarehouseProvisioning(client)
    },
    options
  )
}
