import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Header, HeaderTitle } from 'components/Header'

import { AWSCredentials, AzureCredentials, Name } from './Fields'

import classes from 'components/Form/Form.module.scss'
import { Typography } from '@matillion/component-library'

interface AssociateCredentialsProps {
  onFormCancel?: () => void
  showFormCancel?: boolean
}

const AssociateCloudCredentialsToEnvironment: FunctionComponent<
  AssociateCredentialsProps
> = ({ onFormCancel, showFormCancel = true }) => {
  const { t } = useTranslation()

  return (
    <>
      <Header
        onCancel={onFormCancel}
        showCancel={showFormCancel}
        modalContent={t('associateCredentials.confirmCancelModal.content')}
      >
        <HeaderTitle data-testid="associate-provider-title">
          {t('associateCredentials.title')}
        </HeaderTitle>
        <Typography as="p" data-testid="associate-provider-text">
          {t('associateCredentials.cloudCredentialsToEnvironment.supportText')}
        </Typography>
      </Header>
      <div
        className={classNames(
          classes.Form__InnerWrap,
          classes['Form__InnerWrap--space-top']
        )}
      >
        <Name />
        <AzureCredentials />
        <AWSCredentials />
      </div>
    </>
  )
}

export default AssociateCloudCredentialsToEnvironment
