import { useMutation, UseMutationOptions } from 'react-query'
import { AxiosError } from 'axios'

import { deleteSecretReference } from 'api/clients'
import { DELETESecretReferenceValues } from 'api/types'
import { useClient } from './useClient'

type Options = UseMutationOptions<
  void,
  AxiosError,
  {
    values: DELETESecretReferenceValues
  }
>

export const useDeleteSecretReference = (
  projectId: string,
  options?: Options
) => {
  const client = useClient()

  return useMutation(async (data: { values: DELETESecretReferenceValues }) => {
    const { values } = data

    return deleteSecretReference(values, { projectId }, client)
  }, options)
}
