import { useFormikContext } from 'formik'
import {
  type BringYourOwnGitDetails,
  type BringYourOwnGitFormProps
} from './types'
import { useCallback, useMemo, useState } from 'react'
import useGetRepositoriesAction from 'hooks/useGetRepositoriesAction'
import useAuthMessageListener from 'modules/BringYourOwnGit/hooks/useAuthMessageListener'
import GitProviderAuthModal from '../GitProviderAuthModal'
import GitProviderSelector from '../GitProviderSelector'
import GitRepositoryDropdown from '../GitRepositoryDropdown'
import { type ExternalGitProvider } from 'types/ExternalGitProvider'
import styles from './BringYourOwnGitForm.module.scss'
import classNames from 'classnames'
import { LoadingSpinner } from '@matillion/component-library'

const BringYourOwnGitForm = ({ classes }: BringYourOwnGitFormProps) => {
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { values, setFieldValue } = useFormikContext<BringYourOwnGitDetails>()

  const hasSelectedBYOG = useMemo(() => {
    return values.provider !== 'matillion'
  }, [values.provider])

  const externalGitProvider = useMemo(() => {
    return hasSelectedBYOG
      ? (values.provider as ExternalGitProvider)
      : undefined
  }, [hasSelectedBYOG, values.provider])

  const { repositories, loading, refetch } = useGetRepositoriesAction({
    provider: externalGitProvider,
    onAuthRequired: () => {
      setShowAuthModal(true)
    }
  })

  useAuthMessageListener({
    provider: externalGitProvider,
    onAuthError: async () => {
      await handleCloseAuthModal()
    },
    onAuthSuccess: async () => {
      setShowAuthModal(false)
      await refetch()
    }
  })

  const handleCloseAuthModal = useCallback(async () => {
    await setFieldValue('provider', 'matillion')
    setShowAuthModal(false)
  }, [setFieldValue])

  return (
    <div data-testid="bring-your-own-git-form-content">
      <GitProviderSelector className={classes?.providerSelector} />

      {loading && (
        <LoadingSpinner
          className={styles.loading}
          data-testid="external-git-repos-loading"
        />
      )}

      {showAuthModal && externalGitProvider && (
        <GitProviderAuthModal
          onClose={handleCloseAuthModal}
          provider={externalGitProvider}
        />
      )}

      {repositories && externalGitProvider && (
        <GitRepositoryDropdown
          loading={loading}
          repositories={repositories}
          provider={externalGitProvider}
          className={classNames(
            classes?.repositoryDropdown,
            styles.repositorySelector
          )}
        />
      )}
    </div>
  )
}

export default BringYourOwnGitForm
