import { UseQueryOptions, useQuery } from 'react-query'
import { AxiosError } from 'axios'

import { getSchedules } from 'api/clients'
import { GETSchedulesResponse, mutateGetSchedulesResponse } from 'api/types'
import { QueryKey } from 'constants/endpoint'
import { useClient } from './useClient'

type TData = GETSchedulesResponse[]
type TError = AxiosError<unknown>
type TOptions = UseQueryOptions<TData, TError, TData>

export const useGetSchedules = (projectId: string, options?: TOptions) => {
  const client = useClient()

  return useQuery<TData, TError>(
    [QueryKey.SCHEDULES_LIST, projectId],
    async () => {
      return mutateGetSchedulesResponse(
        await getSchedules(
          {
            projectId
          },
          client
        )
      )
    },
    options
  )
}
