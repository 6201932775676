import { AuthProviderEnvironment } from '@matillion/hub-client'

const version = process.env.REACT_APP_VERSION ?? '0.0.0-local'
const strictMode = process.env.REACT_APP_STRICT_MODE !== 'false'

const getEnvFromUrl = () => {
  const hostname = new URL(window.location.href).hostname
    .replace('.eu1.', '.')
    .replace('.us1.', '.')

  const currentEnv = hostname
    .split('.')
    ?.reverse()?.[2]
    ?.split('-')
    ?.reverse()?.[0]

  if (['local', 'dev', 'preprod'].includes(currentEnv)) {
    return currentEnv
  } else {
    return 'prod'
  }
}

const environment =
  process.env.REACT_APP_ENVIRONMENT === 'test'
    ? process.env.REACT_APP_ENVIRONMENT
    : getEnvFromUrl()

type EnvironmentConfig = {
  AUTH_ENVIRONMENT: AuthProviderEnvironment
  API_BASE_AUDIENCE: string
  API_BASE_SCOPE: string
  DATADOG?: Record<string, string>
  PROJECT_EXPLORER: {
    BASE_URL: string
    CREATE_SECRET_HELP_REDIRECT: string
  }
  ETL_DESIGNER: {
    BASE_URL: string
  }
  LAUNCHDARKLY: {
    CLIENT_ID?: string
    DEFAULT_FLAGS: Record<string, boolean>
  }
  HUB: {
    HOME_TEMPLATE: string
    API_URL: string
    API_AUDIENCE: string
  }
  MARKETO: {
    ID: string
  }
} & {
  [key: string]: string | undefined
}

// eslint-disable-next-line @typescript-eslint/no-var-requires
const envConfig: EnvironmentConfig = require(`./${environment}.json`)

export interface PeFlags {
  [key: string]: boolean
}

const config = {
  environment,
  version,
  strictMode,

  authEnvironment: envConfig.AUTH_ENVIRONMENT,
  apiBaseAudience: envConfig.API_BASE_AUDIENCE,
  apiBaseScope: envConfig.API_BASE_SCOPE,

  gtmId: envConfig.GTM_ID,

  datadog: envConfig.DATADOG && {
    env: envConfig.DATADOG.ENV,
    site: envConfig.DATADOG.SITE,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN as string,
    service: process.env.REACT_APP_DATADOG_APP_NAME as string,
    rumService: process.env.REACT_APP_DATADOG_APP_NAME as string,
    applicationId: process.env.REACT_APP_DATADOG_APP_ID as string
  },

  projectExplorer: {
    apiBaseUrl: envConfig.PROJECT_EXPLORER.BASE_URL,
    createSecretHelpRedirect:
      envConfig.PROJECT_EXPLORER.CREATE_SECRET_HELP_REDIRECT
  },
  etlDesigner: {
    baseUrl: envConfig.ETL_DESIGNER.BASE_URL
  },
  launchDarkly: {
    clientId: envConfig.LAUNCHDARKLY.CLIENT_ID,
    defaultFlags: envConfig.LAUNCHDARKLY.DEFAULT_FLAGS
  },
  hub: {
    homeUrlTemplate: envConfig.HUB.HOME_TEMPLATE,
    apiUrl: envConfig.HUB.API_URL,
    apiAudience: envConfig.HUB.API_AUDIENCE
  },
  marketo: envConfig.MARKETO && {
    id: envConfig.MARKETO.ID
  },
  docs: {
    onboardingVideoUrl:
      'https://fast.wistia.net/embed/iframe/2b4utnqgci?twitter=true&muted=true&autoplay=true'
  }
}

export default config
