import { FunctionComponent } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import {
  CREATE_SECRET_DEFINITION,
  GENERIC_PROJECT_DETAILS_TAB,
  PROJECTS_ADD,
  PROJECT_DETAILS_ENVIRONMENTS,
  PROJECT_DETAILS_BRANCHES,
  PROJECT_DETAILS_SCHEDULES,
  PROJECTS_EDIT,
  PROJECT_DETAILS_OAUTH,
  ADD,
  EDIT,
  PROJECT_DETAILS_CLOUD_CREDENTIALS,
  ASSOCIATE_CREDENTIALS,
  ASSOCIATE_ENVIRONMENT
} from 'constants/route'
import { CreateSecretDefinition } from 'modules/Secrets'
import ProjectDetails from './ProjectDetails'
import CreateEnvironment from 'modules/Projects/CreateEnvironment'
import CreateOAuthFormProvider from 'modules/Projects/CreateOAuth'
import CreateSchedule from 'modules/Projects/CreateSchedule'
import EditSchedule from 'modules/Projects/EditSchedule'
import CreateBranch from 'modules/Projects/CreateBranch/CreateBranch'
import EditBranch from 'modules/Projects/EditBranch/EditBranchForm'
import CloudProviderCredentialsFormProvider from 'modules/Projects/CloudProviderCredentials'
import AssociateCloudCredentialsToEnvironmentForm from 'modules/Projects/EditEnvironment/AssociateCloudCredentials/AssociateCloudCredentialsToEnvironment/AssociateCloudCredentialsToEnvironmentForm'
import AssociateEnvironmentToCloudCredentialForm from 'modules/Projects/EditEnvironment/AssociateCloudCredentials/AssociateEnvironmentToCloudCredential'
import EditEnvironmentForm from 'modules/Projects/EditEnvironment/EditEnvironmentForm'

const ProjectDetailsRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_ADD}/*`}
        element={<CreateEnvironment />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${PROJECTS_EDIT}/:envId/*`}
        element={<EditEnvironmentForm />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_CREDENTIALS}/:envId`}
        element={<AssociateCloudCredentialsToEnvironmentForm />}
      />
      <Route
        path={`${PROJECT_DETAILS_ENVIRONMENTS}/${ASSOCIATE_ENVIRONMENT}/:secretId`}
        element={<AssociateEnvironmentToCloudCredentialForm />}
      />
      <Route
        path={CREATE_SECRET_DEFINITION}
        element={<CreateSecretDefinition />}
      />
      <Route
        path={`${PROJECT_DETAILS_OAUTH}/${PROJECTS_ADD}/*`}
        element={<CreateOAuthFormProvider />}
      />
      <Route
        path={`${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_ADD}/*`}
        element={<CreateSchedule />}
      />
      <Route
        path={`${PROJECT_DETAILS_SCHEDULES}/${PROJECTS_EDIT}/:scheduleId`}
        element={<EditSchedule />}
      />
      <Route
        path={`${PROJECT_DETAILS_CLOUD_CREDENTIALS}/${PROJECTS_ADD}`}
        element={<CloudProviderCredentialsFormProvider />}
      />
      <Route path={GENERIC_PROJECT_DETAILS_TAB} element={<ProjectDetails />} />
      <Route
        path={`${PROJECT_DETAILS_BRANCHES}/${ADD}/*`}
        element={<CreateBranch />}
      />
      <Route
        path={`${PROJECT_DETAILS_BRANCHES}/${EDIT}/:branchId`}
        element={<EditBranch />}
      />
      <Route
        path="*"
        element={<Navigate to={PROJECT_DETAILS_BRANCHES} replace />}
      />
    </Routes>
  )
}

export default ProjectDetailsRoutes
