import { FieldMetaProps } from 'formik'
import { TFunction } from 'react-i18next'
import { object, string, InferType } from 'yup'

export const resolveErrorText = (t: TFunction, meta: FieldMetaProps<unknown>) =>
  meta.touched && meta.error ? t(meta.error) : undefined

// same regex used in backend - must be valid GIT branch names
export const formSchema = object({
  branchName: string().notRequired(),
  defaultEnvironment: object({
    id: string().required(
      'editBranch.fields.defaultEnvironment.error.required'
    ),
    name: string().required(
      'editBranch.fields.defaultEnvironment.error.required'
    )
  })
    .required('editBranch.fields.defaultEnvironment.error.unmatched')
    .nullable()
})

export type FormValues = InferType<typeof formSchema>

export const initialValues: FormValues = {
  branchName: '',
  defaultEnvironment: { id: '', name: '' }
}
