import cronValidator from 'cron-expression-validator'
import { date, object, string } from 'yup'

import { ScheduleDisplayMode } from 'api/types'
import { FormValues } from 'modules/Projects/CreateSchedule/types'

const today = new Date()
export const initialValues: FormValues = {
  name: '',
  environment: {
    id: '',
    name: ''
  },
  pipeline: {
    id: '',
    name: ''
  },
  agent: {
    id: '',
    name: ''
  },
  timezone: {
    id: '',
    name: ''
  },
  startTime: new Date(today.setHours(today.getHours() + 1)),
  cronExpression: '0 0 * ? * * *',
  displayMode: ScheduleDisplayMode.ADVANCED
}

export const validationSchema = object({
  name: string().required('createSchedule.fields.scheduleName.error.required'),
  environment: object({
    id: string().required('commonForm.fields.environment.error.required'),
    name: string().required('commonForm.fields.environment.error.required')
  })
    .required('commonForm.fields.environment.error.unmatched')
    .nullable(),
  pipeline: object({
    id: string().required('commonForm.fields.pipeline.error.required'),
    name: string().required('commonForm.fields.pipeline.error.required')
  })
    .required('commonForm.fields.pipeline.error.unmatched')
    .nullable(),
  agent: object({
    id: string().required('commonForm.fields.agent.error.required'),
    name: string().required('commonForm.fields.agent.error.required')
  })
    .required('commonForm.fields.agent.error.unmatched')
    .nullable(),
  timezone: object({
    id: string().required('createSchedule.fields.timezone.error.required'),
    name: string().required('createSchedule.fields.timezone.error.required')
  })
    .required('createSchedule.fields.timezone.error.unmatched')
    .nullable(),
  startTime: date().required('createSchedule.fields.startTime.error.required'),
  cronExpression: string()
    .test(
      'Cron Validation',
      'createSchedule.fields.cronExpression.error.unmatched',
      (value) => {
        const valid = cronValidator.isValidCronExpression(value!)
        return valid
      }
    )
    .required('createSchedule.fields.cronExpression.error.required')
})
