import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { AutoComplete, Field } from '@matillion/component-library'
import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'
import { renderFormikError } from 'modules/utils'
import classes from 'components/Form/Form.module.scss'
import { WarehouseLookupTypes } from 'api/createProjectForm/types/WarehouseDefaultsRequest'
import { ErrorResponse } from 'api/types'
import { StatusCodes } from 'constants/statusCodes'
import { useMutateWarehouseDefaults } from 'api/hooks'
import { createWarehouseDefaultsMutation } from 'api/mutation'
import { CreateProjectFormikValueTypes } from 'modules/Projects/CreateProject/CreateProjectForm'

export interface RoleProps {
  isFieldEnabled?: boolean
  listenerFields?: Array<keyof CreateProjectFormikValueTypes>
}

const Role: FunctionComponent<RoleProps> = ({
  isFieldEnabled = true,
  listenerFields = []
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    initialValues
  } = useFormikContext<CreateProjectFormikValueTypes>()
  const { t } = useTranslation()
  const {
    data: roleDefaultData = [],
    mutate: mutateWarehouseDefaults,
    isError,
    error,
    isLoading
  } = useMutateWarehouseDefaults(WarehouseLookupTypes.ROLE)
  const projectType = values.type.toLowerCase() || 'common'

  const defaultRoleError = renderFormikError(
    errors.defaultRole,
    Boolean(touched.defaultRole)
  )

  const getErrorMessage = useMemo(() => {
    if (isError) {
      const errorDetail = (error?.response?.data as ErrorResponse)?.detail
      const errorResponseStatus = error?.response?.status
      if (
        errorResponseStatus === StatusCodes.BAD_REQUEST &&
        errorDetail !== undefined
      ) {
        return errorDetail
      }
      return t('fields.defaultRole.error.loadingError')
    }
    if (!roleDefaultData.length) {
      return t('fields.defaultRole.error.noItemsFound')
    }
    if (defaultRoleError) return t(defaultRoleError)
    return null
  }, [defaultRoleError, isError, roleDefaultData.length, t, error])

  const listenerFieldValues = listenerFields.map((field) => values[field])

  const resetRole = useCallback(() => {
    setFieldTouched('defaultRole', false)
    setFieldValue('defaultRole', { id: '', name: '' })
  }, [setFieldTouched, setFieldValue])

  useEffect(() => {
    if (!isFieldEnabled) {
      resetRole()
    }

    listenerFields.forEach((field) => {
      const listenerFieldInitialValue = initialValues[field]
      const listenerFieldCurrentValue = values[field]
      if (listenerFieldCurrentValue !== listenerFieldInitialValue) {
        resetRole()
      }
    })
    if (isFieldEnabled) {
      mutateWarehouseDefaults({
        values: createWarehouseDefaultsMutation(values)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...listenerFieldValues,
    isFieldEnabled,
    mutateWarehouseDefaults,
    resetRole,
    setFieldTouched,
    setFieldValue
  ])

  return (
    <div data-testid={`${projectType}-default-default-role`}>
      <Field
        inputComponent={AutoComplete}
        availableItems={roleDefaultData.map((item) => ({
          id: item,
          name: item
        }))}
        loading={isLoading}
        title={t('fields.defaultRole.title')}
        name="defaultRole"
        data-testid={`${projectType}-default-default-role-input`}
        value={values.defaultRole}
        placeholder={t('fields.defaultRole.placeholderText')}
        onChange={handleChange}
        onBlur={handleBlur}
        className={classes.Form__SpacingStyles}
        supportText={t('fields.defaultRole.supportText')}
        errorText={isLoading || !isFieldEnabled ? undefined : getErrorMessage}
        hasError={
          Boolean(errors?.defaultRole?.id) &&
          Boolean(touched.defaultRole) &&
          !isLoading &&
          isFieldEnabled
        }
        required
        disabled={isError || !isFieldEnabled}
      />
    </div>
  )
}

export default Role
