import { type WtsDescribeMergeResponse } from 'api/hooks/useDescribeMerge/types'

export const useDescribeMergeResponses: Record<
  string,
  WtsDescribeMergeResponse
> = {
  BranchWithConflicts: {
    localCommitId: '939bb46a04c3640c8c427e92b1b557e882e2d2a0',
    remoteCommitId: '41ffe5457d1a557c3317f2e5216ceaa355223d39',
    conflicts: [
      {
        fileName: 'file-one.ORCHESTRATION',
        type: {
          source: 'ADDED',
          target: 'ADDED'
        }
      },
      {
        fileName: 'file-two.TRANSFORMATION',
        type: {
          source: 'DELETED',
          target: 'MODIFIED'
        }
      }
    ]
  },
  BranchNoConflicts: {
    localCommitId: 'eee47f0ba05b7dcc19c43a1ab0ac8d72e2bc7887',
    remoteCommitId: 'bee6cb9099ffe3f5da8ab0b746e93791187b459d',
    conflicts: []
  },
  main: {
    localCommitId: 'eee47f0ba05b7dcc19c43a1ab0ac8d72e2bc7887',
    remoteCommitId: 'bee6cb9099ffe3f5da8ab0b746e93791187b459d',
    conflicts: []
  }
}
