import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATION_EN_JSON from 'locales/en/translation.json'

// The translations
// TO-DO When implemented, will have to include the translations from the MCL, e.g.
//  import { translation } from '@matillion/component-library'
const resources = {
  en: {
    translation: { ...TRANSLATION_EN_JSON }
  }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
})

export default i18n
