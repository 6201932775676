import { useQuery } from '@tanstack/react-query'

import { type WtsDescribeMergeResponse } from './types'
import { queryKeys } from 'api/query-keys'
import useWorkingTreeClient from 'api/clients/useWorkingTreeClient'
import useGitContext from 'provider/GitProvider/useGitContext'

const useDescribeMergeQueryKey = (
  projectId: string | undefined,
  branchName?: string
) => {
  return [
    queryKeys.describeMerge,
    projectId,
    branchName,
    queryKeys.legacyDescribeMerge
  ]
}

/**
 * Describes the outcome of merging changes from the remote into the local
 * copy of the working tree store. Will describe conflicts with uncommitted local
 * changes, should there be any.
 *
 * @param branchName The name of the branch whose remote to pull from.
 * @param enabled Whether or not the query is enabled.
 */
const useDescribeMerge = (branchName?: string, enabled = true) => {
  const client = useWorkingTreeClient()
  const { project } = useGitContext()

  const queryKey = useDescribeMergeQueryKey(project, branchName)

  return useQuery(
    queryKey,
    async () => {
      const url = `/projects/${project}/branches/${branchName}/merges`
      const { data } = await client.get<WtsDescribeMergeResponse>(url)
      return data
    },
    {
      enabled: Boolean(branchName) && enabled
    }
  )
}

export default useDescribeMerge
