import { ChangeEvent, FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import classNames from 'classnames'
import { Field } from '@matillion/component-library'

import classes from 'modules/FormsContent/Form.module.scss'
import { FormValues } from 'modules/Projects/CloudProviderCredentials/types'
import { useFormikFieldSpacesToHyphens } from 'hooks'

const CredentialName: FunctionComponent = () => {
  const { errors, handleBlur, handleChange, touched, values } =
    useFormikContext<FormValues>()
  const { t } = useTranslation()
  const fieldName = 'name'
  const spacesToHyphens = useFormikFieldSpacesToHyphens({ fieldName })

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e)
    spacesToHyphens(e)
  }
  return (
    <div data-testid="cloud-provider-credentials-common-credential-name">
      <Field
        title={t('cloudProviderCredentials.fields.common.credentialName.title')}
        name={fieldName}
        data-testid="cloud-provider-credentials-common-credential-name-input"
        value={values.name}
        placeholder={t(
          'cloudProviderCredentials.fields.common.credentialName.placeholderText'
        )}
        onChange={handleFieldChange}
        onBlur={handleBlur}
        className={classNames(classes.Form__Field)}
        errorText={errors?.name && touched.name ? t(errors.name) : null}
        hasError={!!errors?.name && touched.name}
        required
      />
    </div>
  )
}

export default CredentialName
