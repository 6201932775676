import * as storageItems from 'constants/persistance'
import { useEffect } from 'react'

export const useClearSessionStorageForms = () => {
  useEffect(() => {
    Object.values(storageItems).forEach((storageItem) => {
      window.sessionStorage.removeItem(storageItem)
    })
  }, [])
}
