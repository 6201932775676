import { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResourcesAvailable } from 'components/NoResourcesAvailable'

const NoCreatePermission: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <NoResourcesAvailable
      title={t('schedulesListing.noCreatePermission.title')}
      supportText={t('schedulesListing.noCreatePermission.content')}
      data-testid="no-create-permission-schedule"
    />
  )
}

export default NoCreatePermission
